import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton,
    CForm, CFormInput, CFormLabel } from "@coreui/react";
import { Supply } from "../../../types";
import { useEffect, useState } from "react";
import { getProductSuppliesInformation } from "../../../services/ProductService";
import "../AdminComponentStyles.css";

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    selectedSupplyItem: Supply | undefined;
    closeModal: () => void;
}
const SupplyInfoModal = (props: ModalProps) => {   
    const {isModalVisible, closeModal, headerText, bodyText, selectedSupplyItem} = props;
    const [numOfProducts, setNumOfProducts] = useState<number>(0);
    const [unsoldProducts, setUnsoldProducts] = useState<number>(0);
    const [bookedProducts, setBookedProducts] = useState<number>(0);
   useEffect(()=> {
        getProductSuppliesInformation(selectedSupplyItem ? selectedSupplyItem.id : '').then((response) => {           
            setNumOfProducts(response.data.num_of_products);
            setUnsoldProducts(response.data.unsold_products);
            setBookedProducts(response.data.booked_products);
        }).catch((e) => {
            
        })
   }, []);
    
    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal} className="zero-margin">
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{bodyText}</div>
                <CForm style={{marginBottom: 16, marginTop: 24}}>
                    <CFormLabel><b>Total Number of Products.</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        placeholder='Total Products.'
                        value={numOfProducts}
                        disabled={true}
                        style={{marginBottom: 16, backgroundColor: 'white'}}
                    />

                    <CFormLabel><b>Unsold / Remaining Products.</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        placeholder='Unsold / Remaining Products.'
                        value={unsoldProducts}
                        disabled={true}
                        style={{marginBottom: 16, backgroundColor: 'white'}}
                    />

                    <CFormLabel><b>Reserved Products.</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        placeholder='Reserved Products.'
                        value={bookedProducts}
                        disabled={true}
                        style={{marginBottom: 16,  backgroundColor: 'white'}}
                    />
                </CForm>
            </CModalBody>
            
            <CModalFooter>
                <CButton color="primary" onClick={closeModal}>Close</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default SupplyInfoModal;