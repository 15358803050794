import { useState, useEffect } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CCol, CSmartTable } from '@coreui/react-pro';
import { CButton, CCardBody, CCollapse, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormInput, CFormLabel, CRow, CToaster } from '@coreui/react';
import CatalogManagementHeader from '../../components/catalog-management';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setCatalogArticles } from '../../../redux/slices/CatalogSlice';
import { CatalogItem, DropDownItem, ProductBrand, ProductSubCategory, Supplier } from '../../../types';
import Select from 'react-select';
import { editCatalog } from '../../../services/CatalogService';
import { t } from "i18next";
import { Utils } from '../../components/utils';
import { GenericErrorToast, GenericSuccessToast } from '../../components/create-package-components/ToastsComponent';

const CatalogManagementScreen = () => {
  const {getInCompleteCatalog, CatalogColumns, getAllProductBrands, getAllProductSubCategories} = Utils();
    const dispatch = useDispatch();
    const catalogArticles = useSelector((state: RootState) => state.catalogSlice.catalogArticles);
    const suppliers = useSelector((state: RootState) => state.productSlice.suppliers);
    const productBrands = useSelector((state: RootState) => state.productSlice.productBrands);
    const productSubCategories = useSelector((state: RootState) => state.productSlice.productSubCategories);
    const brandOptions: DropDownItem[] = productBrands.map((brand: ProductBrand) => ({ value: brand.id, label: brand.verbose_name }));   
    const subCategoriesOptions: DropDownItem[] = productSubCategories.map((subCategory: ProductSubCategory) => ({ value: subCategory.id, label: subCategory.name }));
    const supplierOptions: DropDownItem[] = suppliers.map((supplier: Supplier) => ({ value: supplier.id, label: supplier.name }));
    const typeOptions: DropDownItem[] = [
      {label: 'V', value: 'V'},
      {label: 'U', value: 'U'},
      {label: 'T', value: 'T'},
    ];   
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [details, setDetails] = useState<string[]>([]);
    const [editArticleId, setEditArticleId] = useState<string>('');    
    
    const [currentBrand, setCurrentBrand] = useState<DropDownItem>({label: "Select", value: ""});
    const [currentSubCat, setCurrentSubCat] = useState<DropDownItem>({label: "Select", value: ""});
    const [currentSupplier, setCurrentSupplier] = useState<DropDownItem>({label: "Select", value: ""});
    const [currentModel, setCurrentModel] = useState<string>('');
    const [currentRetailPrice, setCurrentRetailPrice] = useState<number>(0);
    const [currentOnlinePrice, setCurrentOnlinePrice] = useState<number>(0);
    const [currentBuyingPrice, setCurrentBuyingPrice] = useState<number>(0);
    const [currentWeight, setCurrentWeight] = useState<number>(0);
    const [currentWidthPrimmary, setCurrentWidthPrimmary] = useState<number>(0);
    const [currentWidthSecondary, setCurrentWidthSecondary] = useState<number>(0);
    const [currentHeightPrimary, setCurrentHeightPrimary] = useState<number>(0);
    const [currentHeightSecondary, setCurrentHeightSecondary] = useState<number>(0);
    const [currentDepthPrimary, setCurrentDepthPrimary] = useState<number>(0);
    const [currentDepthSecondary, setCurrentDepthSecondary] = useState<number>(0);
    const [type, setType] = useState<DropDownItem>({label: '', value: ''});
    const [metadata, setMetadata] = useState<any>({});
    const [toast, addToast] = useState<any>(null);

    useEffect(()=> {
      dispatch(setCatalogArticles([]));
      getInCompleteCatalog();
      getAllProductSubCategories();
      getAllProductBrands();
    },[]);

    const toggleDetails = (index: string) => {
      const position = details.indexOf(index)
      let newDetails = details.slice()
      if (position !== -1) {
        newDetails.splice(position, 1)
      } else {
        newDetails = [index]
      }
      setDetails(newDetails)
    }

    const editCatalogData = (article_number: string) => {
      setEditArticleId('');
      let formData = {
        supplier: currentSupplier.value,
        brand: currentBrand.value,
        sub_category: currentSubCat.value,
        model: currentModel,
        retail_price: currentRetailPrice,
        online_price: currentOnlinePrice,
        buying_price: currentBuyingPrice,
        weight_kg: currentWeight,
        width_primary: currentWidthPrimmary > 0 ? currentWidthPrimmary : null,
        width_secondary: currentWidthSecondary > 0 ? currentWidthSecondary : null,
        height_primary: currentHeightPrimary > 0 ?currentHeightPrimary : null,
        height_secondary: currentHeightSecondary > 0 ? currentHeightSecondary : null,
        depth_primary: currentDepthPrimary > 0 ? currentDepthPrimary : null,
        depth_secondary: currentDepthSecondary > 0 ? currentDepthSecondary : null,
        metadata: {
          ...metadata,
          type: type?.value,
        }
      };

      editCatalog(article_number, formData).then((response) => {
        let updatedCatalogArticles: CatalogItem[] = [];
        catalogArticles.map((catalogArticle: CatalogItem) => {
          if(catalogArticle.article_number == response?.data?.article_number) {
            updatedCatalogArticles.push({
              ...response.data,
              brand_name: response.data.brand.verbose_name,
              sub_cat_name: response.data.sub_category.name,
              supplier_name: response.data.supplier.name,
            });
          }
          else { 
            updatedCatalogArticles.push(catalogArticle);
          }
        })
        dispatch(setCatalogArticles(updatedCatalogArticles));
        getInCompleteCatalog();
        addToast(GenericSuccessToast(()=>{addToast(null)}, "Updated Successfully !!"));
      }).catch((e) => {
        if(e?.response?.data?.model) {
          addToast(GenericErrorToast(()=>{addToast(null)}, ("Model !! " + e?.response?.data?.model)))
        }      
        console.log('error while editing catalog. ', e);
      });
    }

    return (
        <div className='website-settings-main-container'>
          <CToaster push={toast} placement="top-end" />
          <CatalogManagementHeader />
          {
            catalogArticles.length > 0 ?
              <CSmartTable
                columns={CatalogColumns}
                tableFilter
                tableFilterLabel={`${t('adminDashboard.CommonWords.search')}`}
                tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
                items={catalogArticles}
                itemsPerPage={itemsPerPage}
                itemsPerPageSelect
                pagination={true}
                paginationProps={{
                  activePage: activePage,
                  pages: Math.ceil(catalogArticles.length / itemsPerPage) || 1,
                }}
                tableHeadProps={{
                  color: 'info',
                }}
                tableProps={{
                  hover: true,
                  responsive: true,
                  style:{marginTop: 4, marginBottom: 44},
                  striped: true,
                }}
                onActivePageChange={(activePage) => setActivePage(activePage)}
                onColumnFilterChange={(filter) => {
                  setActivePage(1)
                }}
                tableBodyProps={{
                  className: 'align-middle'
                }}
                onItemsPerPageChange={(itemsPerPage) => {
                  setActivePage(1)
                  setItemsPerPage(itemsPerPage)
                }}
                scopedColumns={{
                  article_number: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {item.article_number}
                      </td>
                    )
                  },
                  weight_kg: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                            <CFormInput
                              type="number"
                              value={currentWeight}
                              placeholder={'Select'}
                              onChange={(e: any) => {
                                  setCurrentWeight(Number(e.target.value))
                              }}
                            />
                          :
                            item.weight_kg
                        }
                      </td>
                    )
                  },
                  buying_price: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                            <CFormInput
                              type="number"
                              value={currentBuyingPrice}
                              placeholder={'Select'}
                              onChange={(e: any) => {
                                  setCurrentBuyingPrice(Number(e.target.value))
                              }}
                            />
                          :
                            item.buying_price
                        }
                      </td>
                    )
                  },
                  online_price: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                            <CFormInput
                              type="number"
                              value={currentOnlinePrice}
                              placeholder={'Select'}
                              onChange={(e: any) => {
                                  setCurrentOnlinePrice(Number(e.target.value))
                              }}
                            />
                          :
                            item.online_price
                        }
                      </td>
                    )
                  },
                  retail_price: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                            <CFormInput
                                type="number"
                                value={currentRetailPrice}
                                placeholder={'Select'}
                                onChange={(e: any) => {
                                    setCurrentRetailPrice(Number(e.target.value))
                                }}
                            />
                          :
                            item.retail_price
                        }
                      </td>
                    )
                  },
                  model: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                            <CFormInput
                                type="text"
                                value={currentModel}
                                placeholder={'Select'}
                                onChange={(e: any) => {
                                    setCurrentModel(e.target.value)
                                }}
                            />
                          :
                            item.model
                        }
                      </td>
                    )
                  },
                  sub_cat_name: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                            <Select
                              value={currentSubCat}
                              placeholder={'Select'}
                              noOptionsMessage={({ inputValue: string }) => 'Sub Category not found.'}
                              options={subCategoriesOptions}
                              onChange={(option: any) => {
                                setCurrentSubCat(option)
                              }}
                            />
                          :
                            item.sub_cat_name
                        }
                      </td>
                    )
                  },
                  category: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {item.category}
                      </td>
                    )
                  },
                  brand_name: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                            <Select
                              value={currentBrand}
                              placeholder={t('adminDashboard.CommonWords.min_3_digits')}
                              noOptionsMessage={({ inputValue: string }) => 'Brand not found.'}
                              options={brandOptions}
                              onChange={(option: any) => {
                                setCurrentBrand(option)
                              }}
                            />
                          :
                            item.brand_name
                        }
                      </td>
                    )
                  },
                  supplier_name: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                            <Select
                              value={currentSupplier}
                              placeholder={'Select'}
                              noOptionsMessage={({ inputValue: string }) => 'Supplier not found.'}
                              options={supplierOptions}
                              onChange={(option: any) => {
                                setCurrentSupplier(option)
                              }}
                            />
                          :
                            item.supplier_name
                        }
                      </td>
                    )
                  },
                  action: (item: CatalogItem) => {
                    return (
                      <td className='text-center'>
                        {
                          editArticleId == item.article_number ?
                          <>
                            <CButton 
                              className='elv-btn' 
                              size='sm' 
                              onClick={()=> {
                                editCatalogData(item.article_number)
                                toggleDetails(item.article_number)
                              }}>
                              Done
                            </CButton>
                          </>
                          :
                          <CDropdown>
                            <CDropdownToggle className="elv-btn" href="#" style={{width: 110}}>Action</CDropdownToggle>
                            <CDropdownMenu className='pointer' style={{position: 'absolute'}}>
                                <CDropdownItem 
                                  onClick={()=>{ 
                                      setEditArticleId(item.article_number); 
                                      setCurrentBrand({label: item.brand.verbose_name, value: item.brand.id});
                                      setCurrentSubCat({label: item.sub_category.name, value: item.sub_category.id});
                                      setCurrentSupplier({label: item.supplier.name, value: item.supplier.id});
                                      setCurrentModel(item.model);
                                      setCurrentRetailPrice(item.retail_price);
                                      setCurrentOnlinePrice(item.online_price);
                                      setCurrentBuyingPrice(item.buying_price);
                                      setCurrentWeight(item.weight_kg);
                                      setCurrentWidthPrimmary(item.width_primary ? item.width_primary : 0);
                                      setCurrentWidthSecondary(item.width_secondary ? item.width_secondary : 0);

                                      setCurrentHeightPrimary(item.height_primary ? item.height_primary : 0);
                                      setCurrentHeightSecondary(item.height_secondary ? item.height_secondary : 0);

                                      setCurrentDepthPrimary(item.depth_primary ? item.depth_primary : 0);
                                      setCurrentDepthSecondary(item.depth_secondary ? item.depth_secondary : 0);
                                      if(item?.metadata?.type) {
                                        setType({value: item?.metadata?.type, label: item?.metadata?.type})
                                      }

                                      if(item?.metadata) {
                                        setMetadata(item?.metadata)
                                      }
                                    
                                      
                                      toggleDetails(item.article_number)
                                  }}>
                                    Edit
                                  </CDropdownItem>
                                {/* <CDropdownItem onClick={()=>{}}>Delete</CDropdownItem> */}
                            </CDropdownMenu>
                          </CDropdown>
                        }
                      </td>
                    )
                  },
                  details: (item: any) => {
                    return (
                      <CCollapse visible={details.includes(item.article_number)}>
                        <CCardBody className="p-3">
                          <CRow>
                            <CCol>
                              <CFormLabel>{t('adminDashboard.CatalogManagement.width_primary')}</CFormLabel>
                              <CFormInput
                                type="number"
                                value={currentWidthPrimmary}
                                placeholder={'Select'}
                                onChange={(e: any) => {
                                  setCurrentWidthPrimmary(Number(e.target.value))
                                }}
                              />
                            </CCol>

                            <CCol>
                              <CFormLabel>{t('adminDashboard.CatalogManagement.width_secondary')}</CFormLabel>
                              <CFormInput
                                type="number"
                                value={currentWidthSecondary}
                                placeholder={'Select'}
                                onChange={(e: any) => {
                                  setCurrentWidthSecondary(Number(e.target.value))
                                }}
                              />
                            </CCol>

                            <CCol>
                              <CFormLabel>{t('adminDashboard.CatalogManagement.height_primary')}</CFormLabel>
                              <CFormInput
                                type="number"
                                value={currentHeightPrimary}
                                placeholder={'Select'}
                                onChange={(e: any) => {
                                  setCurrentHeightPrimary(Number(e.target.value))
                                }}
                              />
                            </CCol>

                            <CCol>
                              <CFormLabel>{t('adminDashboard.CatalogManagement.height_secondary')}</CFormLabel>
                              <CFormInput
                                type="number"
                                value={currentHeightSecondary}
                                placeholder={'Select'}
                                onChange={(e: any) => {
                                  setCurrentHeightSecondary(Number(e.target.value))
                                }}
                              />
                            </CCol>

                            <CCol>
                              <CFormLabel>{t('adminDashboard.CatalogManagement.depth_primary')}</CFormLabel>
                              <CFormInput
                                type="number"
                                value={currentDepthPrimary}
                                placeholder={'Select'}
                                onChange={(e: any) => {
                                  setCurrentDepthPrimary(Number(e.target.value))
                                }}
                              />
                            </CCol>

                            <CCol>
                              <CFormLabel>{t('adminDashboard.CatalogManagement.depth_secondary')}</CFormLabel>
                                <CFormInput
                                  type="number"
                                  value={currentDepthSecondary}
                                  placeholder={'Select'}
                                  onChange={(e: any) => {
                                    setCurrentDepthSecondary(Number(e.target.value))
                                  }}
                                />
                            </CCol>
                                <CCol>
                                  <CFormLabel>{t('adminDashboard.CatalogManagement.type')}</CFormLabel>
                                  { (item.sub_category.id == 7 || item.sub_category.id == 14) ?
                                    <Select
                                      value={type}
                                      placeholder={"Select"}
                                      noOptionsMessage={({ inputValue: string }) => 'Type not found.'}
                                      options={typeOptions}
                                      onChange={(option: any) => {
                                        setType(option)
                                      }}
                                    />
                                      : type ?
                                        <CFormInput
                                          type="text"
                                          value={type.value}
                                          placeholder={'Select'}
                                          onChange={(e: any) => {
                                            setType({label: e.target.value, value: e.target.value})
                                          }}
                                        />
                                  
                                  : null
                                }
                              </CCol>
                          </CRow>
                        </CCardBody>
                      </CCollapse>
                    )
                  },
                }}
              />
          : null
        }
      </div>
    )
};
export default CatalogManagementScreen;
