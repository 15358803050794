import { CRow, CCol, CFormLabel, CButton, CFormInput, CToaster, CSpinner } from "@coreui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { DropDownItem, ProductBrand, ProductCategory, ProductSubCategory, Supply, SupplyType, Products, ProductItem } from "../../../types";
import '../AdminComponentStyles.css';
import { useEffect, useState } from "react";
import MultiRangeSlider, { ChangeResult } from "multi-range-slider-react";
import { ELV_BLUE, FONT_15 } from "../../../constants";
import Select from 'react-select';

import { getProductById, getTypeProductSupplies, fullTextSearchProducts, api } from "../../../services/ProductService";
import { getPalletById, fullTextSearchPallets } from "../../../services/PalletService";
import { useTranslation } from "react-i18next";
import { GenericErrorToast } from "../create-package-components/ToastsComponent";

interface FilterProps {
    setBrandist: (value: string[]) => void;   
    setCurrentSubGroupList: (value: string[]) => void;
    setArticleList: (value: string[]) => void;
    setCurrentGradeMin: (value: number) => void;
    setCurrentGradeMax: (value: number) => void;
    setCurrentStatus: (value: string) => void;
    setSupplyList: (value: string[]) => void;
    setCurrentSupplyTypeList: (value: string[]) => void;
    setSubGroupList: (value: number[]) => void;
    clearFilters: () => void;
    applyFilters: () => void;
    setTempProducts: (value: ProductItem[]) => void;
    setNoReultFound: (value: boolean) => void;
    setLastScannedText: (value: boolean) => void;
    getLatestProduct: () => void;
    tempProducts: ProductItem[];
    currentGradeMin: number;
    currentGradeMax: number;
    currentSubGroupList: string[];    
    currentSupplyTypeList: string[];
    noReultFound: boolean;
    // setSearchTerm: (value: string) => void;
    setLoading: (value: boolean) => void;
}
const ProductHorizontalFilterComponent = (props: FilterProps) => {
    const {
        setBrandist, setArticleList, setCurrentGradeMin, setCurrentGradeMax, tempProducts,
        currentSubGroupList, setSupplyList,  setCurrentSubGroupList, getLatestProduct, setTempProducts,
        currentGradeMin, currentGradeMax, setCurrentStatus, setNoReultFound, setLastScannedText,
        noReultFound, setSubGroupList, setLoading, //setSearchTerm
    } = props;
    const {t} = useTranslation();
    // const [supplyLoading, setSupplyLoading] = useState<boolean>(false);
    const productBrands = useSelector((state: RootState) => state.productSlice.productBrands);
    const productSupplies = useSelector((state: RootState) => state.productSlice.productSupplies);
    const productSubCategories = useSelector((state: RootState) => state.productSlice.productSubCategories);
    const productArticles = useSelector((state: RootState) => state.productSlice.productArticles);
    const subCategoryOptions: DropDownItem[] = productSubCategories.map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }));
    
    const subCategoryFilteredOptions: DropDownItem[] = productSubCategories.filter((subCat: ProductSubCategory) => {
        return tempProducts.some((item: ProductItem) => item?.sub_category == subCat.name)
    }).map((subCat: ProductSubCategory) => ({ value: subCat.id, label: subCat.name }));

    const seenBrands = new Set();  // To make sure the uniqueness of the brands verbose_name
    const brandOptions: DropDownItem[] = productBrands.filter((brand: ProductBrand) => {
        // Check if the brand.verbose_name has already been added
        if (tempProducts.some((item: ProductItem) => item?.brand == brand.verbose_name) && !seenBrands.has(brand.verbose_name)) {
            seenBrands.add(brand.verbose_name);  // Add the brand.verbose_name to the set
            return true;  // Include the brand in the filtered results
        }
        return false;  // Exclude duplicate brands
    }).map((brand: ProductBrand) => ({
        value: brand.verbose_name,
        label: brand.verbose_name,
    }));

    const productStatusOptions = [{ value: 'all', label: "All" }, { value: 'sold', label: "Sold" }, { value: 'not_sold', label: "Not sold" }];
    const articleOptions = productArticles.map((article: string) => ({ value: article, label: article }));
    const [supplyOptions, setSupplyOptions] = useState<any>(
        productSupplies.map((supply: Supply) => ({
        value: supply.id,
        label: supply.total_products > 0
          ? `${supply.id} (${supply.total_products})` 
          : `${supply.id} (${t('adminDashboard.CommonWords.in_progress')})`
        })
    ));
    const [articleSearchText, setArticleSearchText] = useState<string>('');
    const [supplySearchText, setSupplySearchText] = useState<string>('');
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [toast, addToast] = useState<any>(null);

    const filterProductById = (id: number) => {
        getProductById(id).then((response) => {
            setLastScannedText(false);
            setNoReultFound(false);
            setTempProducts([{
                ...response.data, 
                image_list: response?.data?.image_list
            }]);
        }).catch((e) => {
            getPalletById(id).then((response) => {
                setLastScannedText(false);
                setNoReultFound(false);
                setTempProducts([{
                    ...response.data, 
                }]);
            }).catch((e) => {
                setNoReultFound(true);
            });
        });
    };

    const fullTextSearchProductAndPallets = async (search_term: string) => {
        try {
          setShowSpinner(true);
          setLoading(true);
          setLastScannedText(false);
          setNoReultFound(false);
          // Fetch products with the highest probability of matching the provided search term
          const productsSearchResponse = await fullTextSearchProducts(search_term);
          const responseCount = productsSearchResponse?.data?.count;
          if(responseCount && responseCount > 0) {
            fetchFullTextSearchResults(productsSearchResponse, search_term);
          } else {
            const palletsSearchResponse = await fullTextSearchPallets(search_term);
            fetchFullTextSearchResults(palletsSearchResponse, search_term);
          }
        } catch (e:any) {
            setShowSpinner(false);
            setLoading(false);
            setNoReultFound(true);
            const errorMessage = e?.response?.data?.details || e?.message || 'An unknown error occurred';
            addToast(GenericErrorToast(() => { addToast(null) }, ("Error !! " + errorMessage)));
        }
    };

    // Function to fetch full-text-search results and set to setTempProducts
    const fetchFullTextSearchResults = async (responseObject:any, search_term:string): Promise<any> => {
        try {
            if (typeof responseObject !== 'object' || responseObject === null || responseObject === 'undefined') {
                setShowSpinner(false);
                addToast(GenericErrorToast(() => { addToast(null) }, ("Error !! Expected an object")));
            } else {
                let allResults = [...responseObject?.data?.results];
                let nextPageUrl = responseObject?.data?.next;
                setTempProducts([...responseObject?.data?.results]);
                setLoading(false);
                setShowSpinner(false);
                // Fetch additional pages of results using POST requests with search_term parameter and append output to previously fetched results
                while (nextPageUrl) {
                    const nextPageResponse = await api.post(nextPageUrl, { search_keyword: search_term });
                    nextPageUrl = nextPageResponse?.data?.next;
                    // Append new results to the existing ones
                    allResults = [...allResults, ...nextPageResponse?.data?.results];
                    // Update tempProducts progressively with the new results
                    setTempProducts(allResults);
                };
            };            
        } catch (e:any) {
            setShowSpinner(false);
            const errorMessage = e?.response?.data?.details || e?.message || 'An unknown error occurred';
            addToast(GenericErrorToast(() => { addToast(null) }, ("Error !! " + errorMessage)));
        }
    };

    useEffect(() => {
        setSupplyOptions(
            productSupplies.map((supply: Supply) => ({
              value: supply.id,
              label: supply.total_products > 0
                ? `${supply.id} (${supply.total_products})` 
                : `${supply.id} (${t('adminDashboard.CommonWords.in_progress')})`
            }))
        );
    }, [productSupplies]);

    const applyFilter = () => {
        setShowSpinner(true);
        setLoading(true);
        setLastScannedText(false);
        fullTextSearchProductAndPallets(searchTerm);
    };

    // Function to handle the Enter key press
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && searchTerm) {
            applyFilter();
        }
    };

    return (
        <>
        <CToaster push={toast} placement="top-end"/>
        <CRow className="col-sm-12">
            {/* Fuzzy Search */}
            <CCol className="margin-top-24 d-flex align-items-center" xs={12} md={8}>
                {/* <CFormLabel className="mr-2"><b>{t('Search')}</b></CFormLabel> */}
                <CFormInput
                    placeholder={`${t('adminDashboard.CommonWords.full_text_search')}`}
                    type='text'
                    onKeyDown={handleKeyDown}
                    onChange={(e: any) => {
                        if(e?.target?.value.length == 0) {
                            setNoReultFound(false);
                            getLatestProduct();
                            setSearchTerm('');
                        }
                        else {
                            setSearchTerm(e?.target?.value);
                            // fullTextSearchProductAndPallets(e?.target?.value);
                        }
                    }}
                />
            </CCol>
            
            <CCol xs={12} md={2} className="margin-top-24 justify-content-center align-items-center">
                <CButton
                    variant="outline"
                    shape="square"
                    className="elv-btn d-flex justify-content-center align-items-center"
                    onClick={applyFilter}
                    disabled={searchTerm?.length == 0 || showSpinner}
                    style={{ minWidth: '150px', height: '40px' }}
                    >
                    {showSpinner ? <CSpinner size="sm" /> : 'Search'}
                </CButton>
            </CCol>

            {/* <CCol className="margin-top-24" xs={12} md={2}>
                <CFormLabel><b>{t('adminDashboard.Products.product_id_search')}</b></CFormLabel>
                <CFormInput
                    placeholder={`${t('adminDashboard.CommonWords.search')}`}
                    type='number'
                    onChange={(e: any) => {
                        if(e?.target?.value.length == 0) {
                            setNoReultFound(false);
                            getLatestProduct();
                        }
                        else {
                            filterProductById(e?.target?.value);
                        }
                    }}
                />
            </CCol>
                
            <CCol className="margin-top-24" xs={12} md={2}>
                <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply_id')}</b></CFormLabel>
                <Select
                    placeholder={`${t('adminDashboard.CommonWords.select')}`}
                    isMulti
                    noOptionsMessage={({ inputValue: string }) => supplySearchText.length >= 3 ? 'Supply not found.' : '' }
                    options={supplyOptions}
                    onChange={(options: any) => {
                        setSupplyList(
                            options.map((option: any) => (option.value))
                        )
                    }} 
                    onInputChange = {(value: string)=>{
                        setSupplySearchText(value)
                    }}
                    // isLoading={supplyLoading}
                />
            </CCol>

            <CCol className="margin-top-24" xs={12} md={2} >
                <CFormLabel><b>{t('adminDashboard.Products.article')}</b></CFormLabel>
                <Select
                    isMulti
                    placeholder={t('adminDashboard.CommonWords.min_3_digits')}
                    noOptionsMessage={({ inputValue: string }) => articleSearchText.length >=3 ? 'Article not found.' : t('adminDashboard.CommonWords.min_3_digits')}
                    options={ articleSearchText.length >= 3 ? articleOptions : []}
                    onChange={(options: any) => {
                        setArticleList(
                            options.map((option: any) => (option.value))
                        )
                    }} 
                    onInputChange = {(option: string)=>{
                        setArticleSearchText(option)
                    }}
                />
            </CCol> */}
            
            {/* <CCol xs={12} md={2} className='margin-top-24'>
                <CFormLabel><b>{t('adminDashboard.Products.category')}</b></CFormLabel> */}
                {/* This used to be a sub cat, but it should be renamed according to the current portal, as asked by Boris.*/}
                {/* <Select
                    placeholder={t('adminDashboard.CommonWords.select')}
                    closeMenuOnSelect={false}
                    isMulti
                    options={subCategoryOptions} 
                    onChange={(options: any) => {
                        setSubGroupList(
                            options.map((option: any) => (option.value))
                        )
                    }}
                    styles={{
                        multiValueLabel: (base) => ({
                            ...base,
                            backgroundColor: ELV_BLUE,
                            color: 'white',
                            fontSize: FONT_15,
                        }),
                    }}
                />
            </CCol> */}
        </CRow>
        
        {
            tempProducts?.length > 1 && noReultFound == false ?
            <>
                <h4 className="margin-top-44"><b>Filters</b></h4>
                <CRow className="margin-top-16">
                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.Products.brands')}</b></CFormLabel>
                        <Select
                            placeholder={`${t('adminDashboard.CommonWords.select')}`}
                            isMulti
                            options={brandOptions}
                            onChange={(options: any) => {
                                setBrandist(
                                    options.map((option: any) => (option.label))
                                )
                            }}
                        />
                    </CCol>

                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.Products.category')}</b></CFormLabel>
                        <Select
                            placeholder={`${t('adminDashboard.CommonWords.select')}`}
                            isMulti
                            options={subCategoryFilteredOptions} 
                            onChange={(options: any) => {
                                setCurrentSubGroupList(
                                    options.map((option: any) => (option.label))
                                )
                            }}
                        />
                    </CCol>
                    <CCol xs={6} md={3}>
                        <CFormLabel><b>{t('adminDashboard.CommonWords.status')}</b></CFormLabel>
                        <Select
                            defaultValue={productStatusOptions[0]}
                            options={productStatusOptions} 
                            onChange={(option: any) => {
                                setCurrentStatus(option.value);
                            }}
                        />
                    </CCol>
                    <CCol xs={12} md={3} >
                        <CFormLabel><b>{t('adminDashboard.Products.grade_point')}</b></CFormLabel>
                        <MultiRangeSlider
                            minValue={currentGradeMin}
                            maxValue={currentGradeMax}
                            min={0}
                            max={100}
                            onInput={(e) => {
                                setCurrentGradeMin(e.minValue);
                                setCurrentGradeMax(e.maxValue);
                            }}
                            onChange={(e: ChangeResult) => {
                                // This function changes values after dragging is done.
                                setCurrentGradeMin(e.minValue);
                                setCurrentGradeMax(e.maxValue);
                            }}
                            label={true}
                            ruler={false}
                            style={{ border: "none", boxShadow: "none", padding: "15px 10px", marginTop: 25 }}
                            barLeftColor="white"
                            barInnerColor={ELV_BLUE}
                            barRightColor="white"
                            thumbLeftColor={ELV_BLUE}
                            thumbRightColor={ELV_BLUE}
                        />
                    </CCol>
                </CRow>
            </>
            : null
        }
      </>
    );
}
export default ProductHorizontalFilterComponent;