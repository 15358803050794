import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CustomerCartState {
    [packageId: number]: {
        productCart: number[];
        totalPrice: number;
        showRefreshButton: boolean;
    }

}

const initialState: CustomerCartState = {};
const customerCartSlice = createSlice({
    name: 'customerCartSlice',
    initialState,
    reducers: {
        customerRehydrateState(state, action: PayloadAction<any>) {
            return { ...state, ...action.payload };
        },
        addToCart: (state, action: PayloadAction<{ packageId: number, id: number; selling_price: number; combined_products?: number[] }>) => {
            const { packageId, id, selling_price, combined_products } = action.payload;
            const packageCart = getOrCreatePackageCart(state, packageId);

            const isInCart = packageCart.productCart.includes(id);
            if (isInCart) {
                // Remove item from cart
                removeFromCartFunc(packageCart, id, selling_price, combined_products);
            } else {
                // Add item to cart
                addToCartFunc(packageCart, id, selling_price, combined_products);
            }
        },
        removeFromCart: (state, action: PayloadAction<{ packageId: number, id: number; selling_price: number; combined_products?: number[] }>) => {
            const { packageId, id, selling_price, combined_products } = action.payload;
            const packageCart = getOrCreatePackageCart(state, packageId);

            removeFromCartFunc(packageCart, id, selling_price, combined_products);
        },
        setProductCart: (state, action: PayloadAction<{ packageId: number; productCart: number[] }>) => {
            const { packageId, productCart } = action.payload;
            const packageCart = getOrCreatePackageCart(state, packageId);

            packageCart.productCart = productCart;
        },
        setTotalPrice: (state, action: PayloadAction<{ packageId: number, totalPrice: number }>) => {
            const { packageId, totalPrice } = action.payload;
            const packageCart = getOrCreatePackageCart(state, packageId);

            packageCart.totalPrice = totalPrice;
        },
        setShowRefreshButton:  (state, action: PayloadAction<{ packageId: number, showRefreshButton: boolean }>) => {
            const { packageId, showRefreshButton } = action.payload;
            const packageCart = getOrCreatePackageCart(state, packageId);

            packageCart.showRefreshButton = showRefreshButton;
        },
    }
});

export const { addToCart, removeFromCart, setProductCart, setTotalPrice, setShowRefreshButton, customerRehydrateState } = customerCartSlice.actions;

export default customerCartSlice.reducer;

// Helper functions
function getOrCreatePackageCart(state: CustomerCartState, packageId: number) {
    if (!state[packageId]) {
        state[packageId] = { productCart: [], totalPrice: 0, showRefreshButton: false }; // Initialize with default values
    }
    return state[packageId];
}

function addToCartFunc(packageCart: { productCart: number[]; totalPrice: number }, id: number, selling_price: number, combined_products?: number[]) {
    if (combined_products && combined_products.length > 0) {
        packageCart.productCart = packageCart.productCart.filter(item => item !== combined_products[0] && item !== id);
        packageCart.productCart.push(...combined_products, id);
    } else {
        packageCart.productCart = [...packageCart.productCart, id];
    }
    packageCart.totalPrice += Number(selling_price);
}

function removeFromCartFunc(packageCart: { productCart: number[]; totalPrice: number }, id: number, selling_price: number, combined_products?: number[]) {
    if (combined_products && combined_products.length > 0) {
        packageCart.productCart = packageCart.productCart.filter(item => item !== combined_products[0] && item !== id);
        packageCart.productCart.splice(combined_products[0], 1);
    } else {
        const index = packageCart.productCart.findIndex(item => item === id);
        if (index !== -1) {
            packageCart.productCart.splice(index, 1);
        }
    }
    packageCart.totalPrice -= Number(selling_price);
}