import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CSpinner, CRow, CCol, CTooltip } from "@coreui/react";
import { useState, useEffect } from "react";
import { t } from "i18next";
import "../AdminComponentStyles.css";

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    closeModal: () => void;
    onClickEdit: (editCallBackFunction?: () => void) => void;
    confirmOrder?: (editCallBackFunction?: () => void) => void;
    editButtonText: string;
    packageStatus: string;
}
const ConfirmationModal = (props: ModalProps) => {
    const {isModalVisible, closeModal, headerText, onClickEdit, bodyText, editButtonText, confirmOrder, packageStatus} = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(packageStatus === 'F');
    
    useEffect(() => {
        setIsButtonDisabled(packageStatus === 'F')
    }, [packageStatus]);
    
    // Pulling code outside to show tooltip message and for code reusability
    const confirmOrderButton = <CButton
        color="success"
        onClick={() => {setLoading(true); confirmOrder && confirmOrder(()=> setLoading(false)) }}
        disabled={isButtonDisabled || loading}
        >
        Accept order
    </CButton>

    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal} className="zero-margin">
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{loading ? <CRow><CCol className="text-center"><CSpinner /></CCol></CRow> : bodyText}</div>
            </CModalBody>
            <CModalFooter>
                <CButton
                    onClick={() => {setLoading(true); onClickEdit(()=> setLoading(false))}}
                    disabled={loading}
                >
                    {editButtonText}
                </CButton>
                {
                    isButtonDisabled ? (
                        <CTooltip content={t('adminDashboard.Packages.already_ordered_edit_package_placed_order_message')} placement='top'>
                        <div style={{ display: 'inline-block' }}>
                            {confirmOrderButton}
                        </div>
                    </CTooltip>
                    ) : (
                        confirmOrderButton
                    )
                }
                <CButton color="danger" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default ConfirmationModal;