
import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setSetArticles, setSelectedProducts, setSelectedSetArticles, setCart, setPackageProducts, setPallets, setSelectedPallets, setPackageTotalPrice, setPackageDiscount } from '../../../redux/slices/PackageSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ProductItem, PossibleArticlePairs, Cart, PairedCartItem, Package, Pallet } from '../../../types';
import { CCol, CRow, CToaster } from '@coreui/react';

// Import Custom Components.
import PackageSearchComponent from '../../components/create-package-components/PackageSearchComponent';
import { getAutomaticPairs, getSetArticlePairs, syncCart, getPackage, modifyPackage, getPackageSnapshots, revertPackageSnapshot} from '../../../services/PackageService';

import ConfirmationModal from '../../components/modals/ConfirmationModal';
import PackageHeaderComponent from '../../components/create-package-components/PackageHeaderComponent';
import { editProductDetails, getProductById } from '../../../services/ProductService';

import { alreadyPairedToast, pairedFoundToast, noPairFoundToast, AddedToCartToast, RemovedFromCartToast, TruckErrorToast, GenericErrorToast, GenericSuccessToast } from '../../components/create-package-components/ToastsComponent';
import { placeOrder } from '../../../services/OrderService';
import Modal from '../../components/modals/PairArticleModal';
import SnapshotModal from '../../components/modals/SnapshotModal';
import PlaceHolder from '../../components/product-components/PlaceHolder';
import ProductCardComponent from '../../components/product-card-component';
import OrderSummary from '../../components/create-package-components/Summary';
import { Utils } from '../../components/utils';
import PalletCardComponent from '../../components/pallet-card-component';
import { editPalletDetails } from '../../../services/PalletService';
import PackageFiltersComponent from '../../components/create-package-components/PackageFiltersComponent';
import { getOrderPDFURL } from '../../components/DownloadPDF';
// import { setOrders } from '../../redux/slices/OrderSlice';

const EditPackageScreen = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const props = useLocation();
    const {getPackagesData, getPendingPackagesData, getAllProductSupplies, getOrdersData, getAllProductBrands, getUsageData, getAllProductSubCategories} = Utils();
    
    const cart: Cart = useSelector((state: RootState) => state.packageSlice.cart);
    const [packageItem, setPackageItem] =  useState<Package>();
    const products: ProductItem[] = useSelector((state: RootState) => state.packageSlice.products);
    const pallets: Pallet[] = useSelector((state: RootState) => state.packageSlice.pallets);
    const setArticles: ProductItem[] = useSelector((state: RootState) => state.packageSlice.setArticles);
    const discount =  useSelector((state: RootState) => state.packageSlice.discount);
    const selectedProducts: number[] = useSelector((state: RootState) => state.packageSlice.selectedProducts);
    const selectedPallets: any = useSelector((state: RootState) => state.packageSlice.selectedPallets);
    const selectedSetArticles: number[] = useSelector((state: RootState) => state.packageSlice.selectedSetArticles);
    const customers = useSelector((state: RootState) => state.usersSlice.customers);

    const [loading, setLoading] = useState<boolean>(true);
    const [currentBrandList, setCurrentBrandList] = useState<string[]>([]);  
    const [currentArticleList, setCurrentArticleList] = useState<string[]>([]);
    const [currentSubGroupList, setCurrentSubGroupList] = useState<string[]>([]);
    const [currentUsageList, setCurrentUsageList] = useState<string[]>([]);
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(100);      
    const [selectedCustomer, setSelectedCustomer] = useState<any>(customers && customers[0]);
    const [truckCapacity, setTruckCapacity] = useState<number>(0);
    const [totalProducts, setTotalProducts] = useState<number>(0);
    const [snapshots, setSnapshots] = useState<any>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [priceInputId, setPriceInputId] = useState<string>('');
    const [priceInputValue, setPriceInputValue] = useState<number>(0);
    const [selectedTab, setSelecteTab] = useState<0 | 1 | 2 | 3>(0); // 0 stands for the standalone products. 1 stands for set articles.
    const [automaticPairing, setAutomaticPairing] = useState<boolean>(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState<boolean>(false);
    const [optionsModalVisible, setOptionsModalVisible] = useState<boolean>(false);
    const [toast, addToast] = useState<any>(null);
    const [pairOptions, setPairOptions] = useState<PossibleArticlePairs | null>(null);
    const [smartPairLoading, setSmartPairLoading] = useState<boolean>(false);
    const [addToCart, setAddToCart] = useState<boolean>(true);
    const [packageStatus, setPackageStatus] = useState<string>('');
    const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);
    
    const resetToDefaults = () => {
        dispatch(setSelectedSetArticles([]));
        dispatch(setSelectedProducts([]));
        dispatch(setPackageProducts([]));
        dispatch(setSetArticles([]));
        dispatch(setPackageTotalPrice(0));
        dispatch(setPackageDiscount(0));
        dispatch(setCart({
            ...cart,
            standalone: [],
            unpaired: [],
            paired: [],
        }));
    }

    const getPackageById = (id: number) => { // gets the packaged products and add them into the cart.      
        getPackage(id).then((response) => {
            // Now we have to set the selected products, set articles and the cart respectively.
            let packageItem: Package = response.data;
            let updatedProducts: ProductItem[] = [];
            let updatedPallets: Pallet[] = [];
            let updatedSetArticles: ProductItem[] = [];
            let paired: PairedCartItem[] = [];
            let unpaired: number[] = [];
            let standalone: number[] = [];
            let pallets: number[] = [];
            let updatedSelectedProducts: number[] = [];
            let updatedSelectedPallets: number[] = [];
            let updatedSelectedSetArticles: number[] = [];
            let productsAlreadyCombined: number[] = [];
            
            // Set package status to resetrict users to perform action while editing package
            setPackageStatus(packageItem?.status);
            packageItem.set_articles.map((item: any) => {
                if (item?.combined_products && item?.combined_products?.length > 0) {
                    if(!productsAlreadyCombined.includes(item.id)) {
                        productsAlreadyCombined.push(item.id);
                        productsAlreadyCombined.push(item.combined_products[0]);
                        paired.push(
                            {
                                is_manual: true, // TODO: Currently just hardcoding the is_manual but need to updated it from backend.
                                set_articles: [item.id, item.combined_products[0]]
                            }
                        )
                    }
                    updatedSetArticles.push(item);
                    updatedSelectedSetArticles.push(item.id);
                }
                else if (item?.combined_products && item?.combined_products?.length === 0) { // checks if the item is unpaired set article.
                    unpaired.push(item.id);
                    updatedSetArticles.push(item);
                    updatedSelectedSetArticles.push(item.id);
                }
            });
            packageItem.standalone_products.map((item: any) => {
                standalone.push(item.id);
                updatedProducts.push(item);
                updatedSelectedProducts.push(item.id);
            });
            packageItem.pallets_products.map((item: any) => {
                pallets.push(item.id);
                updatedPallets.push(item);
                updatedSelectedPallets.push(item.id);
            });                
            dispatch(setSetArticles(updatedSetArticles));
            dispatch(setPackageProducts(updatedProducts));
            dispatch(setPallets(updatedPallets));
            dispatch(setCart({
                unpaired: unpaired,
                paired: paired,
                standalone: standalone,
                pallets: pallets,
            }));
            setTotalProducts(updatedSelectedSetArticles.length + updatedSelectedProducts.length + updatedSelectedPallets.length);
            dispatch(setSelectedProducts(updatedSelectedProducts));
            dispatch(setSelectedPallets(updatedSelectedPallets));
            dispatch(setSelectedSetArticles(updatedSelectedSetArticles));                
            setPackageItem(packageItem);
            setSelectedCustomer(packageItem.customer_details);
            getPackageSnapshots(packageItem.id).then((response) => {
                setLoading(false);
                setSnapshots(response.data);
            }).catch((e) => {
                console.log('error while getting snaps: ', e);
            });
        }).catch((e) => {
            console.log('getPackageById error: ', e);
            setLoading(false);
        });
    }
    useEffect(() => {
        getPackageById(props?.state?.packageId);
        getAllProductBrands();
        getUsageData();
        getAllProductSubCategories();
    }, []);

    useEffect(() => {
       console.log('totalProducts: ', totalProducts)
    }, [totalProducts]);
       
       
    useEffect(() => {
        if(snapshots.length > 1) {
            let deselectedProductsIds: number[] = [];
            snapshots.map((snapshot: any) => { 
                // Get all the missing products ever added in this package since the begining of 1st snapshot.
                snapshot.products.map((productId: number) => {
                    if((!setArticles?.some(article => article.id === productId)) && (!products?.some(article => article.id === productId))) {
                        deselectedProductsIds.push(productId);
                    };
                });
            });

            // Following code makes the missing product ids distinct and unique.
            deselectedProductsIds = deselectedProductsIds.filter((value, index, self) =>
                index === self.indexOf(value)
            );
           
            getMissingProducts(deselectedProductsIds);
        }
    }, [snapshots]);    

    useEffect(() => {
        if(pairOptions && pairOptions?.availableOptions.length > 0){
            setOptionsModalVisible(true);
        }
    }, [pairOptions]);

    useEffect(() => {
        if(cart && cart?.paired.length >= 0){
           // call the synch cart API.
           calculatePackagePrice();
           syncCart(cart.paired).then((response) => {
           }).catch((e) => {
                console.log('syncing error: ');
           });
        }
    },[cart.paired]);

    useEffect(() => {
        if(cart && cart?.standalone?.length >= 0){         
           calculatePackagePrice();           
        }
    },[cart?.standalone]);

    useEffect(() => {
        if(cart && cart?.pallets?.length >= 0){         
           calculatePackagePrice();
        }
    },[cart?.pallets]);

    useEffect(() => {
        if(cart && cart?.unpaired?.length >= 0){          
           calculatePackagePrice();           
        }
    },[cart?.unpaired]);

    const calculateItemsSelected = () => {
       return (selectedProducts.length + selectedSetArticles.length) >= totalProducts ? 100 : ((selectedProducts.length + selectedSetArticles.length) / totalProducts) * 100;
    }
    const getMissingSetArticles = (deselectedProductsIds: number[]) => {
        let missingProductsResponse: ProductItem[] = [];
        deselectedProductsIds.map(async (item: number) => {{
            await getProductById(item).then((response) => {
                missingProductsResponse.push(response.data); 
            }).catch((e) => {
                console.log('get products error: ', e);
            });
            if(missingProductsResponse.length === deselectedProductsIds.length) {
                console.log(missingProductsResponse);
                dispatch(setSetArticles([ ...missingProductsResponse, ...setArticles]));                
            }
        }});
    } 
    
    const getMissingProducts = (deselectedProductsIds: number[]) => {
        let missingProductsResponse: ProductItem[] = [];
        let missingSetArticles: ProductItem[] = [];
        let missingProducts: ProductItem[] = [];
        
        deselectedProductsIds.map(async (item: number) => {{
            await getProductById(item).then((response) => {
                missingProductsResponse.push(response.data); 
            }).catch((e) => {
                console.log('get products error: ', e);
            });
            if(missingProductsResponse.length === deselectedProductsIds.length) {              
                setTotalProducts((cart.paired.length * 2) + cart.unpaired.length + cart.standalone.length + deselectedProductsIds.length);
                missingProductsResponse.map((product) => {
                    if(product.article.includes('-')) {
                        missingSetArticles.push(product);
                    }
                    else {
                        missingProducts.push(product);
                    }
                });
                dispatch(setPackageProducts([ ...products, ...missingProducts]));
                dispatch(setSetArticles([ ...missingSetArticles, ...setArticles]));
            }
        }});
      
    }
    const updateSelectedProducts = (product: number) => {
        let updatedSelectedProducts: number[] = [...selectedProducts];
       
        if(!updatedSelectedProducts.includes(product)){            
            updatedSelectedProducts.push(product);                
            addProductToCart(product);
            dispatch(setSelectedProducts(updatedSelectedProducts));
            addToast(AddedToCartToast(()=>{addToast(null)}));            
        }
        else {
            updatedSelectedProducts = updatedSelectedProducts.filter((item) => {
                return item !== product;
            });
            dispatch(setSelectedProducts(updatedSelectedProducts));
            removeStandaloneFromCart(product);
            addToast(RemovedFromCartToast(()=>{addToast(null)}));            
        }
    }
    const updateSelectedPallets = (pallet: any) => {
        let updatedSelectedPallets: any = [...selectedPallets];
        if(!updatedSelectedPallets?.includes(pallet)){ // add product to cart and selected product items list.               
            updatedSelectedPallets.push(pallet);
            addPalletsToCart(pallet); 
            dispatch(setSelectedPallets(updatedSelectedPallets));
        }
        else { // deselect / remove the product item from the cart. when click.
            updatedSelectedPallets = updatedSelectedPallets?.filter((item: any) => {
                return item !== pallet;
            });
            dispatch(setSelectedPallets(updatedSelectedPallets));
            removePalletsFromCart(pallet);
            addToast(RemovedFromCartToast(()=>{addToast(null)}));
        }
    }
    const updateSelectedSetArticles = (setArticle: number) => {
        let updatedSelectedSetArticles: number[] = [...selectedSetArticles];      

        if(!updatedSelectedSetArticles.includes(setArticle)) {
            // add it to the cart on backend side to reserve the item.
            updatedSelectedSetArticles.push(setArticle);                
            addSetArticleToCart(setArticle);
            dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
            addToast(AddedToCartToast(()=>{addToast(null)}));
        }
        else {
            updatedSelectedSetArticles = updatedSelectedSetArticles.filter((item) => {
                return item !== setArticle;
            });
            dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
            if(cart.unpaired.includes(setArticle)) {
                removeUnpairedFromCart(setArticle)
            }
            else {
                removePairedFromCart(setArticle);
            }
            addToast(RemovedFromCartToast(()=>{addToast(null)}));
        }
    }

    const addProductToCart = (product: number) => {
        let standalone: number[] = [...cart.standalone];
        let updatedStandalone = standalone.filter((cartItem: number) => {
            return (cartItem === product)
        });
        if(updatedStandalone.length === 0) { // the product is not in the cart to we have to append it.
            standalone.push(product);
            dispatch(setCart({
                ...cart,
                standalone: standalone,
            }));
        } // else do nothing.
    }

    const addPalletsToCart = (pallet: any) => {
        let pallets: any = [...cart.pallets];
        let updatedPallets = pallets.filter((cartItem: any) => {
            return (cartItem === pallet)
        });
        if(updatedPallets?.length === 0) { // the product is not in the cart to we have to append it.
            pallets.push(pallet);
            dispatch(setCart({
                ...cart,
                pallets: pallets,
            }));
        } // else do nothing.
    }

    const addSetArticleToCart = (selectedSetArticle: number, suggestedPair?: number) => {
        let paired: PairedCartItem[] = [...cart.paired];
        let unpaired: number[] = [...cart.unpaired];
        
        if(!suggestedPair) {
            // If there is no second argument then it means it has been called just to add an item in the cart.
            let updatedUnpaired = unpaired.filter((cartItem: number) => {
                return (cartItem === selectedSetArticle)
            });
            if(updatedUnpaired.length === 0) { // the set article is not in the cart to we have to append it.
                unpaired.push(selectedSetArticle);
            }
            dispatch(setCart({
                ...cart,
                unpaired: unpaired,
            }));
        }
        else {
            // If there is a second argument passed to this function it means we have to pair these 2 and, 
            // check if one of them is already paired then give error message to the user.
            let updatedPaired = paired.filter((cartItem: PairedCartItem) => {
                return (cartItem.set_articles.includes(selectedSetArticle) || cartItem.set_articles.includes(suggestedPair))
            });
            if(updatedPaired.length === 0) { // Both of the articles are not paired and this is the simplest case.
                paired.push({
                    is_manual: true,
                    set_articles: [
                        selectedSetArticle,
                        suggestedPair
                    ]
                });
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });
            }
            else if(updatedPaired.length === 2) { // Both of them are in the cart but they are single, not paired.
                updatedPaired.map((item: any) => {
                    paired = paired.filter((cartItem: PairedCartItem) => {
                        return (!cartItem.set_articles.includes(item.set_articles[0]))
                    });
                })
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );

                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });                   
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });
            }
            else if(updatedPaired.length === 1 && updatedPaired[0].set_articles.length === 2) { // Optional : we can show error to the user that which one is already paired which one is not.
                // one of them is already paired. show error toast
                addToast(alreadyPairedToast(()=>{addToast(null)}));             
            }

            else if(updatedPaired.length === 1 && updatedPaired[0].set_articles.length === 1 && updatedPaired[0].set_articles.includes(selectedSetArticle)) {
                paired = paired.filter((cartItem: PairedCartItem) => {
                    return (!cartItem.set_articles.includes(selectedSetArticle))
                });
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );

                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });                   
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
               
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });  
            }
            else if(updatedPaired.length === 1 &&  updatedPaired[0].set_articles.length === 1 && updatedPaired[0].set_articles.includes(suggestedPair)) {
                paired = paired.filter((cartItem: PairedCartItem) => {
                    return (!cartItem.set_articles.includes(suggestedPair))
                });
                paired.push(
                    {
                        is_manual: true,
                        set_articles: [
                            selectedSetArticle,
                            suggestedPair,
                        ]
                    }
                );
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== selectedSetArticle)
                });                   
                
                // Now we have to remove both of these parts of pair from the unpaired array if they exists.
                
                unpaired = unpaired.filter((cartItem: number) => {
                    return (cartItem !== suggestedPair)
                });  
            }
            dispatch(setCart({
                ...cart,
                unpaired: unpaired,
                paired: paired,
            }));
        }
    }
    const removePalletsFromCart = (pallet: any) => {
        // This function can be done using simple splice.        
        let pallets: any = [...cart.pallets];

        pallets = pallets?.filter((cartItem: any) => { // // then remove this product also from the cart.
            return (!(cartItem === pallet))
        });
        dispatch(setCart({
            ...cart,
            pallets: pallets,
        }));        
    }
    const removePairedFromCart = (setArticle: number) => {
        // this can also be used in case of un pair the set articles
        // removing any one of them from the cart should remove both.
        let updatedSelectedSetArticles = [...selectedSetArticles];
        let paired: PairedCartItem[] = [...cart.paired];

        let updatedPaired = paired.filter((cartItem: PairedCartItem) => {
            return (cartItem.set_articles.includes(setArticle))
        });

        updatedPaired[0].set_articles.map((cartItem: number) => { // First remove this article also from the selected set article array list.
            updatedSelectedSetArticles = updatedSelectedSetArticles.filter((item: number) => {
                return item !== cartItem;
            });           
        })

        paired = paired.filter((cartItem: PairedCartItem) => { // // then remove this article also from the cart.
            return (!cartItem.set_articles.includes(setArticle))
        });

        dispatch(setCart({
            ...cart,
            paired: paired,
        }));

        dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
    }

    const removeUnpairedFromCart = (setArticle: number) => {
        // This function can be done using simple splice.         
        let unpaired: number[] = [...cart.unpaired];
        unpaired = unpaired.filter((cartItem: number) => { // // then remove this article also from the cart.
            return (!(cartItem === setArticle))
        });
        dispatch(setCart({
            ...cart,
            unpaired: unpaired,
        }));        
    }

    const removeStandaloneFromCart = (product: number) => {
        // This function can be done using simple splice.  
        let standalone: number[] = [...cart.standalone];
        standalone = standalone.filter((cartItem: number) => { // // then remove this article also from the cart.
            return (!(cartItem === product))
        });

        dispatch(setCart({
            ...cart,
            standalone: standalone,
        }));       
    }

    const pairSetArticle = (selectedItem: ProductItem, option: ProductItem) => {
        // This function is only meant to be used for manual pairing
        let updatedSelectedSetArticles: number[] = [...selectedSetArticles];
        const IsSetArticleSelected = updatedSelectedSetArticles.includes(option.id)
        const IsSetArticleExists = setArticles.some(article => article.id === option.id)

        if(!updatedSelectedSetArticles.includes(selectedItem.id)) {
            updatedSelectedSetArticles.push(selectedItem.id); // Currently pressed pair item will be selected.
        }

        if (option?.article && !IsSetArticleSelected && !IsSetArticleExists) {
            // we have to pair
            dispatch(setSetArticles([...setArticles, option]));
            updatedSelectedSetArticles.push(option.id);
        }

        // In the below conditions, the pair is on the front end side then we push it in our selected set article array.
        else if (option?.article?.length > 0 && !IsSetArticleSelected && IsSetArticleExists) {
            updatedSelectedSetArticles.push(option.id); // Currently pressed pair item will be selected.
        }
        else {
            // console.log('No more pair found.', option);
        }
        // calculate the truck capacity first then 
        
        addSetArticleToCart(selectedItem.id, option.id);
        dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
        addToast(pairedFoundToast(()=>{addToast(null)}));
        addToast(AddedToCartToast(()=>{addToast(null)}));
    };
    const handleAutomaticPairingResponse = (response: {
        paired_temp_sets: PairedCartItem[],
        unpaired_ids: number[],
        already_existing_temp_sets: PairedCartItem[],
    }) => {
        let paired: PairedCartItem[] = [];
        let unpaired: number[] = [];
        let missingProducts: number[] = [];
        let updatedSelectedSetArticles =  [...selectedSetArticles];

        response.paired_temp_sets.map((item: PairedCartItem) => {
            paired.push(item);
            if(!setArticles.some(article => article.id === item.set_articles[0])){ 
                // if the corresponding pair is not loaded on the front end then we have to bring it from backend.
                updatedSelectedSetArticles.push(item.set_articles[0]);
                missingProducts.push(item.set_articles[0]);
            }
            else if(setArticles.some(article => article.id === item.set_articles[0]) && !updatedSelectedSetArticles.includes(item.set_articles[0])) {
                // if the corresponding pair is loaded on the front end but it is not in the selected list.
                updatedSelectedSetArticles.push(item.set_articles[0]);
            }

            // Checks both of the paired articles coming from backend if any one of them is not loaded then we have to get it from server.
            if(!setArticles.some(article => article.id === item.set_articles[1])) {
                // if the corresponding pair is not loaded on the front end then we have to bring it from backend.
                updatedSelectedSetArticles.push(item.set_articles[1]);
                missingProducts.push(item.set_articles[1]);
            }
            else if(setArticles.some(article => article.id === item.set_articles[1]) && !updatedSelectedSetArticles.includes(item.set_articles[1])) {
                // if the corresponding pair is loaded on the front end but it is not in the selected list.
                updatedSelectedSetArticles.push(item.set_articles[1]);
            }
        });
        response.unpaired_ids.map((item: number) => {
            unpaired.push(item);
        });
        response.already_existing_temp_sets.map((item: PairedCartItem) => {
            paired.push(item);
        });
        getMissingSetArticles(missingProducts); // by default this function will consider it set article.
        dispatch(setSelectedSetArticles(updatedSelectedSetArticles));
        dispatch(setCart(
            {
                ...cart,
                paired: paired,
                unpaired: unpaired,
            }
        ));
       
    }
    
    const getPairedSetArticlesOptions = (e: any, item?: ProductItem | undefined, callBackFunction?: () => void) => {
        e.stopPropagation() // prevents the click event of parent element.
        
        if(item) {       
            getSetArticlePairs(item).then((response) => {
                // console.log(response);
                if (response?.data?.possible_pairs?.length === 0){
                    if(response?.data?.already_has_pair) {
                        addToast(alreadyPairedToast(()=>{addToast(null)}));
                    }
                    else {
                        addToast(noPairFoundToast(()=>{addToast(null)}));
                    }
                   
                    setPairOptions({
                        selectedItem: item,
                        availableOptions: [],
                    });
                }
                else {
                    setPairOptions({
                        selectedItem: item,
                        availableOptions: response.data.possible_pairs,
                    });
                }
            }).catch((e) => {
                console.log('exception while getting manual pairs: ', e);
                addToast(noPairFoundToast(()=>{addToast(null)}));
            });
        }
        else if (automaticPairing) {
            getAutomaticPairs(selectedSetArticles).then((response) => {
                addToast(pairedFoundToast(()=>{addToast(null)}));
                callBackFunction &&  callBackFunction();
                handleAutomaticPairingResponse(response.data);
            }).catch((e) => {
                console.log('exception while getting automatic pairs: ', e);
                if(e?.response?.data) {
                    addToast(noPairFoundToast(()=>{addToast(null)}));
                    callBackFunction &&  callBackFunction();
                    handleAutomaticPairingResponse(e.response.data);
                }
            });         
        }   
    }

    const editPackage = (editCallBackFunction?: () => void) => {
        if(packageItem?.id) {
            let productIds: any = [];
            productIds = [...cart.standalone, ...cart.unpaired, ...cart.pallets];
            cart.paired.map((item)=>{
                productIds =  [...productIds, ...item.set_articles];
            });
            let formData =  {
                "products": productIds,
                discount: discount / 100,
                add_to_cart: addToCart,
                products_selection_percentage: 0.000,
            }
            modifyPackage(packageItem.id, formData).then((response) => {
                getPackagesData();
                getPendingPackagesData();
                getOrdersData();
                getAllProductSupplies();
                resetToDefaults();
                navigation('/admin/dashboard/home');
            }).catch((e) => {
                console.log(console.log('Error while editing package: ', e));
                editCallBackFunction && editCallBackFunction();
                setConfirmationModalVisible(false);
                if(e?.response?.data?.discount) {
                    addToast(GenericErrorToast(()=>{addToast(null)}, 'Please Ensure you have the valid discount field.'));
                }
            });
        }
    }
   
    const confirmOrder = (editCallBackFunction?: () => void) => {
        if(packageItem?.id) {
            let productIds: any = [];
            productIds = [...cart.standalone, ...cart.unpaired, ...cart.pallets];
            cart.paired.map((item)=>{
                productIds =  [...productIds, ...item.set_articles];
            });
            let formData =  {
                "products": productIds,
                discount: discount / 100,
                add_to_cart: addToCart,
                accept_order: true,
            }
            modifyPackage(packageItem.id, formData).then((response) => {                
                const itemsSelected = calculateItemsSelected();
                let form_data =  {
                    package: packageItem.id,
                    truck_capacity: truckCapacity,
                    items_selected: itemsSelected.toFixed(2),
                }                
                getAllProductSupplies();
                placeOrder(form_data).then((placeOrderResponse) => {
                    getOrderPDFURL(placeOrderResponse.data.id);
                    resetToDefaults();
                    getPackagesData();
                    getPendingPackagesData();
                    navigation('/admin/dashboard/orders/requests', {state: {reload: true}});
                }).catch((e: any) =>{
                    editCallBackFunction && editCallBackFunction();
                    addToast(GenericErrorToast(()=>{addToast(null)}, e?.response?.data));
                })
            }).catch((e) => {
                addToast(GenericErrorToast(()=>{addToast(null)}, e?.response?.data));
                editCallBackFunction && editCallBackFunction();
            });
        }
    }

    const revert = (snapshotId: number) => {
        if(packageItem?.id) {
            resetToDefaults();
            setLoading(true);
            setIsModalVisible(false);   
            revertPackageSnapshot(packageItem.id, snapshotId).then((response) => {
                getPackagesData();
                getPackageById(packageItem.id);
            }).catch((e) => {
                console.log('Error while replacing snapshot: ', e);
                getPackageById(packageItem.id);
            });
        }
    }
    
    const setSellingPriceState = (e: any, productId: number, selling_price: number) => {
        e.stopPropagation();
        setPriceInputId(`selling-price-input-${productId}`);
        setPriceInputValue(selling_price);
    }
  
    const changeProductPrice = (e: any, id: any, type: string) => { 
        e.stopPropagation();     
        let form_data = {
            selling_price: priceInputValue,
        }
        if (type === 'pallet') {
            editPalletDetails(id, form_data).then((response) => {
                const newPallets = pallets?.map((item: Pallet) => {
                    if(item.id === id){
                        return {
                            ...item, // Copy existing fields
                            selling_price: priceInputValue, // Update selling_price
                          };
                    }
                    else {
                        return item;
                    }
                })
                dispatch(setPallets(newPallets));
                setPriceInputId('');
                setPriceInputValue(0);
            })
            .catch((e) => {
            })
        }
        else {
            editProductDetails(id, form_data).then((response) => {
                console.log('success: ');
                if(type === 'set-articles') {
                    const newSetArticles = setArticles.map((item: ProductItem) => {
                        if(item.id === id){                       
                            return {
                                ...item, // Copy existing fields
                                selling_price: priceInputValue, // Update selling_price
                            };
                        }
                        else {
                            return item;
                        }                
                    })
                    dispatch(setSetArticles(newSetArticles));           
                }
                else if(type === 'products') {
                    const newProducts = products.map((item: ProductItem) => {
                        if(item.id === id){                       
                            return {
                                ...item, // Copy existing fields
                                selling_price: priceInputValue, // Update selling_price
                            };
                        }
                        else {
                            return item;
                        }
                    })
                    dispatch(setPackageProducts(newProducts));
                }
                setPriceInputId('');
                setPriceInputValue(0);
            }).catch((e) => {
                
            });
        }
    }

    const calculatePackagePrice = () => {
        let packagePrice = 0;
        products?.map((productItem: ProductItem) => {
            if(cart.standalone.includes(productItem.id)){
                packagePrice = packagePrice + Number(productItem.selling_price);
            }
        });
        pallets?.map((palletItem: Pallet) => {
            if(cart.pallets.includes(palletItem.id)){
                packagePrice = packagePrice + Number(palletItem.selling_price);
            }
        });
        setArticles?.map((productItem: ProductItem) => {
            if(cart.unpaired.includes(productItem.id)){
                packagePrice = packagePrice + Number(productItem.selling_price);
            }
            else {
                cart.paired.map((pairedItem: PairedCartItem) => {
                    if(pairedItem.set_articles.includes(productItem.id)) {
                        packagePrice = packagePrice + Number(productItem.selling_price);
                    }
                })
            }
        });
        
       dispatch(setPackageTotalPrice((packagePrice.toFixed(2))));
    }

    return (
        <div style={{backgroundColor: 'white'}}> 
            <PackageHeaderComponent
                edit={true}
                selectedPallets={selectedPallets}
                headerText = "Edit Package"
                productsLength={products?.length}
                setSelecteTab={setSelecteTab}
                selectedTab={selectedTab}
                setArticlesLength={setArticles?.length}
                palletLength={pallets?.length}
                cartLength={cart.paired.length + cart.unpaired.length + cart.standalone.length + cart?.pallets?.length}
                automaticPairing={automaticPairing}
                setAutomaticPairing={setAutomaticPairing}
                getPairedSetArticlesOptions={getPairedSetArticlesOptions}
                EditPackage={() => setConfirmationModalVisible(true)}
                truckCapacity={truckCapacity}
                getAllSnapshots={() => setIsModalVisible(true)}
                setTruckCapacity={setTruckCapacity}
                selectedProducts={selectedProducts}
                selectedSetArticles={selectedSetArticles}
                selectedCustomer={selectedCustomer}
                calculatePackagePrice={calculatePackagePrice}
                truckErrorCallBack = {() => addToast(TruckErrorToast(()=>{addToast(null)}))}
                setSmartPairLoading={setSmartPairLoading}
                smartPairLoading={smartPairLoading}
                addToCart={addToCart}
                setAddToCart={setAddToCart}
                setPackageStatus={setPackageStatus}
                showRefreshButton={showRefreshButton}
                setShowRefreshButton={setShowRefreshButton}
            />         
            <CToaster push={toast} placement="top-end" />
            <SnapshotModal 
                isModalVisible={isModalVisible}
                headerText={'Revert to previous snapshots'}
                bodyText={'Which snapshot would you like to revert to? '}
                closeModal={() => {
                    setIsModalVisible(false);
                }}
                snapshots={snapshots}
                revert={revert}
            />
            <ConfirmationModal
                isModalVisible={confirmationModalVisible}
                closeModal={()=>setConfirmationModalVisible(false)}
                headerText={"Are you sure you want to confirm this package?"}
                bodyText={"This will place a confirm order and cannot be edited afterwards."}
                onClickEdit={(editCallBackFunction?: () => void) => {
                    editPackage(editCallBackFunction);
                }}
                editButtonText={"Just edit"}
                confirmOrder={confirmOrder}
                packageStatus={packageStatus}
            />
            <Modal
                isModalVisible={optionsModalVisible}
                closeModal={()=>setOptionsModalVisible(false)}
                pairOptions={pairOptions}
                pairSetArticle={pairSetArticle}
                cart={cart}
                alreadyPairedToast={alreadyPairedToast}
                addToast={addToast}
            />
           
            <div className='website-settings-main-container'>
                <CRow>
                    <CCol xs={12} md={2}>
                        <PackageSearchComponent
                            selectedCustomer={selectedCustomer}
                            setSelectedCustomer ={setSelectedCustomer}
                            selectedTab={selectedTab}
                            editPackage={true}
                            setLoading={setLoading}
                            setSmartPairLoading={setSmartPairLoading}
                            packageStatus={packageStatus}
                            current_package_id={packageItem?.id}
                            showCustomerToast = {() => addToast(GenericSuccessToast(()=>{addToast(null)}, 'Customer edited successfully.'))}
                            setShowRefreshButton={setShowRefreshButton}
                        />
                    </CCol>
                    <CCol xs={12} md={10}>
                    {
                        loading ?
                            <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                <PlaceHolder />
                            </CCol>
                        :   null
                    }
                    {
                         ((selectedTab === 0 && products.length > 0) || (selectedTab === 1 && setArticles.length > 0)) ?
                            <PackageFiltersComponent 
                                selectedTab = {selectedTab}
                                editPackage={true}
                                setCurrentBrandList={setCurrentBrandList}
                                setCurrentSubGroupList={setCurrentSubGroupList}
                                setCurrentArticleList={setCurrentArticleList}
                                setCurrentUsageList={setCurrentUsageList}
                                setMinValue={setMinValue}
                                setMaxValue={setMaxValue}
                                minValue={minValue}
                                maxValue={maxValue}
                            />
                            : null
                    }
                        <CRow>
                            {
                                selectedTab === 0 ?
                                    products.length === 0 ?
                                        <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                            <h5>No product within the applied filter</h5>
                                        </CCol>
                                    :
                                    products?.filter((product: any) => {
                                        if (product?.final_points >= minValue && product.final_points <= maxValue) {
                                            const brandMatch = currentBrandList.length === 0 || currentBrandList.includes(product.brand);
                                            const subCatMatch = currentSubGroupList.length === 0 || currentSubGroupList.includes(product.sub_category);
                                            const articleMatch = currentArticleList.length === 0 || currentArticleList.includes(product.article);
                                            const usageMatch = currentUsageList.length === 0 || currentUsageList.includes(product.usage.name);
                                        
                                            return brandMatch && articleMatch && usageMatch && subCatMatch;
                                        }
                                        else
                                        {
                                            return false
                                        }
                                      }).map((item: ProductItem) => {
                                        // rendering only set articles
                                        return (
                                        <CCol xs={12} md={3} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                            <ProductCardComponent
                                                edit={true}
                                                selectedProducts={selectedProducts}
                                                item={item}
                                                changeProductPrice={changeProductPrice}
                                                priceInputId={priceInputId}
                                                setSellingPriceState={setSellingPriceState}
                                                priceInputValue={priceInputValue}
                                                setPriceInputValue={setPriceInputValue}
                                                cardOnClick={() =>  {updateSelectedProducts(item.id); setShowRefreshButton(true);}}
                                                selectedTab={selectedTab}
                                                setPriceInputId={setPriceInputId}
                                            />                                            
                                        </CCol>
                                        )
                                    })
                                :  selectedTab === 1 ?
                                setArticles.length === 0 ?
                                    <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                        <h5>No set-article within the applied filter</h5>
                                    </CCol>
                                :
                                setArticles?.filter((product: any) => {
                                    if (product?.final_points >= minValue && product.final_points <= maxValue) {
                                        const brandMatch = currentBrandList.length === 0 || currentBrandList.includes(product.brand);
                                        const subCatMatch = currentSubGroupList.length === 0 || currentSubGroupList.includes(product.sub_category);
                                        const articleMatch = currentArticleList.length === 0 || currentArticleList.includes(product.article);
                                        const usageMatch = currentUsageList.length === 0 || currentUsageList.includes(product.usage.name);
                                    
                                        return brandMatch && articleMatch && usageMatch && subCatMatch;
                                    }
                                    else
                                    {
                                        return false
                                    }
                                  }).map((item: ProductItem) => {
                                    // rendering only set articles
                                    return (
                                    <CCol xs={12} md={3} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                        <ProductCardComponent
                                            edit={true}
                                            selectedProducts={selectedSetArticles}
                                            item={item}
                                            changeProductPrice={changeProductPrice}
                                            priceInputId={priceInputId}
                                            setSellingPriceState={setSellingPriceState}
                                            priceInputValue={priceInputValue}
                                            setPriceInputValue={setPriceInputValue}
                                            cardOnClick={() =>  {updateSelectedSetArticles(item.id); setShowRefreshButton(true);}}
                                            automaticPairing={automaticPairing}
                                            getPairedSetArticlesOptions={getPairedSetArticlesOptions}                                                                                            
                                            selectedTab={selectedTab}
                                            setPriceInputId={setPriceInputId}
                                        />
                                    </CCol>
                                    )
                                })
                                : selectedTab === 2 ?
                                    pallets?.length === 0 ?
                                        <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
                                            <h5>No Pallet within the applied filter</h5>
                                        </CCol>
                                    :
                                    pallets?.map((item: Pallet) => {
                                        // rendering only set articles
                                        return (
                                            <CCol xs={12} md={4} style={{marginTop: 24, marginBottom: 24}}> {/* product cards here. */}
                                                <PalletCardComponent
                                                    edit={true}
                                                    selectedPallets={selectedPallets}
                                                    item={item}
                                                    changeProductPrice={changeProductPrice}
                                                    priceInputId={priceInputId}
                                                    setSellingPriceState={setSellingPriceState}
                                                    priceInputValue={priceInputValue}
                                                    setPriceInputValue={setPriceInputValue}
                                                    cardOnClick={() =>  {updateSelectedPallets(item.id); setShowRefreshButton(true);}}
                                                    selectedTab={selectedTab}
                                                    setPriceInputId={setPriceInputId}
                                                />
                                            </CCol>
                                        )

                                    })
                                : selectedTab === 3 ?
                                    <OrderSummary
                                        updateSelectedProducts={updateSelectedProducts}
                                        updateSelectedSetArticles={updateSelectedSetArticles}
                                        updateSelectedPallets={updateSelectedPallets}
                                    /> 
                                :
                                <div></div>
                            }
                        </CRow>
                    </CCol>
                </CRow>
            </div>

        </div>
    );
};
export default EditPackageScreen;