import { CRow, CCol, CCard, CCardBody, CCardText, CButton, CFormLabel, CFormInput, CFormCheck, CBadge } from "@coreui/react";
import { DropDownItem, LoggedinUser, Pallet, Package } from "../../../types";
import { CUSTOMER_GROUP_ID, SUPPLIER_GROUP_ID, WAREHOUSE_GROUP_ID, formattedPrice } from "../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../redux/store";
import { useState } from "react";
import { editPalletDetails } from "../../../services/PalletService";
import { removeFromCart, addToCart, setShowRefreshButton } from "../../../redux/slices/CustomerCartSlice";
import { useTranslation } from "react-i18next";

interface PalletDetailsCardComponentProps {
    pallet: Pallet;
    palletEditable: boolean;
    setPalletObject: (palletObject: Pallet) => void;
    showButton: boolean;
}
const PalletDetailsCardComponent = (props: PalletDetailsCardComponentProps) => {
    const { pallet, setPalletObject, palletEditable, showButton } = props;
    const {t} = useTranslation();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const [editPallet, setEditPallet] = useState<boolean>(false);

    const [description, setDescription] = useState<string>(pallet.description);
    const [isActive, setIsActive] = useState<boolean>(!(pallet.is_deleted));
    const [remarks, setRemarks] = useState<string>(pallet.metadata?.remarks);

    const packageItemString = localStorage.getItem('packageItem');
    const packageItem: Package = packageItemString ? JSON.parse(packageItemString) : null;
    const productCartSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.productCart;
    const productCart = useSelector(productCartSelector(packageItem?.id)) || [];
    const dispatch = useDispatch();

    // Update Cart and Price accordingly
    const updateCartWithPrice = (packageId: number, id: number, selling_price: number, combined_products?: number[]) => {
        const indexInCart = productCart.indexOf(id);
        if (indexInCart !== -1) {
            dispatch(removeFromCart({ packageId: packageId, id: id, selling_price: selling_price, combined_products: combined_products }));
        } else {
            dispatch(addToCart({ packageId: packageId, id: id, selling_price: selling_price, combined_products: combined_products }));
        }
        
        dispatch(setShowRefreshButton({packageId: packageId, showRefreshButton: true}));
    }

    const updateProductData = () => {
        if(editPallet) {
            setEditPallet(!editPallet)
            const formData = {
                description: description,
                is_deleted: !isActive,
                metadata: {
                    ...pallet.metadata,
                    remarks: remarks,
                }
            }
            editPalletDetails(pallet.id, formData).then((response) => {
                setPalletObject(response.data);
            }).catch((e) => {
            console.log('error: ', e);
            })
        }
        else {
            setEditPallet(!editPallet)
        }
    }

    // Check user group membership
    const isWarehouse = authUser?.groups?.includes(WAREHOUSE_GROUP_ID);
    const isSupplier = authUser?.groups?.includes(SUPPLIER_GROUP_ID);
    const isCustomer = authUser?.groups?.includes(CUSTOMER_GROUP_ID);

    // Determine price lable based on user group
    const priceLabel = isSupplier ? t('adminDashboard.Products.buying_price') : t('adminDashboard.Products.selling_price');
    const priceValue = isSupplier ? pallet?.buying_price : pallet?.selling_price;

    return (
        <CRow>
            <CCol className="col-sm-4">
                <div>
                    <img src={'https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=340439039052693323&filename=0.jpg'} className={"aspect-ratio-l-style"} alt="logo"/>
                </div>
            </CCol>
            <CCol className="col-sm-8">
                <CCard>
                    <CCardBody>
                    <CRow>
                        <CCol xs={12}>
                            {
                                !authUser?.groups?.includes(CUSTOMER_GROUP_ID) && !authUser?.groups?.includes(SUPPLIER_GROUP_ID) && !authUser?.groups?.includes(WAREHOUSE_GROUP_ID) && (
                                    <>
                                        <div>
                                            <CBadge className="margin-top-12" color={pallet?.is_sold ? "danger" : "success"} style={{fontSize: 16}}>
                                                {
                                                    pallet?.is_sold ? 
                                                    pallet?.order_id ?
                                                    "SOLD" : "OFFERED" : "Available"
                                                }
                                            </CBadge>
                                            {
                                                pallet?.is_sold && (
                                                    <>
                                                        <CBadge color="info" style={{marginLeft: '1%'}}>
                                                            <b>{'PackageID: ' + pallet?.package_id}</b>
                                                        </CBadge>
                                                        <CBadge color="dark" style={{marginLeft: '1%'}}>
                                                            <b>{pallet?.customer_name}</b>
                                                        </CBadge>
                                                    </>
                                                )
                                            }
                                            
                                        </div>
                                    </>
                                )
                            }
                        </CCol>
                    </CRow>
                        {
                            palletEditable && pallet?.is_sold == false ?
                                <CRow>
                                    <CCol xs={12}>
                                        <CButton
                                            className="elv-btn margin-top-12"
                                            size="sm"
                                            onClick={updateProductData}
                                        >
                                            {editPallet? "Done" : "Edit"}
                                        </CButton>
                                    </CCol>
                                </CRow>              
                            : null 
                        }
                        {
                            editPallet ?
                            <CRow>
                                <CCol xs={6} className='padding-top-16'>
                                    <CFormLabel><b>{t('adminDashboard.Products.description')}</b></CFormLabel>
                                    <CFormInput
                                        type="text"
                                        value={description}
                                        onChange={(e: any) => {
                                            setDescription(e.target.value);
                                        }}
                                    />
                                </CCol>
                                <CCol xs={6} className='padding-top-16'>
                                    <CFormLabel><b>Remarks</b></CFormLabel>
                                    <CFormInput
                                        type="text"
                                        value={remarks}
                                        onChange={(e: any) => {
                                            setRemarks(e.target.value);
                                        }}
                                    />
                                </CCol>
                                <CCol xs={12} className='padding-top-16'>
                                    <CFormCheck 
                                        type="checkbox"
                                        checked={isActive}
                                        label={'Is Active'}
                                        onClick={() => setIsActive(!isActive)}
                                    />
                                </CCol>
                            </CRow>
                            :
                            <CRow>
                                <CCol className="col-sm-6">
                                    <ul className="list-group list-group-flush margin-top-12">
                                        <li className="list-group-item"><CCardText><div><h6>{t('adminDashboard.Products.description')}: {pallet?.description}</h6></div></ CCardText></li>
                                        <li className="list-group-item margin-top-12"><CCardText><div><h6>ID: {pallet?.id.toString()}</h6></div></ CCardText></li>
                                        {(!isWarehouse &&
                                            <li className="list-group-item margin-top-12">
                                                <CCardText>
                                                    <div>
                                                        <h6>{priceLabel}: {formattedPrice(Number(priceValue))}</h6>
                                                    </div>
                                                </CCardText>
                                            </li>
                                        )}
                                        <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.remarks')}: {pallet?.metadata?.remarks}</h6></div></ CCardText></li>
                                        <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Pallets.products_inside')}: {pallet?.products_inside}</h6></div></ CCardText></li>
                                    </ul>
                                </CCol>
                                <CCol className="col-sm-6">
                                    <ul className="list-group list-group-flush margin-top-12">
                                        {
                                            pallet?.is_sold ?
                                                <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.online_price')}: { pallet?.selling_online_price && Number(pallet.selling_online_price)?.toFixed(2)}</h6></div></ CCardText></li>
                                        
                                            :
                                                <li className="list-group-item margin-top-12"><CCardText><div><h6>{t('adminDashboard.Products.online_price')}: { pallet?.current_online_price && Number(pallet.current_online_price)?.toFixed(2)}</h6></div></ CCardText></li>
                                        }
                                        {(!isWarehouse && !isCustomer &&
                                            <li className="list-group-item margin-top-12">
                                                <CCardText>
                                                    <div>
                                                        <h6>{t('adminDashboard.Products.buying_price')}: {formattedPrice(Number(pallet?.buying_price))}</h6>
                                                    </div>
                                                </CCardText>
                                            </li>
                                        )}
                                    </ul>
                                </CCol>
                            </CRow>
                        }
                    </CCardBody>
                </CCard>
                {
                    authUser?.groups?.includes(CUSTOMER_GROUP_ID) && showButton ?
                    <CCol className="py-3">
                        {
                            packageItem && packageItem.status == 'A' ?
                                <div>
                                    <CButton
                                        className='elv-btn'
                                        variant="outline"
                                        shape="square"
                                        onClick={() => {
                                            if (packageItem && pallet && pallet.id !== undefined && pallet.selling_price !== undefined) {
                                                updateCartWithPrice(packageItem?.id, pallet?.id, pallet?.selling_price);
                                            }
                                        }}
                                    >
                                        {
                                            pallet?.id !== undefined && productCart.includes(pallet?.id) ? t('adminDashboard.Products.remove_from_cart') : t('adminDashboard.Products.add_to_cart')
                                        }
                                    </CButton>
                                </div>
                            :
                            null
                        }
                    </CCol>
                    :
                    null
                }
            </CCol>
        </CRow>
        
    )
};

export default PalletDetailsCardComponent;
