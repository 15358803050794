import { useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CButton, CFormInput, CForm, CHeader, CFormLabel, CSpinner, CToaster, CCol, CRow, CFormCheck, CTooltip, CInputGroup } from '@coreui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { updateCustomer } from '../../../services/CustomerService';
import { registerUser } from '../../../services/AuthService';
import Select, {components} from 'react-select';
import {UserCreatedSuccessToast, UserEditedSuccessToast, GenericErrorToast} from '../../components/create-package-components/ToastsComponent';
import { Utils } from '../../components/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Country, DropDownItem } from '../../../types';
import { useTranslation } from 'react-i18next';
import { CrmUtils } from '../../components/utils/CrmUtils';
import CIcon from '@coreui/icons-react';
import { cilInfo } from '@coreui/icons';
import { ELV_BLUE, LANGUAGES } from '../../../constants';
import { GR, RO, RU, DE, US } from 'country-flag-icons/react/3x2';

const AddUserScreen = () => {
    const state = useLocation();
    const {t} = useTranslation();
    const navigation = useNavigate();
    const {getCustomersData} = Utils();
    const {registerCRMUser} = CrmUtils();
    const [toast, addToast] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const countriesList: Country[] = useSelector((state: RootState) => state.usersSlice.countries);
    const countriesOptions: DropDownItem[] = countriesList.map((country: Country) => ({ value: country.id, label: country.name, is_eu: country.is_eu }));
    const { Option } = components;
    const IconOption = (props: any) => (
        <Option {...props}>
            {props.data.component} {props.data.label}         
        </Option>
    );
    //component: <US title="US" className='flag-style'/> 
    const languageOptions: DropDownItem[] = [
        { value: 'en', label: ` ${LANGUAGES['en']}`, component: <US title="US" className='flag-style'/>},
        { value: 'de', label: ` ${LANGUAGES['de']}`, component: <DE title="DE" className='flag-style'/>},
        { value: 'ru', label: ` ${LANGUAGES['ru']}`, component: <RU title="RU" className='flag-style'/>},
        { value: 'ro', label: ` ${LANGUAGES['ro']}`, component: <RO title="RO" className='flag-style'/>},
        { value: 'gr', label: ` ${LANGUAGES['gr']}`, component: <GR title="GR" className='flag-style'/>},
    ];

    const [crmError, setCrmError] = useState<string>("");
    console.log('Language: ', state?.state?.customerObject?.user?.preferred_language);
    const [email, setEmail] = useState<string>(state?.state?.customerObject?.email);
    const [password, setPassword] = useState<string>(state?.state?.customerObject?.user?.password);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>(state?.state?.customerObject?.first_name);
    const [lastName, setLastName] = useState<string>(state?.state?.customerObject?.last_name);
    const [phoneNumber, setPhoneNumber] = useState<any>(state?.state?.customerObject?.phone_number || '');
    const [company, setComapany] = useState<string>(state?.state?.customerObject?.company);
    const [language, setLanguage] = useState<DropDownItem>  ( state?.state?.customerObject?.user?.preferred_language ? {
       value: `${state?.state?.customerObject?.user?.preferred_language}`, 
       label: `${LANGUAGES[state?.state?.customerObject?.user?.preferred_language]}`,
      
    } : languageOptions[0]);

    const [jtlID, setjtlID] = useState<number>(state?.state?.customerObject?.jtl_id);
    const [vat_id, setVatId] = useState<string>(state?.state?.customerObject?.vat);
    const [selectionPercent, setSelectionPercent] = useState<number>(state?.state?.customerObject?.selection_percent || 0.8);
    const [address, setAddress] = useState<string>(state?.state?.customerObject?.shipping_address?.address);
    const [country, setCountry] = useState<DropDownItem>(countriesOptions?.length > 0 ? countriesOptions?.filter((country) => { 
        return country.label == state?.state?.customerObject?.shipping_address?.country
    })[0] : countriesOptions[0]);
    const [zipcode, setZipcode] = useState<string>(state?.state?.customerObject?.shipping_address?.zipcode);
    const [city, setCity] = useState<string>(state?.state?.customerObject?.shipping_address?.city);
    const [isActive, setIsActive] = useState<boolean>(state?.state?.customerObject?.is_active);

    const [errorMessage, setErrorMessage] = useState<string>("");
    const isDataValid = () : boolean => {
        if (state?.state?.editText) {
            return email?.length > 0 && email?.includes('@') && firstName?.length > 0 && vat_id?.length > 1 &&
            lastName?.length > 0 && company?.length > 0 && address?.length > 0 && country?.label?.length > 0 &&
            zipcode?.length > 0 && city?.length > 0 && jtlID > 0 && selectionPercent >= 0 && selectionPercent <= 1
        }
        else {
            return email?.length > 0 && email?.includes('@') && password?.length > 0 && firstName?.length > 0 && vat_id?.length > 1 &&
            lastName?.length > 0 && phoneNumber?.length > 0 && company?.length > 0 && address?.length > 0 && country && country?.label?.length > 0 &&
            zipcode?.length > 0 && city?.length > 0 && jtlID > 0 && selectionPercent >= 0 && selectionPercent <= 1
        }
    }
    const addCustomerData = () => {
        if(state?.state?.editText) {
            setIsLoading(true);
            updateCustomer(state.state.customerObject?.id, {
                user: {                   
                    first_name: firstName,
                    last_name: lastName,
                    phone_number: phoneNumber,
                    is_active: isActive,
                    preferred_language: language.value,
                },
                company,
                jtl_id: jtlID,
                vat: state?.state?.customerObject?.vat !== vat_id ? {vat: vat_id} : undefined,
                selection_percent: selectionPercent,
                shipping_address: state?.state?.customerObject?.shipping_address?.address !== address || 
                    state?.state?.customerObject?.shipping_address?.country !== country?.label ||
                    state?.state?.customerObject?.shipping_address?.zipcode !== zipcode || 
                    state?.state?.customerObject?.shipping_address?.city !== city ? { address, country: country?.label, zipcode, city } : undefined,
                billing_address: { address, country: country?.label, zipcode, city },
            }).then((response) => {
                setIsLoading(false);
                getCustomersData();
                setErrorMessage("");
                addToast(UserEditedSuccessToast(()=>{
                    addToast(null);
                }));
                // navigate here to main customer screen.
                setTimeout(()=> {
                    navigation('/admin/dashboard/customers');
                }, 1200);
                
            }).catch((e) => {   
                setIsLoading(false);
                if(e?.response?.data?.vat) {
                    setErrorMessage(e?.response?.data?.vat?.vat);                   
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `VAT Error !! ${e?.response?.data?.vat?.vat}`));
                }
                if(e?.response?.data?.selection_percent) {
                    setErrorMessage(e?.response?.data?.selection_percent);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `Selection percent !! ${e?.response?.data?.selection_percent}`));
                }
                if(e?.response?.data?.user?.phone_number) { 
                    setErrorMessage(e?.response?.data?.user?.phone_number);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `Phone Error !! ${e?.response?.data?.user?.phone_number}`));
                }
                if(e?.response?.data?.non_field_errors) {
                    setErrorMessage(e?.response?.data?.non_field_errors);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `Error !! ${e?.response?.data?.non_field_errors}`));
                }
                if(e?.response?.data?.email) {
                    setErrorMessage(e?.response?.data?.email);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `Error !! ${e?.response?.data?.email}`));
                }
            });
        }
        else {
            setIsLoading(true);
            registerUser({
                email,
                password1: password,
                password2: password,
                jtl_id: jtlID,
                first_name: firstName,
                last_name: lastName,
                phone_number: phoneNumber,
                company,
                reference: 'Elvinci Personal',
                shipping_address: { address, country: country?.label, zipcode, city },
                vat: {vat: vat_id},
                selection_percent: selectionPercent,
                use_same_address: true,
                preferred_language: language.value,
            }).then((response) => {
                setIsLoading(false);
                getCustomersData();
                addToast(UserCreatedSuccessToast(()=>{
                    addToast(null);
                }));
                setErrorMessage('');
                registerCRMUser(
                    {email, jtlID, firstName, lastName, phoneNumber, company, address, country, zipcode, city, vat_id}, 
                    (value: string) => setCrmError(value));

            }).catch((e) => {
                setIsLoading(false);
                if(e?.response?.data?.password1) {
                    setErrorMessage(e?.response?.data?.password1);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `${e?.response?.data?.password1}`));
                }
                if(e?.response?.data?.selection_percent) { 
                    setErrorMessage(e?.response?.data?.selection_percent);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `Selection percent !! ${e?.response?.data?.selection_percent}`));
                }
                if(e?.response?.data?.vat) {
                    setErrorMessage(e?.response?.data?.vat?.vat);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `VAT Error !! ${e?.response?.data?.vat?.vat}`));
                }
                if(e?.response?.data?.user?.phone_number) { 
                    setErrorMessage(e?.response?.data?.user?.phone_number);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `Phone Error !! ${e?.response?.data?.user?.phone_number}`));
                }
                if(e?.response?.data?.non_field_errors) { 
                    setErrorMessage(e?.response?.data?.non_field_errors);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `Error !! ${e?.response?.data?.non_field_errors}`));
                } 
                if(e?.response?.data?.email) {
                    setErrorMessage(e?.response?.data?.email);
                    addToast(GenericErrorToast(()=>{
                        addToast(null);
                    }, `Error !! ${e?.response?.data?.email}`));
                }
            });
        }
    }
    return (
        <div className='website-settings-main-container'>
            <CToaster push={toast} placement="top-end" />
            <CHeader><h2><b>{state?.state?.editText? state.state.editText :  "Add New User"}</b></h2></CHeader>
            <CForm style={{marginBottom: 16, marginTop: 24}}>
            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Email*</b></CFormLabel>
                    <CFormInput
                        type={"email"}
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                        disabled={state?.state?.editText ? true : false}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    {
                        !state?.state?.editText? <CFormLabel><b>Password*</b></CFormLabel> : null
                    }
                    {
                        !state?.state?.editText? 
                        <CInputGroup className='form-input-group'>
                            <CFormInput
                                type={showPassword ? "text" : "password"}
                                placeholder='Enter User Password'
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                style={{borderWidth: 0}}
                            />
                            {
                                showPassword ?
                                    <img className='margin-left-12 show-hide-password-icon' src={require('../../../assets/images/eye-slash-icon.png')} onClick={()=>setShowPassword(false)}/>
                                :
                                    <img className='margin-left-12 show-hide-password-icon' src={require('../../../assets/images/eye-icon.png')} onClick={()=>setShowPassword(true)}/>
                            }
                        </CInputGroup>
                        : null
                    } 
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>JTL ID*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter User JTL ID'
                        type={'number'}
                        value={jtlID}
                        onChange={(e) => {
                            setjtlID(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                        required
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>First Name*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter First Name'
                        value={firstName}
                        onChange={(e) => {
                            setFirstName(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Last Name*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Last Name'
                        value={lastName}
                        onChange={(e) => {
                            setLastName(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    /> 
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Company*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Company'
                        value={company}
                        onChange={(e) => {
                            setComapany(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />  
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4}className='padding-top-16'>
                    <CFormLabel><b>VAT ID*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter VAT ID'
                        value={vat_id}
                        onChange={(e) => {
                            setVatId(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />  
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Phone Number*</b></CFormLabel>
                    {/* <CFormInput
                        placeholder='Enter Phone Number'
                        value={phoneNumber}
                        onChange={(e) => {
                            setPhoneNumber(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    /> */}
                     <PhoneInput
                        international
                        defaultCountry="DE"
                        value={phoneNumber}
                        onChange={setPhoneNumber} />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Selection Percent* (0 - 1)
                        <CTooltip content="Minimum percentage this customer is required to select from offered packages">
                            <CIcon 
                                icon={cilInfo}
                                style={{borderRadius: 3, color: ELV_BLUE}}
                                className='margin-left-16'
                            />
                        </CTooltip>
                        </b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Selection Percent'
                        type={'number'}
                        defaultValue={0}
                        min={0}
                        max={1}
                        step={0.1}
                        value={selectionPercent}
                        onChange={(e) => {
                            console.log(e.target.value)
                            let val: any = e.target.value;
                            if (val == '') {
                                // val = 0;  // Default to 0 when backspacing to empty
                                setSelectionPercent(0);
                            }
                            else {
                                val = parseFloat(val).toString();
                                if(val <= 1 && val >= 0 && /^\d*(\.\d{0,3})?$/.test(val)) {
                                    setSelectionPercent(Number(val));
                                }
                            }
                            // Check if the value is within range and correct format
                           
                        }}
                       
                        style={{marginBottom: 16}}
                        required
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Country*</b></CFormLabel>
                    <Select
                        value={country}
                        placeholder={`${t('adminDashboard.CommonWords.select')}`}
                        noOptionsMessage={({ inputValue: string }) => 'Country not found.' }
                        options={countriesOptions}
                        onChange={(option: any) => {
                            console.log(option)
                            setCountry(option)
                        }}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Zip code*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Zip code'
                        value={zipcode}
                        onChange={(e) => {
                            setZipcode(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>City*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter City'
                        value={city}
                        onChange={(e) => {
                            setCity(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    /> 
                </CCol>
            </CRow>

            
            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Address*</b></CFormLabel>
                    <CFormInput
                        placeholder='Enter Address'
                        value={address}
                        onChange={(e) => {
                            setAddress(e.target.value);
                        }}
                        style={{marginBottom: 16}}
                    /> 
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                    <CFormLabel><b>Preferred Language*</b></CFormLabel>
                    <Select
                        value={language}
                        placeholder={`${t('adminDashboard.CommonWords.select')}`}
                        noOptionsMessage={({ inputValue: string }) => 'Country not found.' }
                        options={languageOptions}
                        components={{ Option: IconOption }}
                        onChange={(option: any) => {
                            setLanguage(option)
                        }}
                    />
                </CCol>
            </CRow>
            {
                state?.state?.editText? 
                <CRow>
                    <CCol xs={12} md={6} lg={4} className='padding-top-16'>
                        <CFormCheck 
                            type="checkbox"
                            checked={isActive}
                            label={'Is Active'}
                            onClick={() => setIsActive(!isActive)}
                        />
                    </CCol>
                </CRow>
                : null
            }
            
            
            <CRow>
                <CCol xs={7} className='padding-top-16'>
                    <CButton
                        disabled={!isDataValid()}
                        onClick={addCustomerData}>
                        {isLoading? <CSpinner /> : state?.state?.buttonText? state.state.buttonText : "Add"}
                    </CButton>
                </CCol>
            </CRow>
            {
                errorMessage && errorMessage.length > 0 ?
                    <p style={{color: 'red', marginTop: 8}}>Error! {errorMessage}</p>
                :    null
            }
             {
                crmError && crmError.length > 0 ?
                    <p style={{color: 'red', marginTop: 8}}>CRM Error! {crmError}</p>
                :    null
            }
            </CForm>
        </div>
    )
};
export default AddUserScreen;

