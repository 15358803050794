import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CAvatar, CTable, CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow, CModalContent } from "@coreui/react"
import { ProductItem, PossibleArticlePairs, PairedCartItem, Cart } from "../../../types";
import "../AdminComponentStyles.css";

interface ModalProps {
    cart: Cart;
    isModalVisible: boolean;
    closeModal: () => void;
    pairOptions?: PossibleArticlePairs | null;
   
    pairSetArticle: (selectedItem: ProductItem , item: ProductItem) => void;
    alreadyPairedToast: any;
    addToast: any;
}
const Modal = (props: ModalProps) => {
    const {isModalVisible, closeModal, pairOptions, pairSetArticle, cart, addToast, alreadyPairedToast} = props;
    const handleOpenNewTab = (product: ProductItem, url: string) => {
        // Open the link in a new tab using window.open
        const newTab: any = window.open(url, '_blank');
        const serializedProduct = JSON.stringify(product);
        newTab.localStorage.setItem('product', serializedProduct);
    };
    return (
        <CModal size="xl" visible={isModalVisible} onClose={closeModal} className="zero-margin">
            <CModalHeader className="modal-header">
                <CModalTitle>Select article pair</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <CTable align="middle" className="mb-0" hover responsive>
                    <CTableHead color="light">
                        <CTableRow>
                            <CTableHeaderCell className="text-center">Lager Id</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Article Id</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Kochfelder Id</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Supply</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Grade</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Price</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                    {pairOptions?.availableOptions?.map((option: ProductItem, index: number) => {
                        // const IsAlreadyPaired = selectedSetArticles.includes(option.id);
                        const IsOptionAlreadyPaired = cart.paired.some((cartItem: PairedCartItem) => (cartItem.set_articles.includes(option.id) && cartItem.set_articles.length === 2));
                        const IsSelectedItemAlreadyPaired = cart.paired.some((cartItem: PairedCartItem) => (cartItem.set_articles.includes(pairOptions.selectedItem.id) && cartItem.set_articles.length === 2));
                        
                        return (   
                            <CTableRow v-for="item in tableItems" key={index}>
                                <CTableDataCell className="text-center">
                                    <div>{option.id}</div>
                                </CTableDataCell>

                                <CTableDataCell className="text-center">
                                    <div>{option.article}</div>
                                </CTableDataCell>

                                <CTableDataCell className="text-center">
                                    <div>{option.metadata ? option.metadata.cooktop_id : '-'}</div>
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                    <div>{option.supply}</div>
                                </CTableDataCell>
                            
                                <CTableDataCell className="text-center">
                                    <div className="clearfix">
                                        {Math.round(option.final_points)}
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                    <div className="clearfix">
                                        {Math.round(option.selling_price)}
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                    <CButton
                                        shape="square"
                                        size="sm"
                                        disabled={IsOptionAlreadyPaired}
                                        onClick={() => {
                                            if(!IsSelectedItemAlreadyPaired){
                                                pairSetArticle(pairOptions.selectedItem , option);
                                            }
                                            else {
                                                addToast(alreadyPairedToast(()=>{addToast(null)}));
                                            }                                     
                                        }}
                                    >
                                        {IsOptionAlreadyPaired ? "paired" : "pair"}
                                    </CButton>
                                    <CButton
                                        shape="square"
                                        size="sm"
                                        disabled={IsOptionAlreadyPaired}
                                        style={{marginLeft: 8}}
                                        onClick={() => {
                                            handleOpenNewTab(option, '/admin/dashboard/product/details')
                                        }}
                                    >
                                        details
                                    </CButton>
                                </CTableDataCell>                    
                            </CTableRow>
                    )})}
                    </CTableBody>
                </CTable>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    )
}
export default Modal;