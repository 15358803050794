import { useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CButton, CFormInput, CForm, CHeader, CFormLabel, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormFeedback, CToast, CToastBody, CToastHeader, CToaster} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Supplier, SupplyTypePost } from '../../../types';
import { addSupplyTypes, getSupplyTypes } from '../../../services/ProductService';
import { setSupplyTypes } from '../../../redux/slices/ProductSlice';
import { t } from 'i18next';

interface SupplyTypeProps {
    showCreatedToast?: () => void;
}

const AddNewSupplyType = (props: SupplyTypeProps) => {
    const {showCreatedToast} = props;
    const dispatch = useDispatch();    
    const suppliers: Supplier[] = useSelector((state: RootState) => state.productSlice.suppliers);
    const [supplyTypeName, setSupplyTypeName] = useState<string>('');
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier>(suppliers[0] && suppliers[0]);
    const [buyingPercentage, setBuyingPercentage] = useState<number>(27);
    const [AGradePercentage, setAGradePercentage] = useState<number>(47);
    const [BGradePercentage, setBGradePercentage] = useState<number>(37);
    const [CGradePercentage, setCGradePercentage] = useState<number>(25);
    const [nameValid, setNameValid] = useState<boolean>(true);
    const [errorText, setErrorText] = useState<string>('');

    const getAllSupplyTypes = () => {
        getSupplyTypes().then((response) => {      
            dispatch(setSupplyTypes(response.data));
          }).catch((e) => {
            dispatch(setSupplyTypes([]));
            console.log('get supply types error: ', e);
        });
    }
    const addNewSupplyType = () => {
        // call backend to add supply type here.
        let formData : SupplyTypePost = {
            name: supplyTypeName,
            grade_A_percent: Number((AGradePercentage / 100).toFixed(2)),
            grade_B_percent: Number((BGradePercentage / 100).toFixed(2)),
            grade_C_percent: Number((CGradePercentage / 100).toFixed(2)),
            buying_percentage: Number((buyingPercentage / 100).toFixed(2)),
            supplier: selectedSupplier.name,
        };

        addSupplyTypes(formData).then((response) => {
            getAllSupplyTypes();
            setNameValid(true);
            showCreatedToast && showCreatedToast();          
        }).catch((e) => {
            if(e?.response?.data?.name) {
                setErrorText(e?.response?.data?.name);
                setNameValid(false);
            }
        });
    }
    return(
        <div>
            <CHeader><h2><b>{t('adminDashboard.SupplyManagement.create_new_supply_type')}</b></h2></CHeader>
            <CForm style={{marginBottom: 16, marginTop: 24}}>
                <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply_type')}</b></CFormLabel>
                <CFormInput
                    type={"string"}
                    placeholder='OttoMix, OttoBeware, etc. '
                    value={supplyTypeName}
                    onChange={(e) => {
                        setSupplyTypeName(e.target.value);
                    }}
                    style={{marginBottom: 16}}
                />
                <CFormFeedback invalid={nameValid} style={{color: 'red'}}>{errorText}</CFormFeedback>

                <CFormLabel><b>{t('adminDashboard.CommonWords.buying_percentage')} (0 - 100)</b></CFormLabel>
                <CFormInput
                    type={'number'}
                    max={100}
                    placeholder='Buying percentage here'
                    value={buyingPercentage}
                    onChange={(e) => {
                        setBuyingPercentage(Number(e.target.value));
                    }}
                    style={{marginBottom: 16}}
                />

                <CFormLabel><b>{t('adminDashboard.SupplyManagement.a_grade_percentage')} (0 - 100)</b></CFormLabel>
                <CFormInput
                    type={'number'}
                    max={100}
                    placeholder='A'
                    value={AGradePercentage}
                    onChange={(e) => {
                        setAGradePercentage(Number(e.target.value));
                    }}
                    style={{marginBottom: 16}}
                />

                <CFormLabel><b>{t('adminDashboard.SupplyManagement.b_grade_percentage')} (0 - 100)</b></CFormLabel>
                <CFormInput
                    type={'number'}
                    max={100}
                    placeholder='B'
                    value={BGradePercentage}
                    onChange={(e) => {
                        setBGradePercentage(Number(e.target.value));
                    }}
                    style={{marginBottom: 16}}
                />

                <CFormLabel><b>{t('adminDashboard.SupplyManagement.c_grade_percentage')} (0 - 100)</b></CFormLabel>
                <CFormInput
                    type={'any'}
                    max={100}
                    placeholder='C'
                    value={CGradePercentage}
                    onChange={(e) => {
                        setCGradePercentage(Number(e.target.value));
                    }}
                    style={{marginBottom: 16}}
                />
                <CFormLabel><b>{t('adminDashboard.SupplyManagement.supplier')} </b></CFormLabel>
                <CDropdown style={{marginLeft: 16}}>
                    <CDropdownToggle className="elv-btn" href="#" >{selectedSupplier?.name}</CDropdownToggle>
                    <CDropdownMenu style={{position: 'absolute'}}>
                        {
                            suppliers.map((supplier: Supplier) => {
                                return (
                                    <CDropdownItem className="pointer" onClick={() =>  {setSelectedSupplier(supplier)}}>{supplier?.name } Id: ({supplier?.id})</CDropdownItem>
                                )
                            })
                        }
                    </CDropdownMenu>
                </CDropdown>
            </CForm>
            <CButton onClick={addNewSupplyType} disabled={supplyTypeName.length === 0 || !(AGradePercentage <= 100 && AGradePercentage >= 0) || !(BGradePercentage <= 100 && BGradePercentage >= 0) || !(CGradePercentage <= 100 && CGradePercentage >= 0) || !(buyingPercentage <= 100 && buyingPercentage >= 0) }>{t('adminDashboard.Users.add_supply_type')}</CButton>
        </div>
    )
};
export default AddNewSupplyType;
