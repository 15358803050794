import { useState, useEffect } from "react";
import "simplebar/dist/simplebar.min.css";
import { CSmartTable, CToaster } from "@coreui/react-pro";
import {
  applyProductFiltersWithoutPaginate,
  latestProduct, fullTextSearchProducts, api
} from "../../../services/ProductService";
import "../../AdminStyles.css";
import { ProductItem, filterRequestObject } from "../../../types";
import {
  CButton,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CBadge,
} from "@coreui/react";
import ProductHorizontalFilterComponent from "../../components/product-components/ProductHorizontalFIltersComponent";
import EmptyImage from "../../../assets/images/empty-image.jpg";
import { Utils } from "../../components/utils";

import PalletDetailsCardComponent from "../../components/pallet-card-component/PalletDetailsCardComponent";
import ProductDetailsCardComponent from "../../../customer/components/ProductDetailsCardComponent";
import { getPalletsBySupplyId, fullTextSearchPallets } from "../../../services/PalletService";
import { useTranslation } from "react-i18next";

const UpdatedProductsScreen = () => {
  const {
    downloadZipImages,
    checkPDFStatus,
    toast,
    ProductColumns,
    getLink,
    getAllProductSubCategories,
    getAllProductCategoryQuestions,
    getAllProductArticles,
    getAllProductBrands,
  } = Utils();

  const { t } = useTranslation();
  const [tempProducts, setTempProducts] = useState<any>([]);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [subGroupList, setSubGroupList] = useState<number[]>([]);

  const [currentBrandList, setCurrentBrandList] = useState<string[]>([]);
  const [currentSubGroupList, setCurrentSubGroupList] = useState<string[]>([]);
  const [currentArticleList, setCurrentArticleList] = useState<string[]>([]);
  const [currentSupplyTypeList, setCurrentSupplyTypeList] = useState<string[]>(
    []
  );
  const [currentSupplyList, setCurrentSupplyList] = useState<string[]>([]);
  const [currentGradeMin, setCurrentGradeMin] = useState<number>(0);
  const [currentGradeMax, setCurrentGradeMax] = useState<number>(100);
  const [currentStatus, setCurrentStatus] = useState<string>("all");
  const [loading, setLoading] = useState<boolean>(false);
  const [noReultFound, setNoReultFound] = useState<boolean>(false);
  const [lastScannedText, setLastScannedText] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const getLatestProduct = async () => {
    latestProduct()
      .then((response) => {
        setTempProducts([response.data]);
        setLastScannedText(true);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getLatestProduct();
    getAllProductSubCategories();
    getAllProductBrands();
    getAllProductCategoryQuestions();
    getAllProductArticles();
  }, []);

  useEffect(() => {
    if (
      currentSupplyList?.length > 0 ||
      currentArticleList?.length > 0 ||
      subGroupList?.length > 0
    ) {
      applyFilters();
    } else if (
      currentSupplyList?.length == 0 &&
      currentArticleList?.length == 0 &&
      subGroupList?.length == 0
    ) {
      getLatestProduct();
    }
  }, [currentSupplyList, currentArticleList, subGroupList]);

  const applyFilters = () => {
    setLoading(true);
    setLastScannedText(false);
    const filterObject: filterRequestObject = {
      is_deleted: false,
      article: currentArticleList?.length > 0 ? currentArticleList : undefined,
      supply_id: currentSupplyList?.length > 0 ? currentSupplyList : undefined,
      sub_category: subGroupList?.length > 0 ? subGroupList : undefined,
    };
    fetchAndCombineProducts(filterObject);
  };

  // Generic function to fetch white and small goods by supply-IDs
  async function fetchAndCombineProducts(filterObject: filterRequestObject) {
    try {
      setLoading(true);
      // Fetch both products concurrently if there's no dependency between them
      const [whiteProductsResponse, smallProductsResponse] = await Promise.all([
        applyProductFiltersWithoutPaginate(filterObject),
        getPalletsBySupplyId(filterObject),
      ]);
      // Extract the data or default to empty arrays
      const whiteProducts = whiteProductsResponse?.data || [];
      const smallProducts = smallProductsResponse?.data || [];

      // Combine the products
      const mixProducts = [...whiteProducts, ...smallProducts];
  
      // Update the state
      setTempProducts(mixProducts);
    } catch (error) {
      // Handle any errors from either API call
      console.error("Error fetching products:", error);
      setTempProducts([]);
    } finally {
      // Ensure loading state is updated regardless of success or failure
      setLoading(false);
    }
  }

  const handleOpenNewTab = (product: any) => {
    // Open the link in a new tab using window.open
    if ("pallet_products" in product) {
      const newTab: any = window.open(
        "/admin/dashboard/pallet/details",
        "_blank"
      );
      const serializedPallet = JSON.stringify(product);
      newTab.localStorage.setItem("pallet", serializedPallet);
    } else {
      const newTab: any = window.open(
        "/admin/dashboard/product/details",
        "_blank"
      );
      const serializedProduct = JSON.stringify(product);
      newTab.localStorage.setItem("product", serializedProduct);
    }
  };

  function convertDateTime(timestamp: string) {
    const date = new Date(timestamp);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const filterProducts = function (product: any) {
    const brandMatch =
      currentBrandList.length === 0 ||
      currentBrandList?.includes(product?.brand);
    const subCategoryMatch =
      currentSubGroupList.length === 0 ||
      currentSubGroupList?.includes(product?.sub_category);
    const statusMatch =
      currentStatus == "all"
        ? true
        : currentStatus == "sold"
        ? product.is_sold
        : product.is_sold == false;
    return brandMatch && subCategoryMatch && statusMatch;
  };
  const renderedProducts = tempProducts.filter((product: any) => {
    if (
      product?.final_points >= currentGradeMin &&
      product.final_points <= currentGradeMax
    ) {
      return filterProducts(product);
    } else if (product?.pallet_products) {
      return filterProducts(product);
    } else {
      return false;
    }
  });

  // const fullTextSearchProductsAndPallets = async (search_term: string) => {
  //   try {
  //     // Fetch products with the highest probability of matching the provided search term
  //     setLoading(true);
  //     const productsSearchResponse = await fullTextSearchProducts(search_term);
  //     console.log(`Show my results ${productsSearchResponse?.data?.results}`);
  //     setLastScannedText(false);
  //     setNoReultFound(false);
  //     const responseCount = productsSearchResponse?.data?.count;
  //     if(responseCount && responseCount > 0) {
  //       fetchFullTextSearchResults(productsSearchResponse, search_term);
  //     } else {
  //       const palletsSearchResponse = await fullTextSearchPallets(search_term);
  //       fetchFullTextSearchResults(palletsSearchResponse, search_term);
  //     }
  //   } catch (e) {
  //       setLoading(false);
  //       setNoReultFound(true);
  //     console.log('Error while fetching records in full text search: ', e);
  //   }
  // };

  // // Function to fetch full-text-search results and set to setTempProducts
  // const fetchFullTextSearchResults = async (responseObject: any, search_term: string): Promise<any> => {
  //   try {
  //     if (typeof responseObject !== 'object' || responseObject === null || responseObject === 'undefined') {
  //       throw new Error('Expected an object');
  //     } else {
  //       setLoading(true);
  //       let allResults = [...responseObject?.data?.results];
  //       let nextPageUrl = responseObject?.data?.next;
  //       setTempProducts([...responseObject?.data?.results]);
  //       setLoading(false);
  //       // Fetch additional pages of results using POST requests with search_term parameter and append output to previously fetched results
  //       while (nextPageUrl) {
  //         const nextPageResponse = await api.post(nextPageUrl, { search_keyword: search_term });
  //         nextPageUrl = nextPageResponse?.data?.next;
  //         // Append new results to the existing ones
  //         allResults = [...allResults, ...nextPageResponse?.data?.results];
  //         // Update tempProducts progressively with the new results
  //         setTempProducts(allResults);
  //       };
  //     };
  //   } catch (error) {
  //     //
  //   }
  // };

  return (
    <div className="website-settings-main-container">
      <CToaster push={toast} placement="top-end" />
      <h4>
        <b>{t("adminDashboard.CommonWords.product")}</b>
      </h4>
      <ProductHorizontalFilterComponent
        currentGradeMin={currentGradeMin}
        currentGradeMax={currentGradeMax}
        setCurrentGradeMin={setCurrentGradeMin}
        setCurrentGradeMax={setCurrentGradeMax}
        setArticleList={setCurrentArticleList}
        setSubGroupList={setSubGroupList}
        setCurrentSubGroupList={setCurrentSubGroupList}
        setBrandist={setCurrentBrandList}
        setSupplyList={setCurrentSupplyList}
        setCurrentStatus={setCurrentStatus}
        currentSubGroupList={currentSubGroupList}
        currentSupplyTypeList={currentSupplyTypeList}
        setTempProducts={setTempProducts}
        tempProducts={tempProducts}
        setCurrentSupplyTypeList={setCurrentSupplyTypeList}
        clearFilters={() => {
          setCurrentBrandList([]);
          setCurrentArticleList([]);
          setCurrentBrandList([]);
          setCurrentSubGroupList([]);
          setCurrentSupplyList([]);
          setCurrentSupplyTypeList([]);
          setCurrentStatus("");
          setSearchTerm("");
          setLoading(false);
        }}
        applyFilters={applyFilters}
        noReultFound={noReultFound}
        setNoReultFound={setNoReultFound}
        getLatestProduct={getLatestProduct}
        setLastScannedText={setLastScannedText}
        // setSearchTerm={setSearchTerm}
        setLoading={setLoading}
      />

      <div className="margin-top-36">
        {noReultFound ? (
          "No product found"
        ) : tempProducts.length == 1 && tempProducts[0]?.pallet_products ? (
          <div>
            <CButton
              size="sm"
              className="elv-btn"
              onClick={() => {
                handleOpenNewTab(tempProducts[0]);
              }}
            >
              Details
            </CButton>
            <PalletDetailsCardComponent
              pallet={tempProducts[0]}
              setPalletObject={(pallet) => {}}
              palletEditable={false}
              showButton={false}
            />
          </div>
        ) : tempProducts?.length == 1 && !tempProducts[0]?.pallet_products ? (
          <div>
            <h4>
              {lastScannedText
                ? t("adminDashboard.Products.latest_product_scanned")
                : null}
            </h4>
            <CButton
              size="sm"
              className="elv-btn margin-top-16"
              onClick={() => {
                handleOpenNewTab(tempProducts[0]);
              }}
            >
              Details
            </CButton>

            <ProductDetailsCardComponent
              productEditable={false}
              images={
               tempProducts[0]?.image_list?.length > 0
                  ? [
                      {
                        question_number: tempProducts[0]?.image_list[0],
                        url: `https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${tempProducts[0].id}&filename=${tempProducts[0]?.image_list[0]}.jpg`,
                      },
                    ]
                  : []
              }
              product={tempProducts[0]}
              showButton={false}
              setProduct={() => {}}
            />
          </div>
        ) : (
          <CSmartTable
            columns={ProductColumns}
            items={renderedProducts}
            itemsPerPage={itemsPerPage}
            loading={loading}
            itemsPerPageSelect
            itemsPerPageLabel={`${t(
              "adminDashboard.CommonWords.items_per_page"
            )}`}
            noItemsLabel={`${t("adminDashboard.CommonWords.no_items_found")}`}
            onActivePageChange={(activePage) => setActivePage(activePage)}
            onItemsPerPageChange={(itemsPerPage) => {
              setActivePage(1);
              setItemsPerPage(itemsPerPage);
            }}
            pagination={true}
            paginationProps={{
              activePage: activePage,
              pages: Math.ceil(renderedProducts?.length / itemsPerPage),
            }}
            tableHeadProps={{
              color: "info",
            }}
            tableProps={{
              hover: true,
              responsive: true,
              style: { marginTop: 8 },
              striped: true,
            }}
            tableBodyProps={{
              className: "align-middle",
            }}
            columnSorter={true}
            tableFilter
            scopedColumns={{
              image_list: (item: any) => {
                return (
                  <td className="center">
                    {item?.image_list && item?.image_list?.length > 0 ? (
                      <img
                        style={{
                          width: 80,
                          height: 125,
                          cursor: "pointer",
                          marginLeft: 12,
                        }}
                        src={`https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${item.id}&filename=${item?.image_list[0]}.jpg`}
                        onClick={() => handleOpenNewTab(item)}
                      />
                    ) : item?.pallet_products ? (
                      <img
                        style={{
                          width: 80,
                          height: 125,
                          cursor: "pointer",
                          marginLeft: 12,
                        }}
                        src={
                          "https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=340439039052693323&filename=0.jpg"
                        }
                        onClick={() => handleOpenNewTab(item)}
                      />
                    ) : (
                      <img
                        style={{ width: 80, height: 125 }}
                        src={EmptyImage}
                        onClick={() => handleOpenNewTab(item)}
                      />
                    )}
                  </td>
                );
              },
              id: (item: ProductItem) => {
                return <td className="center">{item?.id}</td>;
              },
              supply: (item: ProductItem) => {
                return <td className="center">{item?.supply}</td>;
              },
              article: (item: ProductItem) => {
                return (
                  <td
                    className="center url-decoration"
                    onClick={() => {
                      const link = item.link
                        ? item.link
                        : getLink(item.model || "");
                      if (link?.length > 0) {
                        window.open(
                          link.startsWith("http") ? link : `https://${link}`,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }
                    }}
                  >
                    {item?.article}
                  </td>
                );
              },
              sub_category: (item: ProductItem) => {
                return <td className="center">{item?.sub_category}</td>;
              },
              selling_price: (item: ProductItem) => {
                return <td className="center">{item?.selling_price}</td>;
              },
              current_online_price: (item: ProductItem) => {
                return (
                  <td className="center">
                    {item?.is_sold
                      ? item?.selling_online_price
                      : item?.current_online_price}
                  </td>
                );
              },
              brand: (item: ProductItem) => {
                return <td className="center">{item?.brand}</td>;
              },
              final_points: (item: ProductItem) => {
                return <td className="center">{item?.final_points}</td>;
              },
              created_at: (item: ProductItem) => {
                return (
                  <td className="center">{convertDateTime(item.created_at)}</td>
                );
              },
              is_sold: (item: ProductItem) => {
                return (
                  <td className="center">
                    <div>
                      <CBadge color={item?.is_sold ? "dark" : "success"}>
                        <b>
                          {item?.is_sold ? item?.customer_name : "Not Sold"}
                        </b>
                      </CBadge>
                      {item?.is_sold && (
                        <>
                          <div style={{ marginTop: "5px" }}>
                            <CBadge color="info">
                              <b>PackageID: {item?.package_id}</b>
                            </CBadge>
                          </div>
                          {item?.order_id && (
                            <div style={{ marginTop: "5px" }}>
                              <CBadge color="warning">
                                <b>OrderID: {item?.order_id}</b>
                              </CBadge>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </td>
                );
              },
              uploading_user_name: (item: ProductItem) => {
                return <td className="center">{item?.uploading_user_name}</td>;
              },
              action: (item: any) => {
                return (
                  <td className="center">
                    <CDropdown>
                      <CDropdownToggle
                        className="elv-btn"
                        href="#"
                        style={{ width: 110 }}
                      >
                        Action
                      </CDropdownToggle>
                      <CDropdownMenu
                        className='pointer'
                        style={{ position: "absolute", right: 20 }}
                      >
                        <CDropdownItem onClick={() => handleOpenNewTab(item)}>
                          View
                        </CDropdownItem>
                        <CDropdownItem
                          onClick={() => downloadZipImages([item.id])}
                        >
                          Images
                        </CDropdownItem>
                        {!item.pallet_products && (
                          <CDropdownItem
                            onClick={() =>
                              checkPDFStatus(item.id, "productPDF")
                            }
                          >
                            PDF
                          </CDropdownItem>
                        )}
                      </CDropdownMenu>
                    </CDropdown>
                  </td>
                );
              },
            }}
          />
        )}
      </div>
    </div>
  );
};
export default UpdatedProductsScreen;
