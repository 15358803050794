import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CTable, 
    CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow 
} from "@coreui/react";
import "../AdminComponentStyles.css";

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    closeModal: () => void;
    snapshots: any;
    revert: (snapshotId: number) => void;
}
const SnapshotModal = (props: ModalProps) => {
    const {isModalVisible, closeModal, headerText, bodyText, snapshots, revert} = props;
    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal} className="zero-margin">
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{bodyText}</div>
                <CTable align="middle" className="mb-0" hover responsive>
                    <CTableHead color="light">
                        <CTableRow>
                            <CTableHeaderCell className="text-center">Snapshot id</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Selected products</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Modified by</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Created at</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Products added / removed</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                    {snapshots?.map((snapshot: any, index: number) => {
                        return (   
                            <CTableRow v-for="item in tableItems" key={index}>
                                <CTableDataCell className="text-center">
                                    <div>{snapshot.id}</div>
                                </CTableDataCell >
                                <CTableDataCell className="text-center">
                                    <div>{snapshot?.products?.length}</div>
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                    {snapshot?.modified_by?.first_name}
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                    <div>{new Date(snapshot.modified_at).getDate()}/{new Date(snapshot.modified_at).getMonth() + 1}/{new Date(snapshot.modified_at).getFullYear()}</div>
                                </CTableDataCell>
                                <CTableDataCell className="text-center" >
                                    <div >
                                        {
                                            index !== 0 ?
                                                snapshot.products.length - snapshots[index - 1].products.length
                                                : '0'
                                        }
                                    </div>
                                </CTableDataCell>
                                <CTableDataCell className="text-center">
                                    <CButton
                                        shape="square"
                                        size="sm"
                                        onClick={() => revert(snapshot.id)}
                                    >
                                      confirm
                                    </CButton>
                                </CTableDataCell>
                            </CTableRow>
                    )})}
                    </CTableBody>
                </CTable>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default SnapshotModal;