import { useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { CButton, CFormInput, CForm, CHeader, CFormLabel, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormFeedback} from '@coreui/react';
import { useDispatch, useSelector } from 'react-redux';
import { SupplyType } from '../../../types';
import { RootState } from '../../../redux/store';
import { addProductSupplies } from '../../../services/ProductService';
import { Utils } from '../utils';
import { t } from "i18next";

interface SupplyProps {
    showCreatedToast?: () => void;
}
const AddNewSupply = (props: SupplyProps) => {
    const {showCreatedToast} = props;
    const dispatch = useDispatch();
    const {getAllProductSupplies} = Utils();
    const supplyTypes: SupplyType[] = useSelector((state: RootState) => state.productSlice.supplyTypes);
    const [supplyId, setSupplyId] = useState<string>('');
    const [selectedSupplyType, setSelectedSupplyType] = useState<SupplyType>(supplyTypes[0] && supplyTypes[0]);
    const [idValid, setIdValid] = useState<boolean>(true);
    const [errorText, setErrorText] = useState<string>('');

    const addNewSupply = () => {
        if(supplyId.length > 50) {
            setErrorText("Error! Supply ID is too long. (Max 50 characters are allowed)");
            setIdValid(false);
            return;
        }
        
        // Regular expression to check if any special characters other than underscore exist
        const specialCharacterRegex = /[^a-zA-Z0-9_]/;
        if (specialCharacterRegex.test(supplyId)) {
            setErrorText('Only alphanumeric characters and underscores are allowed.');
            setIdValid(false);
            return;
        }
        // call backend to add supply type here.
        let formData = {
            id: supplyId,
            is_fresh: true, // by default it would obviously be true.
            type: selectedSupplyType.id,
        };

        addProductSupplies(formData).then((response) => {
            // getAllProductSupplies();
            showCreatedToast && showCreatedToast();
            setIdValid(true);
        }).catch((e) => {
            if(e?.response?.data?.id) {
                setErrorText(e?.response?.data?.id);
                setIdValid(false);
            }
        });
    }

    return (
        <div>
                <CHeader><h2><b>{t('adminDashboard.SupplyManagement.create_new_supply')}</b></h2></CHeader>
                <CForm style={{marginBottom: 16, marginTop: 24}}>
                    <CFormLabel><b>{t('adminDashboard.SupplyManagement.new_supply_id')}</b></CFormLabel>
                    <CFormInput
                        placeholder='ABC123, etc'
                        value={supplyId}
                        onChange={(e) => {
                            setSupplyId(e.target.value);
                        }}
                        style={{marginBottom: 16}}                    
                    />  
                    <CFormFeedback invalid={idValid} style={{color: 'red'}}>{errorText}</CFormFeedback>                  
                                              
                    <CFormLabel><b>{t('adminDashboard.SupplyManagement.supply_type')}</b></CFormLabel>
                    <CDropdown style={{marginLeft: 16}}>
                        <CDropdownToggle className="elv-btn" href="#" >{selectedSupplyType?.name}</CDropdownToggle>
                        <CDropdownMenu style={{position: 'absolute'}}>
                            {
                                supplyTypes.map((supplyType: SupplyType) => {
                                    return (
                                        <CDropdownItem className="pointer" onClick={() =>  {setSelectedSupplyType(supplyType)}}>{supplyType.name } Id: ({supplyType.id})</CDropdownItem>
                                    )
                                })
                            }
                        </CDropdownMenu>
                    </CDropdown>
                </CForm>
                <CButton onClick={addNewSupply} disabled={supplyId.length === 0}>{t('adminDashboard.CommonWords.assign')}</CButton>
            </div>
    )
};
export default AddNewSupply;
