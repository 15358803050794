import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton,
    CForm, CFormInput, CFormLabel } 
    from "@coreui/react";
import { ExcelDataObject  } from "../../../types";
import { useState } from "react";
import "../AdminComponentStyles.css";
import { editManualProductPrices } from "../../../services/ProductService";

interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    selectedItem: ExcelDataObject | undefined;
    closeModal: () => void;
    onClickConfirmation: () => void;
    confirmButtonText: string;
}
const EditPriceModal = (props: ModalProps) => {   
    const {isModalVisible, closeModal, headerText, onClickConfirmation, bodyText, confirmButtonText, selectedItem} = props;
    const [id, setId] = useState<number>(selectedItem && selectedItem.id ? selectedItem.id : 0);
    const [productId, setProductId] = useState<number>(selectedItem ? selectedItem.Lager_Number : 0);
    const [sellingPrice, setSellingPrice] = useState<number>(selectedItem ? selectedItem.Selling_Price : 0);
    const [buyingPrice, setBuyingPrice] = useState<number>(selectedItem && selectedItem.Buying_Price ? selectedItem.Buying_Price : 0);
    const [buyingOnlinePrice, setBuyingOnlinePrice] = useState<number>(selectedItem && selectedItem.Buying_Online_Price ? selectedItem.Buying_Online_Price : 0);
  
    const editPrice = () => {
        let formData: ExcelDataObject = {          
            Selling_Price: sellingPrice,
            Lager_Number: productId,
            Buying_Price: buyingPrice,
            Buying_Online_Price: buyingOnlinePrice,            
        }
        editManualProductPrices(id, formData).then((response) => {
            onClickConfirmation();
        }).catch((e) => {           
        })
    };
    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal} className="zero-margin">
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{bodyText}</div>
                <CForm style={{marginBottom: 16, marginTop: 24}}>
                   
                    <CFormLabel><b>Selling price</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        placeholder='Selling price'
                        value={sellingPrice}
                        onChange={(e) => {
                            setSellingPrice(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                    />

                    <CFormLabel><b>Buying price</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        placeholder='Buying price'
                        value={buyingPrice}
                        onChange={(e) => {
                            setBuyingPrice(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                    />

                    <CFormLabel><b>Buying online price</b></CFormLabel>
                    <CFormInput
                        type={"number"}
                        placeholder='Buying online price'
                        value={buyingOnlinePrice}
                        onChange={(e) => {
                            setBuyingOnlinePrice(Number(e.target.value));
                        }}
                        style={{marginBottom: 16}}
                    />
                </CForm>
            </CModalBody>

            <CModalFooter>
                <CButton color="primary" onClick={editPrice}>{confirmButtonText}</CButton>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default EditPriceModal;