

import { useEffect, useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { 
    CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem,
    CButton, CFormInput } from '@coreui/react-pro';

import '../../AdminStyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { LoggedinUser, PermissionGroup } from '../../../types';
import {
    salesPersonDefaultPermissions, customerDefaultPermissions, backOfficeDefaultPermissions, adminDefaultPermissions, supplierDefaultPermissions,
    salesAssistantDefaultPermissions, ADMIN, warehouseManagerDefaultPermissions} from '../../../constants';
import { assignPermissions } from '../../../services/AuthService';
import { getAllGroups } from '../../../services/CustomerService';
import { setPermissionGroups } from '../../../redux/slices/UsersSlice';
import { t } from 'i18next';
import { Utils } from '../../components/utils';

const RightsManagementScreen = () => {
    const permissionGroups: PermissionGroup[] = useSelector((state: RootState) => state.usersSlice.permissionGroups);
    const permissions = useSelector((state: RootState) => state.usersSlice.permissions);
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const dispatch = useDispatch();
    const {getAllPermissionsData, getAllGroupsData} = Utils();
    const getAllPermissionGroups = () => {
        getAllGroups().then((response) => {
            dispatch(setPermissionGroups(response.data));
        }).catch((e) => {
            dispatch(setPermissionGroups([]));
        });
    }
    const assignDefaultPermissions = (item: PermissionGroup) => {
        if(item.name === 'Sales') {
            const formData = {
                name: item.name,
                permissions: salesPersonDefaultPermissions,
            }
            assignPermissions(item.id, formData).then((response) => {
                getAllPermissionGroups();
            }).catch((e) => {
                console.log('assign default permissions error: ', e)
            })
        }
        else if(item.name === 'Customer') {
            const formData = {
                name: item.name,
                permissions: customerDefaultPermissions,

            }
            assignPermissions(item.id, formData).then((response) => {
                getAllPermissionGroups();
            }).catch((e) => {
                console.log('assign default permissions error: ', e)
            })
        }
        else if(item.name === 'BackOffice') {
            const formData = {
                name: item.name,
                permissions: backOfficeDefaultPermissions,

            }
            assignPermissions(item.id, formData).then((response) => {
                getAllPermissionGroups();
            }).catch((e) => {
                console.log('assign default permissions error: ', e)
            })
        }
        else if(item.name === 'Supplier') {
            const formData = {
                name: item.name,
                permissions: supplierDefaultPermissions,

            }
            assignPermissions(item.id, formData).then((response) => {
                getAllPermissionGroups();
            }).catch((e) => {
                console.log('assign default permissions error: ', e)
            })
        }

        else if(item.name === 'WarehouseManager') {
            const formData = {
                name: item.name,
                permissions: warehouseManagerDefaultPermissions,

            }
            assignPermissions(item.id, formData).then((response) => {           
                getAllPermissionGroups();
            }).catch((e) => {
                console.log('assign default permissions error: ', e)
            })
        }

        else if(item.name === 'sales-assistant') {
            const formData = {
                name: item.name,
                permissions: salesAssistantDefaultPermissions,

            }
            assignPermissions(item.id, formData).then((response) => {
                getAllPermissionGroups();
            }).catch((e) => {
                console.log('assign default permissions error: ', e)
            })
        }
        else if(item.name === ADMIN) {
            const formData = {
                name: item.name,
                permissions: adminDefaultPermissions,

            }
            assignPermissions(item.id, formData).then((response) => {
                getAllPermissionGroups();
            }).catch((e) => {
                console.log('assign default permissions error: ', e)
            })
        }
    }

    const updatePermissions = (item: PermissionGroup ) => { // sends request to backend to update the permission of the selected group.    
        const formData = {
            name: item.name,
            permissions: item.permissions,
        }
        assignPermissions(item.id, formData).then((response) => {
            getAllPermissionGroups();
        }).catch((e) => {
            console.log('assign default permissions error: ', e)
        })    
    }

    const addPermission = (permissionGroupItem: PermissionGroup, permissionId: number) => {
        let updatedPermissionGroups = [...permissionGroups];
        updatedPermissionGroups.map((item: PermissionGroup) => {
            if(item.id === permissionGroupItem.id) {
                const updatedPermission = {
                ...item,
                    permissions: [...item.permissions, permissionId]
                };
                updatePermissions(updatedPermission);
            }
        })
        dispatch(setPermissionGroups(updatedPermissionGroups));
    }
    const removePermission = (permissionGroupItem: PermissionGroup, permissionId: number) => {

        let updatedPermissionGroups = [...permissionGroups];
        updatedPermissionGroups.map((item: PermissionGroup) => {
            if(item.id === permissionGroupItem.id) {
                const updatedPermission = {
                ...item,
                    permissions: item.permissions.filter((obj) => {return obj !== permissionId})
                };
                updatePermissions(updatedPermission);
            }
        })
        dispatch(setPermissionGroups(updatedPermissionGroups));
    }
    const UserPermissionsViewComponent = (componentProps: {item: PermissionGroup; onPressPermission:(id: number) => void;}) => {
        const { item, onPressPermission } = componentProps;
        const [searchText, setSearchText] = useState<string>("");
        return (
            <CAccordion activeItemKey={1} style={{marginTop: 16}}>
                <CAccordionItem itemKey={1}>
                    <CAccordionHeader className="elv-btn">{item?.name}</CAccordionHeader>
                    <CAccordionBody>
                        <div className="scroll-y">                        
                            { item?.permissions?.length === 0 ?
                                <CButton className='elv-btn' onClick={()=> assignDefaultPermissions(item)}>{t('adminDashboard.CommonWords.assign_default_permissions')}</CButton>
                            :<>
                                <CFormInput className='margin-bottom-12' value={searchText} onChange={(e) => {setSearchText(e.target.value)}}/>
                                {
                                    permissions.filter((permission: any)=>{return permission?.name?.toLowerCase()?.includes(searchText.toLowerCase())}).map((permissionObject: any) => (
                                        <div className="form-check">
                                            <input
                                                className="form-check-input pointer" 
                                                type="checkbox" 
                                                value="" 
                                                id={`flexCheckChecked${item?.id}${permissionObject.id}`} 
                                                checked={item?.permissions?.includes(permissionObject.id)} 
                                                onClick={() => { onPressPermission(permissionObject.id)}}
                                            />
                                            <label className="form-check-label">
                                                {permissionObject.name}
                                            </label>
                                        </div>
                                    ))
                                }
                                </>
                            }
                        </div>
                    </CAccordionBody>
                </CAccordionItem>
            </CAccordion>
        )
    }
    useEffect(()=>{
        getAllGroupsData();
        getAllPermissionsData();
    },[]);
  return (
    <div className='website-settings-main-container'>
      <h1><b>{t('adminDashboard.CommonWords.permissions')}</b></h1>
      {
        <>
            {
                permissionGroups.map((item: PermissionGroup, index: number) => {
                    return (
                        (item.name == 'Admin' && authUser.groups?.includes(ADMIN)) ?
                         null

                        : (item.name != 'SuperAdmin') ?
                            <UserPermissionsViewComponent
                                item={item}
                                onPressPermission = { (id: number) => !item?.permissions?.includes(id) ?  addPermission(item, id) : removePermission(item, id)} 
                            /> 
                        : null
                    )
                })
            }
        </>
      }
    </div>
  );
};

export default RightsManagementScreen;
