import { CSmartTable } from "@coreui/react-pro";
import { useState } from "react";
import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { Supplier } from "../../../types";
import EditSupplierModal from "../modals/EditSupplierModal";
import { deleteSuppliers, getSuppliers } from "../../../services/ProductService";
import { setSuppliers } from "../../../redux/slices/ProductSlice";
import { cilPen, cilDelete } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import DeleteItemModal from "../modals/DeleteItemModal";
import { Utils } from "../utils";
import { useTranslation } from "react-i18next";

interface SupplierProps {
    showEditedToast: () => void;
    showDeletedToast: () => void;
}

const AllSuppliers = (props: SupplierProps) => {
    const {showDeletedToast, showEditedToast} = props;
    const dispatch = useDispatch();
    const {AllSuppliersColumns} = Utils();
    const {t} = useTranslation();
    const suppliers = useSelector((state: RootState) => state.productSlice.suppliers);
    const [showEditSupplierModal, setShowEditSupplierModal] = useState<boolean>(false);
    const [selectedSupplierItem, setSelectedSupplierItem] = useState<Supplier>();
    const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState<boolean>(false);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);

   
    const getAllSuppliers = () => {
        getSuppliers().then((response) => {
            dispatch(setSuppliers(response.data));
            setShowEditSupplierModal(false);
          }).catch((e) => {
            dispatch(setSuppliers([]));
            console.log('get suppliers error: ', e);
          }); 
    }
    const deleteSupplierItem = () => {
        deleteSuppliers(selectedSupplierItem ? selectedSupplierItem.id : 0).then((response) => {
            getAllSuppliers();
            setShowDeleteSupplierModal(false);
            showDeletedToast();
        }).catch((e) => {

        })
    }
    return (
    <div>
         {
            showDeleteSupplierModal ?
            <DeleteItemModal 
                isModalVisible={showDeleteSupplierModal}
                headerText={"Are you sure?"}
                bodyText={"This will delete the item permanently."}
                closeModal={() => setShowDeleteSupplierModal(false)}
                confirmButtonText={"Delete"}
                onClickConfirmation={deleteSupplierItem}
            /> : null
        }
         {
            showEditSupplierModal ?
            <EditSupplierModal 
                isModalVisible={showEditSupplierModal}
                headerText={"Edit supplier information"}
                bodyText={"Please edit the following details of the supplier."}
                selectedSupplierItem={selectedSupplierItem}
                closeModal={() => setShowEditSupplierModal(false)}
                confirmButtonText={"Edit"}
                onClickConfirmation={() => {getAllSuppliers(); showEditedToast();}}
            /> : null
        }
        <CSmartTable
            columns={AllSuppliersColumns}
            tableFilter
            tableFilterLabel={`${t('adminDashboard.CommonWords.search')}`}
            tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
            items={suppliers}           
            itemsPerPage={itemsPerPage}
            itemsPerPageSelect
            pagination={true}

            paginationProps={{
              activePage: activePage,
              pages: Math.ceil(suppliers.length / itemsPerPage) || 1,
            }}
            tableHeadProps={{
              color: 'info',
            }}
            tableProps={{
              hover: true,
              responsive: true,
              style:{marginTop: 12},
            }}

            onActivePageChange={(activePage) => setActivePage(activePage)}
            onColumnFilterChange={(filter) => {
              setActivePage(1)
            }}

            onItemsPerPageChange={(itemsPerPage) => {
              setActivePage(1)
              setItemsPerPage(itemsPerPage)
            }}
            itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
            scopedColumns={{
                id: (item: Supplier) => {
                  return (
                    <td className="py-2 text-center">
                      {item.id}
                    </td>
                  )
                },
                name: (item: Supplier) => {
                    return (
                      <td className="py-2 text-center">
                        {item.name}
                      </td>
                    )
                },
                show_details: (item: Supplier) => {
                    return (
                      <td className="py-2 text-center">
                        <CIcon className="pointer" icon={cilPen} size="xl" style={{color: '#23B2D9'}} onClick={() => { setSelectedSupplierItem(item); setShowEditSupplierModal(true); }}/>                            
                      </td>
                    )
                },
            }}
        />
    </div>
    )
};
export default AllSuppliers;
