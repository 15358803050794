

import { useState } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CSmartTable, CSpinner, CToaster } from '@coreui/react-pro';
import { placeOrder} from '../../../services/OrderService';
import '../../AdminStyles.css';
import { Package } from '../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { ADMIN, PACKAGE_STATUS, SALES_ASSISTANT_GROUP_ID } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { deletePackage, getPackageSnapshots, getTruckCapacity, modifyPackage, revertPackageSnapshot } from '../../../services/PackageService';
import { setPendingPackages } from '../../../redux/slices/PackageSlice';
import SnapshotModal from '../../components/modals/SnapshotModal';
import { CBadge, CTooltip } from '@coreui/react';
import { downloadExcelFile } from '../../components/GenerateExcelFile';
import GenericConfirmationModal from '../../components/modals/DeleteItemModal';
import { Utils } from '../../components/utils';
import { getOrderPDFURL } from '../../components/DownloadPDF';
import { useTranslation } from 'react-i18next';
import { GenericErrorToast } from '../../components/create-package-components/ToastsComponent';

const PendingPackagesScreen = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const authUser = useSelector((state: RootState) => state.authSlice.user);
    const packages: Package[] = useSelector((state: RootState) => state.packageSlice.pendingPackages);
    const {getPackagesData, getOrdersData, PendingPackagesColumns, getPendingPackagesData, returnDateFormat} = Utils();
    const IS_ADMIN_OR_SUPERADMIN = (authUser.is_superuser || authUser?.groups?.includes(ADMIN));

    const [state, setState] = useState<any>({
      activePageNumber: 1,
      itemsPerPage: 20,
      loading: false,
      details: [],    
      loadMore: false,
      isModalVisible: false,
      snapshots: [],
      selectedPackage: undefined,
      packagesData: [],
      orderConfirmationModal: false,
      deletePackageConfirmationModal: false,
      totalProducts: 0,
    });
    const [toast, addToast] = useState<any>(null);

  const deletePackages = (id: number) => {
    setState({
      ...state,
      loading: true,
    });
    let updatedPackages = packages?.filter((packageItem: Package) => {
      return (packageItem.id !== id)
    });
    dispatch(setPendingPackages([...updatedPackages]));
    setState({
      ...state,
      loading: false,
      deletePackageConfirmationModal: false,
    });
    deletePackage(id).then((response) => {
      getOrdersData();
    }).catch((e) => {
      setState({
        ...state,
        loading: false,
        deletePackageConfirmationModal: false,
      });
      getPackagesData();
      getPendingPackagesData();
      getOrdersData();
      console.log('delete packages error: ', e);
    })
  };

  const confirm = (packageItem: Package) => {   
    let products = packageItem?.products?.map(number => `'${number}'`).join(',');

    let form_data = {
      "lager_numbers": '(' +  products + ')',
      fetched_pallet_count:  packageItem?.pallets_products?.length ? packageItem?.pallets_products?.length : 0,
    }
    getTruckCapacity(form_data).then((response) => {
      const totalCapacityOccupied = Number(response?.data?.truck_details?.occupancy["total_occupied_in_meters"]);
      if(!totalCapacityOccupied) {
        confirmOrder(packageItem, 0);
      }
      else {
        confirmOrder(packageItem, totalCapacityOccupied);
      }
    }).catch((e)=> {
        console.log('Truck capacity Exception: ', e);
        confirmOrder(packageItem, 0);
    });
  }

  const confirmOrder = (packageItem: Package, totalCapacityOccupied: number) => {
    let formData =  {
      "products": packageItem.products,
      accept_order: true,
    }
    setState({
      ...state,
      loading: true,
      orderConfirmationModal: true,
    });
    modifyPackage(packageItem.id, formData).then((PackageResponse) => {
      const itemSelected = (packageItem.quantity / state.totalProducts) * 100;
      let form_data =  {
        package: packageItem.id,
        truck_capacity: totalCapacityOccupied,
        items_selected: itemSelected.toFixed(2),
      };

      placeOrder(form_data).then((OrderResponse) => {
        getOrderPDFURL(OrderResponse.data.id);
        getPackagesData();
        getPendingPackagesData();
        navigation('/admin/dashboard/orders/requests', {state: {reload: true}});
      }).catch((e: any) =>{
        addToast(GenericErrorToast(()=>{addToast(null)}, e?.response?.data));
        setState({
          ...state,
          loading: false,
          orderConfirmationModal: false,
        })
      })
    }).catch((e: any) =>{
      addToast(GenericErrorToast(()=>{addToast(null)}, e?.response?.data));
    })
  }

  const getAllSnapshots = (selectedPackageItem: Package) => {
      setState({
        ...state,
        loading: true,
      })
      selectedPackageItem.id && getPackageSnapshots(selectedPackageItem.id).then((response) => {
        setState({
          ...state,
          snapshots: response.data,
          isModalVisible: true,
          selectedPackage: selectedPackageItem,
        })
      }).catch((e) => {
        setState({
          ...state,
          isModalVisible: false,
        })
      });
  }
  const revert = (snapshotId: number) => {
    setState({
      ...state,
      loading: true,
    })

    if(state.selectedPackage?.id) {
      revertPackageSnapshot(state.selectedPackage.id, snapshotId).then((response) => {
        getPackagesData();
        getPendingPackagesData();
        setState({
          ...state,
          isModalVisible: false,
          loading: false,
        })
      }).catch((e) => {
        getPackagesData();
        getPendingPackagesData();
        console.log('Error while reverting to snapshot: ', e);
      });
    }
  } 

  const calculateItemsSelected = (packageItem: Package) => {
    getPackageSnapshots(packageItem.id).then((response) => {
      setState({
        ...state,
        selectedPackage: packageItem,
        orderConfirmationModal: true,
        totalProducts:  response?.data[0]?.products.length ? response?.data[0]?.products.length : packageItem.quantity ,
      })
    }).catch((e) => {
      setState({
        ...state,
        selectedPackage: packageItem,
        orderConfirmationModal: true,
        totalProducts: packageItem.quantity,
      })
    });
  }
  return (
    <div className='website-settings-main-container margin-top-24'>
      <CToaster push={toast} placement="top-end" />
      <GenericConfirmationModal
        loading={state.loading}
        isModalVisible={state.orderConfirmationModal}
        closeModal={()=> setState({...state, orderConfirmationModal: false})}
        headerText={`${t('adminDashboard.Packages.are_you_sure')}`}
        bodyText={`${t('adminDashboard.PendingPackages.place_order_confirmation')}`}
        confirmButtonText={`${t('adminDashboard.Packages.place_order')}`}
        onClickConfirmation={() => {          
          confirm(state.selectedPackage)
        }}    
      />
      <GenericConfirmationModal
        isModalVisible={state.deletePackageConfirmationModal}
        closeModal={()=> setState({...state, deletePackageConfirmationModal: false})}
        headerText={`${t('adminDashboard.Packages.are_you_sure')}`}
        bodyText={`${t('adminDashboard.SupplyInventory.confirm_delete_item')}`}
        confirmButtonText={`${t('adminDashboard.CommonWords.delete')}`}
        onClickConfirmation={() => {
          deletePackages(state.selectedPackage.id);
        }}
      />
      <SnapshotModal 
        isModalVisible={state.isModalVisible}
        headerText={`${t('adminDashboard.Packages.revert_to_prev_snapshots')}`}
        bodyText={`${t('adminDashboard.Packages.which_snapshot')}`}
        closeModal={() => {
          setState({
            ...state,
            isModalVisible: false,
          })        
        }}
        snapshots={state.snapshots}
        revert={revert}
      />

      <h1 className='margin-bottom-24'><b>{t('adminDashboard.Packages.pending_packages')}</b></h1>
      {
        state.loading ?
          <CCol xs={12} style={{marginTop: 24, textAlign: 'center'}}>
            <CSpinner />
          </CCol>
        : null
      }
      <CSmartTable
        columns={PendingPackagesColumns}
        tableFilter={true}
        tableFilterLabel={`${t('adminDashboard.CommonWords.search')}`}
        tableFilterPlaceholder={`${t('adminDashboard.CommonWords.search')}`}
        items={ packages && packages?.length > 0 ? packages?.filter((item: Package) => {return (item.status === 'M' || item.status === 'A')}) : undefined}
        itemsPerPageSelect
        itemsPerPageLabel={`${t('adminDashboard.CommonWords.items_per_page')}`}
        noItemsLabel={`${t('adminDashboard.CommonWords.no_items_found')}`}
        pagination={true}
        tableHeadProps={{
          color: 'info',
        }}
        tableProps={{
          hover: true,
          striped: true,
          responsive: true,
        }}
        tableBodyProps={{
          className: 'align-middle'
        }}
        scopedColumns={{
          id: (item: Package) => {
            return (
              <td className="text-center">
                {item.id}
              </td>
            )
          },
          customer_company: (item: Package) => {
            return (
              <td className="text-center">
                {item.customer_company}
              </td>
            )
          },
          products_selection_percentage: (item: Package) => {
            return (
              <td className="text-center">
                {
                  item.products_selection_percentage === "0.000" ?
                  "-"
                  :
                  (Number(item.products_selection_percentage) * 100).toFixed(2)
                }
              </td>
            )
          },
          created_at: (item: Package) => {
            return (
              <td className="text-center">
                {returnDateFormat(item.created_at)}
              </td>
            )
          },
          status: (item: any) => {
            return (
              <td className='text-center'>
                <CBadge color={item.status == 'A' ? 'warning' : item.status == 'M' ? 'info' : item.status == 'F' ? 'success' : 'light'}>
                  <b>
                    {item.status && item.status == 'A' ? t('adminDashboard.CommonWords.pending') 
                      : item.status && item.status == 'M' ? t('adminDashboard.CommonWords.order_requested') 
                      : item.status && item.status == 'F' ? t('adminDashboard.OrdersWords.ordered') 
                      : null
                    }
                  </b>
                </CBadge>
                <div>
                  {
                    item.is_warehouse_package ? <CBadge className='margin-top-12' color={'dark'}><b>{t('adminDashboard.Warehouse.sidebar_text') }</b></CBadge> : null
                  }
                </div>
              </td>
            )
          },
          discount: (item: Package) => {
            return (
              <td className="text-center">
                {item.discount}
              </td>
            )
          },
          quantity: (item: Package) => {
            return (
              <td className="text-center">
                {item.quantity}
              </td>
            )
          },
          creator_first_name: (item: Package) => {
            return (
              <td className="text-center">
                {item.creator_first_name}
              </td>
            )
          },
          sub_total: (item: Package) => {
            return (
              <td className="text-center">
                {item.sub_total && Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.sub_total)}
              </td>
            )
          },
          total: (item: Package) => {
            return (
              <td className="text-center">
                {item.total && Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 }).format(item.total)}
              </td>
            )
          },
          note: (item: any) => {
            return (
              <td className='text-center'>
                {
                  item?.note?.length > 10  ?
                  <CTooltip
                    content={item.note}
                    placement="top"
                  >
                    <div className="text-center" style={{ maxWidth: 100, wordWrap: 'break-word' }}>
                      {`${item?.note?.slice(0, 10)}...`}
                    </div>
                  
                  </CTooltip>
                  :  item?.note || "--"
                }
              </td>
            )
          },
          show_details: (item: any) => {
            if(item.status === 'M' || item.status === 'A') {
              return (
                <td className="text-center">
                   <CDropdown>
                      <CDropdownToggle className="elv-btn" href="#" style={{width: 110}}>{t('adminDashboard.CommonWords.action')}</CDropdownToggle>
                      <CDropdownMenu className='pointer' style={{position: 'absolute', right: 20}}>
                        <CDropdownItem  onClick={() =>  { navigation('/admin/dashboard/packages/details', {state: {packageItem: item}})}}>{t('adminDashboard.CommonWords.view')}</CDropdownItem>
                        <CDropdownItem onClick={() =>  {downloadExcelFile(item.id, true)}}>{t('adminDashboard.CommonWords.download_excel')}</CDropdownItem>
                        {item?.customer_company && (((IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID)) && item.status != 'F') || ((item.status != 'F') && (item.creator_id == authUser.id))) ? <CDropdownItem  onClick={() =>  {calculateItemsSelected(item)}}>{t('adminDashboard.CommonWords.place_order')}</CDropdownItem> :  null}
                        {((IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID))) || ((item.status != 'F') && (item.creator_id == authUser.id)) ? <CDropdownItem  onClick={()=>   {
                          if (item.is_warehouse_package) {
                            navigation('/admin/dashboard/create/warehouse-package', {state: {packageItem: item.id}})
                          }
                          else {
                            navigation('/admin/dashboard/edit/package', {state: {packageId: item.id}})
                          }
                        }}>{t('adminDashboard.CommonWords.edit')}</CDropdownItem> : null }
                        {!item.is_warehouse_package && (IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID) || ((item.status != 'F') && (item.creator_id == authUser.id))) ? <CDropdownItem  onClick={() =>  {getAllSnapshots(item)}}>{t('adminDashboard.CommonWords.revert')}</CDropdownItem> : null}
                        {IS_ADMIN_OR_SUPERADMIN || authUser?.groups?.includes(SALES_ASSISTANT_GROUP_ID) || (item.creator_id == authUser.id) ? <CDropdownItem  onClick={() =>  {
                          setState({
                            ...state,
                            selectedPackage: item,
                            deletePackageConfirmationModal: true,
                          })
                        }}>{t('adminDashboard.CommonWords.delete')}</CDropdownItem> : null}
                      </CDropdownMenu>
                    </CDropdown>
                </td>
              )
            }
            else {
              return (
                <td className="py-2"></td>
              );
            }
          },
        }}
      
      />
    </div>
  );
};

export default PendingPackagesScreen;
