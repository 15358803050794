import { cilReload } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CHeader, CNav, CNavItem, CRow, CCol, CSpinner, CButton, CFormCheck, CTooltip } from "@coreui/react";
import { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { CUSTOMER_GROUP_ID, formattedPrice } from "../../constants";
import { LoggedinUser, Package } from "../../types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { isMobile } from "react-device-detect";

interface PackageDetailStickyHeaderProps {
    numOfTrucks: number;
    loading: boolean;
    truckCapacity: number;
    totalPrice: number;
    productCart: any;
    packageItem: Package | undefined;
    disableConfirmButton: boolean;
    calculateTruckCapacity: () => void;
    handleShowModal: () => void;
    showRefreshButton: boolean;
    isChecked: boolean;
    checkTermsAcceptanceDate: Date;
    setIsChecked: (value: boolean) => void;
} 

const PackageDetailStickyHeader = (props: PackageDetailStickyHeaderProps) => {
    const {numOfTrucks, loading, truckCapacity, productCart, packageItem, showRefreshButton,
         disableConfirmButton, totalPrice, calculateTruckCapacity, handleShowModal, isChecked, checkTermsAcceptanceDate, setIsChecked} = props;
    const [showHeader, setShowHeader] = useState<boolean>(false);
    const {t} = useTranslation();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
  
    const handleOpenNewTab = (url: string) => {
        return window.open(url, '_blank', 'noopener noreferrer');
    }

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            // Define the threshold at which the header should appear (e.g., 100px)
            const threshold = 290;
            if (scrollPosition > threshold) {
                setShowHeader(true);
            } else {
                setShowHeader(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

    }, []); // Run this effect only once on component mount

    return (
            <CHeader
                position="sticky" 
                className={`mb-4 ${showHeader ? 'd-block' : 'd-none'} ${isMobile ? "max-width" : ""}`}
                style={isMobile ? { top: 10, margin: 0, padding: 12} : {}}    
            >
                <CNav style={{ justifyContent: isMobile ? 'flex-start' : 'flex-end', alignItems: isMobile ? 'flex-start': 'flex-end' }}>
                    {
                        !checkTermsAcceptanceDate && (
                            <CRow className={isMobile ? "max-width" : "margin-right-12"}>
                                <CCol className={isMobile ? "max-width" : ""} xs={12}>
                                    <CNavItem>
                                        <div className="d-flex align-items-center">
                                            <CFormCheck
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={(e) => setIsChecked(e?.target?.checked)}
                                                className="white"
                                                label={
                                                    <>
                                                    {t('adminDashboard.CommonWords.terms_conditions_agreement')}{' '}
                                                    <span
                                                        onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (authUser?.groups && authUser.groups.includes(CUSTOMER_GROUP_ID)) {
                                                            handleOpenNewTab('/customer/terms-conditions');
                                                        }
                                                        }}
                                                        className="url-decoration-non-sticky white"
                                                    
                                                    >
                                                        {t('adminDashboard.CommonWords.terms_conditions_pdf')}
                                                    </span>
                                                    </>
                                                }
                                            />
                                        </div>
                                    </CNavItem>
                                </CCol>
                            </CRow>
                        )
                    }
                    <CRow >
                        <CCol className={"max-width"} xs={12}>
                            <CNavItem>
                                {
                                    loading ?
                                        <CRow>
                                            <CCol>
                                                <CSpinner />
                                            </CCol>
                                        </CRow>
                                        : null
                                }
                             </CNavItem>
                        </CCol>
                    </CRow>
                    <CRow className={isMobile ? "max-width" : ""}>
                        <CCol className={isMobile ? "max-width" : ""} xs={12}>
                            <CNavItem>
                                <h6 className="white">{numOfTrucks} x {t('adminDashboard.Packages.truck_capacity_text')} ({truckCapacity} m)</h6>
                                <ProgressBar completed={`${truckCapacity}`} maxCompleted={13.5} bgColor="#333333" width="100%" customLabel=" " />
                            </CNavItem>
                        </CCol>
                    </CRow>
                    <CNavItem>
                        {
                            showRefreshButton ?
                            <CRow>
                                <CCol className={"max-width"} xs={6} style={{marginTop: isMobile ? '12px': 0, marginLeft: isMobile ? 12: 0}}>
                                    <CButton
                                        className='elv-btn-header'
                                        variant="outline"
                                        shape="square"
                                        style={{ marginLeft: isMobile ? 0 : 20 }}
                                        onClick={calculateTruckCapacity}
                                        disabled={productCart.length == 0}
                                    >
                                        {t('adminDashboard.CommonWords.refresh')} 
                                        <CIcon
                                            icon={cilReload}
                                        />
                                    </CButton>
                                </CCol>
                                </CRow>

                            :
                                null
                        }
                    </CNavItem>
                    {
                        packageItem && packageItem.status === 'A' ? (
                            <CRow>
                                <CCol className={"max-width"} xs={6} style={{ marginTop: isMobile ? '12px' : 0, marginLeft: isMobile ? 12 : 0 }}>
                                    <CNavItem>
                                    {
                                        // Check the condition and show the tooltip if the condition is met
                                        !isChecked && !checkTermsAcceptanceDate && checkTermsAcceptanceDate === null && !showRefreshButton && productCart?.length > 0 ? (
                                        <CTooltip content={t('adminDashboard.CommonWords.terms_conditions_not_acceptance_error_message')} placement='top'>
                                            <div style={{ marginLeft: isMobile ? '0px' : '20px', marginRight: isMobile ? '0px' : '12px' }}>
                                                <CButton
                                                    className='elv-btn-header'
                                                    variant="outline"
                                                    disabled={productCart?.length === 0 || disableConfirmButton || loading || (!isChecked && checkTermsAcceptanceDate === null)}
                                                    onClick={() => {
                                                    handleShowModal();
                                                    }}
                                                >
                                                    {t('adminDashboard.Packages.confirm_package')}
                                                </CButton>
                                            </div>
                                        </CTooltip>
                                        ) : (
                                            <div style={{ marginLeft: isMobile ? '0px' : '20px', marginRight: isMobile ? '0px' : '12px' }}>
                                                <CButton
                                                    className='elv-btn-header'
                                                    variant="outline"
                                                    disabled={productCart?.length === 0 || disableConfirmButton || loading || (!isChecked && checkTermsAcceptanceDate === null)}
                                                    onClick={() => {
                                                        handleShowModal();
                                                    }}
                                                    >
                                                    {t('adminDashboard.Packages.confirm_package')}
                                                </CButton>
                                            </div>
                                        )
                                    }
                                    </CNavItem>
                                </CCol>
                            </CRow>
                        ) : null
                    }
                    <CRow>
                        <CCol className={"max-width"} xs={6} style={{marginTop: isMobile ? 16 : 0, alignItems: 'center', justifyContent: 'center'}}>
                            <CNavItem>
                                <h5 className="margin-left-12 margin-right-12 white"><b>{t('adminDashboard.Packages.total_items')}: {productCart.length}</b></h5> 
                            </CNavItem>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol className={"max-width"} xs={6} style={{marginTop: isMobile ? '12px': 0 }}>
                            <CNavItem>
                                <h5 className="margin-left-12 margin-right-12 white text-center" ><b>{t('adminDashboard.Customers.grand_total_euro_customer_side')}: {formattedPrice(totalPrice)}</b></h5>
                            </CNavItem>
                        </CCol>
                    </CRow>
                </CNav>
            </CHeader>
    )
};
export default PackageDetailStickyHeader;