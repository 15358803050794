import { useEffect, useState, ChangeEvent } from 'react';
import 'simplebar/dist/simplebar.min.css';
import { CCol, CFormInput, CNav, CHeader, CRow, CNavItem, CToaster } from '@coreui/react-pro';
import Select from 'react-select';
import '../CustomerStyles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Package, ProductItem, DropDownItem, LoggedinUser, CustomerPatchData } from '../../types';
import { getPackage, modifyPackage } from '../../services/PackageService';
import { RootState } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';
import { getTruckCapacity } from '../../services/PackageService';
import { useTranslation } from 'react-i18next';
import { setProductCart, setTotalPrice, setShowRefreshButton } from '../../redux/slices/CustomerCartSlice';
import PackageDetailProductsComponent from '../components/PackageDetailProducts';
import PackageDetailStickyHeader from '../components/PackageDetailStickyHeader';
import Divider from '../../admin/components/divider';
import PackageLoader from '../../admin/components/product-components/PackageLoader';
import PackageDetailNonStickyHeader from '../components/PackageDetailNonStickyHeader';
import ConfirmPackageModal from '../../admin/components/modals/ConfirmPackageModal';
import { CUSTOMER_GROUP_ID } from '../../constants';
import { isMobile } from 'react-device-detect';
import { updateCustomerData, getCustomerData } from '../../services/CustomerService';
import { GenericErrorToast } from '../../admin/components/create-package-components/ToastsComponent';

const CustomerPackagesDetails = () => {
    const state = useLocation();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const productCartSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.productCart;
    const totalPriceSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.totalPrice;
    const showRefreshButtonSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.showRefreshButton;

    const [products, setProducts] = useState<ProductItem[]>([]);
    const [combinedProductIds, setCombinedProductIds] = useState<number[]>([]);
    const [packageItem, setPackageItem] = useState<Package>();
    const [numOfTrucks, setNumOfTrucks] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [truckCapacity, setTruckCapacity] = useState<number>(0);
    const [disableConfirmButton, setDisableConfirmButton] = useState<boolean>(true);
    const [subCategoryOptions, setSubCategoriesOptions] = useState<DropDownItem[]>([]);
    const [currentSubGroupList, setCurrentSubGroupList] = useState<number[]>([]);
    const [searchedInput, setSearchedInput] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    // const [showRefreshButton, setShowRefreshButton] = useState<boolean>(false);
    const [minScale, setMinScale] = useState('');
    const [maxScale, setMaxScale] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [note, setNote] = useState<string>('');
    const packageId = state?.state?.packageItem?.id || 0;
    const productCart = useSelector(productCartSelector(packageId)) || [];
    const totalPrice = useSelector(totalPriceSelector(packageId)) || 0;
    const showRefreshButton = useSelector(showRefreshButtonSelector(packageId)) || false;
    const [checkTermsAcceptanceDate, setCheckTermsAcceptedDate] = useState<Date>(new Date());
    const [isChecked, setIsChecked] = useState(false);
    const [toast, addToast] = useState<any>(null);


    const getPackageById = () => {
        getPackage(state?.state?.packageItem?.id).then((response) => {
            let updatedCombinedProductIds: number[] = [];
            setPackageItem(response.data);
            const allProducts = [...response.data.standalone_products, ...response.data.set_articles, ...response.data.pallets_products];
            setProducts(allProducts);
            response.data.set_articles.map((item: ProductItem) => {
                // this filteration means the set articles which are paired with eachother, only one of them should be rendered because it is a whole product for the customer and 
                // if the customer opens a detailed view, he can see details of both of the set articles separatly. but on main screen it would be shown as one single
                // product so that is why I have added this filteration.
                if (!updatedCombinedProductIds.includes(item.id)) {
                    if (item.combined_products && item.combined_products.length === 1) {
                        updatedCombinedProductIds.push(item.combined_products[0]);
                    }
                }
            });
            setCombinedProductIds(updatedCombinedProductIds);
            let addToCart = Boolean(response?.data?.add_to_cart);
            if (productCart?.length == 0 && addToCart) {
                const updatedProductCart = allProducts.map(item => item.id);
                const grandTotalPrice = allProducts.reduce((total, item) => total + Number(item.selling_price), 0);
                dispatch(setProductCart({ packageId: packageId, productCart: updatedProductCart }));
                dispatch(setTotalPrice({ packageId: packageId, totalPrice: grandTotalPrice }));
                addToCart = !addToCart;
                setTimeout(()=> {
                    calculateTruckCapacity(updatedProductCart, response.data.pallets_products);
                }, 1500);
            }
            else if(productCart?.length !== 0 && addToCart) {
                let updatedProductCart: number[] = [];
                let updatedProducts = [];
                updatedProductCart = productCart.filter((product_id: number) => {
                    return allProducts.some((item: ProductItem) => item?.id == product_id)
                })

                updatedProducts = allProducts.filter((productItem: ProductItem) => {
                    return productCart.includes(productItem.id)
                })

                const grandTotalPrice = updatedProducts.reduce((total, item) => total + Number(item.selling_price), 0)
                dispatch(setProductCart({ packageId: packageId, productCart: updatedProductCart }));
                dispatch(setTotalPrice({ packageId: packageId, totalPrice: grandTotalPrice }));
                setTimeout(()=> {
                    calculateTruckCapacity(updatedProductCart, response.data.pallets_products);
                }, 1500);
            }
            else if(productCart?.length !== 0 && !addToCart) {
                let updatedProductCart: number[] = [];
                let updatedProducts = [];
                updatedProductCart = productCart.filter((product_id: number) => {
                    return allProducts.some((item: ProductItem) => item?.id == product_id)
                })

                updatedProducts = allProducts.filter((productItem: ProductItem) => {
                    return productCart.includes(productItem.id)
                })

                const grandTotalPrice = updatedProducts.reduce((total, item) => total + Number(item.selling_price), 0)
                dispatch(setProductCart({ packageId: packageId, productCart: updatedProductCart }));
                dispatch(setTotalPrice({ packageId: packageId, totalPrice: grandTotalPrice }));
                setTimeout(()=> {
                    calculateTruckCapacity(updatedProductCart, response.data.pallets_products);
                }, 1500);
            }
        }).catch((e) => {
            console.log('error while getting package: ', e);
        })
    }

    const changePackageStatus = () => {
        const productPercentage =  packageItem !== undefined ? (productCart?.length / packageItem?.products?.length) : 0;
        let formData = {
            products: productCart,
            note: note,
            products_selection_percentage: productPercentage > 1 ? 1 : productPercentage.toFixed(2),
        }
        if (isChecked || checkTermsAcceptanceDate) {
            modifyPackage(state?.state?.packageItem?.id, formData).then((response) => {
                updateCustmoer();
                setLoading(false);
                navigation('/customer/packages/summary');
            }).catch((e: any) => {
                setLoading(false);
                addToast(GenericErrorToast(() => {addToast(null)}, `Error while placing an order ${e}`));
            })
        } else {
            setLoading(false);
            addToast(GenericErrorToast(()=>{addToast(null)}, `${t('adminDashboard.CommonWords.terms_conditions_not_acceptance_error_message')}`));
        }
    }

    // Calculate Truck Capacity
    const calculateTruckCapacity = (updatedCart?: number[], pallet?: ProductItem[]) => {
        setLoading(true);
        let fetched_pallet_count = 0;
        let productIds;
        if(updatedCart && updatedCart?.length > 0) {
            productIds = updatedCart.map(number => `'${number}'`).join(',');
            pallet && pallet.map((pallet) => {
              if(updatedCart.includes(pallet.id)){
                fetched_pallet_count = fetched_pallet_count + 1;
              }
            })
        }
        else {
            productIds = productCart.map(number => `'${number}'`).join(',');
            products.filter((item) => ('pallet_products' in item)).map((pallet) => {
              if(productCart.includes(pallet.id)){
                fetched_pallet_count = fetched_pallet_count + 1;
              }
            })
        }
       
        let form_data = {
            "lager_numbers": '(' + productIds + ')',
            "fetched_pallet_count": fetched_pallet_count,
        }
        getTruckCapacity(form_data).then((response) => {
            const totalCapacityOccupied = Number(response.data.truck_details.occupancy["total_occupied_in_meters"]);
            if(totalCapacityOccupied) {
                const totalTrucks = Math.ceil(totalCapacityOccupied / 13.6);
                const currentTruckCapacity = (totalCapacityOccupied - ((totalTrucks - 1) * 13.6));
                setNumOfTrucks(totalTrucks);
                setTruckCapacity(parseFloat(currentTruckCapacity.toFixed(2)));
                setDisableConfirmButton(false);
                setLoading(false);
            }
            else {
                setNumOfTrucks(0);
                setDisableConfirmButton(false);
                setLoading(false);
            }
           dispatch(setShowRefreshButton({packageId: packageId, showRefreshButton: false}));
        }).catch((e) => {
            setDisableConfirmButton(false);
            setLoading(false);
            dispatch(setShowRefreshButton({packageId: packageId, showRefreshButton: false}));
        });
    }

    // Render Unique Products Sub-Catagories
    useEffect(() => {
        const uniqueSubCategories = Array.from(products.reduce((unique, item) => {
            if (!unique.has(item.sub_category)) {
                unique.set(item.sub_category, { value: item.sub_category, label: item.sub_category?.toString() });
            }
            return unique;
        }, new Map()).values());
        setSubCategoriesOptions(uniqueSubCategories);
    }, [products.length]);

    // A Note/Message from customer while placing an order
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    
    const handleChangeInfo = (event: ChangeEvent<HTMLTextAreaElement>) => setNote(event.target.value);

    const handleConfirmPackage = () => {
        setLoading(true);
        //Call change Pacakge Status
        changePackageStatus();
        //Close Modal
        handleCloseModal();
    };

    // Get customer data
    const getCurrentCustomerData = () => {
        getCustomerData().then((response) => {
            setCheckTermsAcceptedDate(response?.data?.terms_accepted_date);
        }).catch((e) => {
            console.log('Error while getting customer data : ', e);
        }).finally(() => {
            // setLoading(false);
        });
    };

     // Update customer data partially
    const updateCustmoer = () => {
        const patchData: CustomerPatchData = {
        terms_accepted_date: new Date().toISOString()
        };
        updateCustomerData(patchData).then((response) => {
        }).catch((e) => {
        console.log('Error updating customer data: ', e);
        });
    };

    //
    useEffect(() => {
        getCurrentCustomerData();
        getPackageById();
        setDisableConfirmButton(true);
    }, []);
    useEffect(() => {
       if(showRefreshButton) {
        setDisableConfirmButton(true);
       }
    }, [showRefreshButton]); 

    return (
        <>
        <CToaster push={toast} placement="top-end" />
        {
              authUser?.groups?.includes(CUSTOMER_GROUP_ID) ?
                <PackageDetailStickyHeader
                    numOfTrucks={numOfTrucks}
                    loading={loading}
                    truckCapacity={truckCapacity}
                    totalPrice={totalPrice}
                    productCart={productCart}
                    packageItem={packageItem}
                    disableConfirmButton = {disableConfirmButton}
                    calculateTruckCapacity={calculateTruckCapacity}
                    handleShowModal={handleShowModal}
                    showRefreshButton={showRefreshButton}
                    isChecked={isChecked}
                    checkTermsAcceptanceDate={checkTermsAcceptanceDate}
                    setIsChecked={setIsChecked}
                />
              : null
        }
        <div style={{padding:authUser.groups?.includes(CUSTOMER_GROUP_ID) ? 15 : 25, backgroundColor: 'white'}}>
            {
                authUser?.groups?.includes(CUSTOMER_GROUP_ID) ?
                <>
                    <PackageDetailNonStickyHeader 
                        loading={loading}
                        packageItem={packageItem}
                        totalPrice={totalPrice}
                        productCart={productCart}
                        numOfTrucks={numOfTrucks}
                        truckCapacity={truckCapacity}
                        handleShowModal={handleShowModal}
                        disableConfirmButton={disableConfirmButton}
                        calculateTruckCapacity={calculateTruckCapacity}
                        showRefreshButton={showRefreshButton}
                        isChecked={isChecked}
                        checkTermsAcceptanceDate={checkTermsAcceptanceDate}
                        setIsChecked={setIsChecked}
                        
                    />
                </>
                    : null
            }
            <CHeader>
                <h1><b>{t('adminDashboard.OrdersWords.package_id')} # {state?.state?.packageItem?.id}</b></h1>
            </CHeader>
            {
                products.length == 0 ?
                <PackageLoader /> 
                :
                <>
                    <CHeader style={{ justifyContent: isMobile ? 'center' : 'flex-end', alignItems:  isMobile ? 'center' : 'flex-end' }}>
                        <CNav>
                            <CNavItem>
                                <CRow>
                                    <CCol xs={12} style={{ width: '300px', marginTop: isMobile ? 12 : 0 }}>
                                        <Select
                                            isMulti
                                            placeholder={`${t('adminDashboard.CommonWords.select')}`}
                                            options={subCategoryOptions}
                                            onChange={(options: any) => {
                                                setCurrentSubGroupList(
                                                    options.map((option: any) => (option.value))
                                                )
                                            }}
                                        />
                                    </CCol>
                                </CRow>
                            </CNavItem>
                            <CNavItem>
                                <CRow>
                                    <CCol xs={10}>
                                        <CFormInput
                                            type='text'
                                            placeholder={`${t('adminDashboard.CommonWords.search')} e.g. (Mikrowelle)`}
                                            value={searchedInput}
                                            onChange={(e) => setSearchedInput(e.target.value)}
                                            className="custom-margin-right ml-10"
                                            style={{ marginLeft: isMobile ? '0px' :'12px', marginTop: isMobile ? 12 : 0  }}
                                        />
                                    </CCol>
                                </CRow>
                            </CNavItem>
                            <CNavItem>
                                <CRow>
                                    <CCol >
                                        <CFormInput
                                            type='number'
                                            placeholder="Min Price"
                                            min="0"
                                            step="0.01"
                                            value={minPrice}
                                            onChange={(e) => setMinPrice(e.target.value)}
                                            style={{ marginRight: '10px', width: '120px', marginTop: isMobile ? 12 : 0 }}
                                        />
                                    </CCol>
                                </CRow>
                            </CNavItem>
                            <CNavItem>
                                <CRow>
                                    <CCol >
                                        <CFormInput
                                            type='number'
                                            placeholder="Max Price"
                                            min="0"
                                            step="0.01"
                                            value={maxPrice}
                                            onChange={(e) => setMaxPrice(e.target.value)}
                                            style={{ marginRight: '10px', width: '120px', marginTop: isMobile ? 12 : 0  }}
                                        />
                                    </CCol>
                                </CRow>
                            </CNavItem>
                            <CNavItem>
                                <CRow>
                                    <CCol >
                                        <CFormInput
                                            type='number'
                                            placeholder="Min Scale"
                                            min="0"
                                            step="1"
                                            value={minScale}
                                            onChange={(e) => setMinScale(e.target.value)}
                                            style={{ marginRight: '10px', width: '120px', marginTop: isMobile ? 12 : 0  }}
                                        />
                                    </CCol>
                                </CRow>
                            </CNavItem>
                            <CNavItem>
                                <CRow>
                                    <CCol >
                                        <CFormInput
                                            type='number'
                                            placeholder="Max Scale"
                                            min="0"
                                            step="1"
                                            value={maxScale}
                                            onChange={(e) => setMaxScale(e.target.value)}
                                            style={{ width: '120px', marginTop: isMobile ? 12 : 0  }}
                                        />
                                    </CCol>
                                </CRow>
                            </CNavItem>
                        </CNav>
                    </CHeader>
                    <PackageDetailProductsComponent
                        products={products.filter((item) => !('pallet_products' in item))}
                        minPrice={minPrice}
                        maxPrice={maxPrice}
                        minScale={minScale}
                        maxScale={maxScale}
                        searchedInput={searchedInput}
                        currentSubGroupList={currentSubGroupList} 
                        productCart={productCart} 
                        combinedProductIds={combinedProductIds}
                        packageItem={packageItem}
                        setShowRefreshButton={(value: boolean) => dispatch(setShowRefreshButton({packageId: packageId, showRefreshButton: value}))}
                        setDisableConfirmButton={setDisableConfirmButton}
                    />
                    {
                        products.filter((item) => ('pallet_products' in item)).length > 0 ?
                            <>
                                <Divider color={'#23B2D9'} thickness={1} margin={12} />
                                <h5>Pallets:</h5>
                                <PackageDetailProductsComponent
                                    products={products.filter((item) => ('pallet_products' in item))}
                                    minPrice={minPrice}
                                    maxPrice={maxPrice}
                                    minScale={minScale}
                                    maxScale={maxScale}
                                    searchedInput={searchedInput}
                                    currentSubGroupList={currentSubGroupList} 
                                    productCart={productCart} 
                                    combinedProductIds={combinedProductIds}
                                    packageItem={packageItem}
                                    setShowRefreshButton={(value: boolean) => dispatch(setShowRefreshButton({packageId: packageId, showRefreshButton: value}))}
                                    setDisableConfirmButton={setDisableConfirmButton}
                                />
                            </>
                            : null
                    }
        </>
        }
            {/* Modal */}
            <ConfirmPackageModal
                note={note} 
                showModal={showModal} 
                productCart={productCart} 
                handleCloseModal={handleCloseModal}
                handleChangeInfo={handleChangeInfo} 
                handleConfirmPackage={handleConfirmPackage}
                packageItem={packageItem}
            />
        </div >
        </>
    );
};
export default CustomerPackagesDetails;
