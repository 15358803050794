import '../CustomerStyles.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const TermsConditionsPDFScreen = () => {
    const { i18n } = useTranslation();
    const [pdfUrl, setPdfUrl] = useState('');

    useEffect(() => {
        const language = i18n.language;

        switch (language) {
            case 'ro':
                setPdfUrl(require('../../assets/pdf/acord-cadru_pentru_RO.pdf'));
                break;
            case 'de':
                setPdfUrl(require('../../assets/pdf/Rahmenlieferungsvereinbarung_DE.pdf'));
                break;
            case 'ru':
                setPdfUrl(require('../../assets/pdf/Общий_договор_поставки_RUS.pdf'));
                break;
            case 'gr':
                setPdfUrl(require('../../assets/pdf/Συμφωνία-πλαίσιο_παράδοσης_για_μετάφραση_GR.pdf'));
                break;
            default:
                setPdfUrl(require('../../assets/pdf/terms_conditions_agreement_EN.pdf'));
        }
    }, [i18n.language]);
 
    return (
        <div className="pdf-container">
        {pdfUrl ? (
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js`}>
                <div className="responsive-iframe">
                    <Viewer fileUrl={pdfUrl} />
                </div>
            </Worker>
        ) : (
            <div>Loading PDF...</div>
        )}
    </div>
    );
};

export default TermsConditionsPDFScreen;