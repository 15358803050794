import { useEffect, useState } from 'react'
import 'simplebar/dist/simplebar.min.css';
import '../../AdminStyles.css';
import { DropDownItem, LoggedinUser, Pallet, PalletProduct, Package } from '../../../types';
import { CButton, CCol, CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle, CFormInput, CRow, CSpinner } from '@coreui/react';
import PalletDetailsCardComponent from '../../components/pallet-card-component/PalletDetailsCardComponent';
import { getFilteredPalletProducts,  getPalletById, editPalletProduct, deletePalletProduct} from '../../../services/PalletService';
import { CSmartTable } from '@coreui/react-pro';
import {ADMIN, BACK_OFFICE_GROUP_ID, CUSTOMER_GROUP_ID, PalletProductcolumns, SALES_ASSISTANT_GROUP_ID, SUPER_ADMIN, SUPPLIER_GROUP_ID, WAREHOUSE_GROUP_ID} from '../../../constants';
import Select from 'react-select';
import { RootState } from '../../../redux/store';
import { useSelector } from 'react-redux';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import GenericConfirmationModal from '../../components/modals/DeleteItemModal';
import AddPalletProductModal from '../../components/modals/AddPalletProductModal';
import { formattedPrice } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { Utils } from '../../components/utils';

const PalletDetailScreen = () => {
  const { t } = useTranslation();
  const {PalletProductReducedColumns, getAllProductArticles, getAllProductSubCategories, PalletProductWarehouseRoleColumns} = Utils();
  const palletDataString = localStorage.getItem('pallet');
  const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
  const [palletObject, setPalletObject] = useState<Pallet>(palletDataString ? JSON.parse(palletDataString) : null);
  const productArticles = useSelector((state: RootState) => state.productSlice.productArticles);
  const articleOptions: DropDownItem[] = productArticles.map((article: string) => ({ value: article, label: article }));
  const [palletProducts, setPalletProducts] = useState<PalletProduct[]>([]);
  const [selectedPalletProduct, setSelectedPalletProduct ] = useState<PalletProduct>();
  const [loading, setLoading] = useState<boolean>(false);
  const [productsLoader, setProductsLoader] = useState<boolean>(true);
  const [showAddPalletProductModal, setShowAddPalletProductModal] = useState<boolean>(false);
  const [packageConfirmationModal, setPackageConfirmationModal] = useState<boolean>(false);
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [records, setRecords] = useState<number>(0);
  const [editPalletProductId, setEditPalletProductId] = useState<number>(0);
  const [currentArticle, setCurrentArticle] = useState<DropDownItem>({label: "Select", value: ""});
  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const [currentBuyingPrice, setCurrentBuyingPrice] = useState<number>(0);
  const [currentQuantity, setCurrentQuantity] = useState<number>(0);
  const [articleSearchText, setArticleSearchText] = useState<string>('');
  const packageItemString = localStorage.getItem('packageItem');
  const packageItem: Package = packageItemString ? JSON.parse(packageItemString) : null;
  const productCartSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.productCart;
  const totalPriceSelector = (packageId: number) => (state: RootState) => state.CustomerCartSlice[packageId]?.totalPrice;
  const productCart = useSelector(productCartSelector(packageItem?.id)) || [];
  const totalPrice = useSelector(totalPriceSelector(packageItem?.id)) || 0;

  useEffect(()=>{
    setLoading(true);
    getAllProductArticles();
    getAllProductSubCategories();
    getPalletObject();
    getPalletProducts();
  },[]);

  const getPalletObject = () => {
    getPalletById(palletObject.id).then((response) => {
      setPalletObject(response.data);
      setLoading(false);
    }).catch((e) => {
      console.log('get pallet error: ', e);
      setLoading(false);
    });
  }

  const getPalletProducts = () => {
    getFilteredPalletProducts(palletObject?.id).then((response) => {
      setPalletProducts(response.data);
      // whenever the pallet product is updated the price of pallet recalculated from change in pallet product.
      setLoading(false);
      setProductsLoader(false);
      setRecords(response?.data?.length);
    }).catch((e) => {
      console.log('get pallet products error: ', e);
      setPalletProducts([]);
      setRecords(0);
      setLoading(false);
      setProductsLoader(false);
    }); 
  } 

  const editPalletProductsData = (item: PalletProduct) => {
    setProductsLoader(true);
    const formData = {
      selling_price: currentPrice,
      buying_price: currentBuyingPrice,
      article: currentArticle.value,
      quantity: currentQuantity,
    }
    editPalletProduct(item.id, formData).then((response) => {
      getPalletObject();
      getPalletProducts();
    }).catch((e) => {
      console.log('edit pallet product error.')
    })
  }

  const deletePalletProductsData = (item: PalletProduct | undefined) => {
    if(item){
      setProductsLoader(true);
      deletePalletProduct(item.id).then((response) => {
        let updatedPalletProducts = [];
        updatedPalletProducts =  palletProducts.filter((palletProduct)=> {
          return palletProduct.id !== item.id 
        });
        setPalletProducts(updatedPalletProducts);
        setProductsLoader(false);
        getPalletObject();
        // getPalletProducts();
      }).catch((e) => {
        console.log('edit pallet product error.');
        setProductsLoader(false);
      })
    }
  }
  
  return (
    <div>
      <AddPalletProductModal
        isModalVisible={showAddPalletProductModal}
        closeModal={()=>{
          setShowAddPalletProductModal(false);
        }}
        onSuccess={()=>{
          setProductsLoader(true);
          setShowAddPalletProductModal(false);
          getPalletObject();
          getPalletProducts();
        }}
        pallet_id={palletObject.id}
      />
        <GenericConfirmationModal
          isModalVisible={packageConfirmationModal}
          closeModal={()=> {setPackageConfirmationModal(false)}}
          headerText={'Are you sure?'}
          bodyText={`Are you sure, you want to delete this item.`}
          confirmButtonText={'Delete'}
          onClickConfirmation={() => {
            deletePalletProductsData(selectedPalletProduct);
            setPackageConfirmationModal(false);
          }}
        hideCancelButton={true}
        />
      <div className='website-settings-main-container'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <h3><b>{t('adminDashboard.Pallets.pallet_details')}</b></h3>
          </div>
            {authUser?.groups?.includes(CUSTOMER_GROUP_ID) && (
            <div style={{ marginLeft: 'auto' }}>
              <h6><b>{t('adminDashboard.Packages.total_items')}: {productCart.length}</b></h6>
              <h6><b>{t('adminDashboard.Customers.grand_total_euro_customer_side')}: {formattedPrice(totalPrice)}</b></h6>
            </div>
          )}          
        </div>
        {
          loading ?
            <CRow><CCol className="text-center"><CSpinner /></CCol></CRow> 
          :
          <>
            <div className='margin-bottom-44'>
              <PalletDetailsCardComponent
                pallet={palletObject}
                setPalletObject={setPalletObject}
                palletEditable={(authUser.groups?.includes(BACK_OFFICE_GROUP_ID) || authUser.groups?.includes(SUPER_ADMIN) || authUser.groups?.includes(SALES_ASSISTANT_GROUP_ID) || authUser.groups?.includes(ADMIN)) ?  true : false}
                showButton={true}
              />
            </div>
          </>
        }
        {
          palletObject?.is_sold ?
            null
          :
            <CRow className='align-row-right'>
              <CCol className='align-col-right'>
                {(authUser.groups?.includes(BACK_OFFICE_GROUP_ID) || authUser.groups?.includes(SUPER_ADMIN) || authUser.groups?.includes(SALES_ASSISTANT_GROUP_ID) || authUser.groups?.includes(ADMIN)) && <CButton className='elv-btn' size='sm' onClick={()=>setShowAddPalletProductModal(true)}>{t('adminDashboard.Pallets.add_pallet_product_button')}</CButton>}
              </CCol>
            </CRow>
        }
       
        <CSmartTable
          columns={authUser?.groups?.includes(CUSTOMER_GROUP_ID) ? PalletProductReducedColumns : (authUser?.groups?.includes(WAREHOUSE_GROUP_ID) || authUser?.groups?.includes(SUPPLIER_GROUP_ID)) ? PalletProductWarehouseRoleColumns : PalletProductcolumns}
          tableFilter={palletProducts && palletProducts?.length > 0 ? true : false}
          tableFilterPlaceholder={'Table Filter'}
          tableFilterLabel=''
          items={palletProducts}
          itemsPerPage={itemsPerPage}
          loading={productsLoader}
          itemsPerPageSelect
          onActivePageChange={(activePage) => setActivePage(activePage)}
          onItemsPerPageChange={(itemsPerPage) => {
            setActivePage(1)
            setItemsPerPage(itemsPerPage)
          }}
          pagination={true}  
          paginationProps={{
            activePage: activePage,
            pages: Math.ceil(records / itemsPerPage) || 1,
          }}
          tableHeadProps={{
            color: 'info',
            style: { textAlign: 'center' },
          }}
          tableProps={{
            hover: true,
            responsive: true,
            style:{marginTop: 6},
            striped: true,
          }}
          tableBodyProps={{
            className: 'align-middle'
          }}
          scopedColumns={{
            current_online_price: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {item.current_online_price}
                </td>
              )
            },
            total_current_online_price: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {item.total_current_online_price}
                </td>
              )
            },
            total_selling_price: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {item.total_selling_price}
                </td>
              )
            },
            brand: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {item.brand}
                </td>
              )
            },
            sub_category: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {item.sub_category}
                </td>
              )
            },
            buying_price: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {
                    editPalletProductId == item.id ?
                      <CFormInput
                        type="number"
                        value={currentBuyingPrice}
                        onChange={(e)=> {
                          setCurrentBuyingPrice(Number(e.target.value))
                        }}
                      />
                    :
                      item.buying_price
                  }
                </td>
              )
            },
            quantity: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {
                    editPalletProductId == item.id ?
                      <CFormInput
                        type="number"
                        value={currentQuantity}
                        onChange={(e)=> {
                          setCurrentQuantity(Number(e.target.value))
                        }}
                      />
                    :
                      item.quantity
                  }
                </td>
              )
            },
            selling_price: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {
                    editPalletProductId == item.id ?
                      <CFormInput
                        type="number"
                        value={currentPrice}
                        onChange={(e)=> {
                          setCurrentPrice(Number(e.target.value))
                        }}
                      />
                    :
                      item.selling_price
                  }
                </td>
              )
            },
            article: (item: PalletProduct) => {
              return (
                <td className='center'>
                  {
                    editPalletProductId == item.id ?
                      <Select
                        value={currentArticle}
                        placeholder={t('adminDashboard.CommonWords.min_3_digits')}
                        noOptionsMessage={({ inputValue: string }) => articleSearchText.length >= 3 ? 'Article not found.' : t('adminDashboard.CommonWords.min_3_digits') }
                        options={ articleSearchText.length >= 3 ? articleOptions : []}
                        onChange={(option: any) => {
                          setCurrentArticle(option)
                        }} 
                        onInputChange = {(option: string)=>{
                          setArticleSearchText(option)
                        }}
                      />
                    :
                      item.article
                  }
                </td>
              )
            },
            show_details: (item: PalletProduct) => {
              return (
                <td>
                  {
                    editPalletProductId == item.id ?
                    <>
                      <CButton className='elv-btn' size='sm' onClick={()=> {setEditPalletProductId(0);editPalletProductsData(item)}}>
                        {t('adminDashboard.CommonWords.confirm')}
                      </CButton>
                      <CIcon icon={cilTrash} size='lg' color='red' className='margin-left-12' onClick={() => {setPackageConfirmationModal(true); setSelectedPalletProduct(item)}}/>
                    </>
                    :
                    <CDropdown>
                      <CDropdownToggle disabled={palletObject?.is_sold} className="elv-btn" href="#" style={{width: 100}}>{palletObject?.is_sold ? "SOLD" : "Action"}</CDropdownToggle>
                      {
                        (palletObject?.is_sold == false && (authUser.groups?.includes(BACK_OFFICE_GROUP_ID) || authUser.groups?.includes(SUPER_ADMIN) || authUser.groups?.includes(SALES_ASSISTANT_GROUP_ID) || authUser.groups?.includes(ADMIN))) ? 
                        <CDropdownMenu style={{position: 'absolute'}}>
                          <CDropdownItem  onClick={() =>  {
                            setEditPalletProductId(item.id);
                            setCurrentArticle({label: item.article, value: item.article});
                            setCurrentQuantity(item.quantity);
                            setCurrentPrice(item.selling_price)
                            setCurrentBuyingPrice(item.buying_price)
                          }}
                          >
                            {t('adminDashboard.CommonWords.edit')}
                          </CDropdownItem>
                          <CDropdownItem  onClick={() => {setPackageConfirmationModal(true); setSelectedPalletProduct(item)}}>{t('adminDashboard.CommonWords.delete')}</CDropdownItem>
                      </CDropdownMenu>
                      : null
                      }
                    </CDropdown>
                  }
                </td>
              )
            }
          }}
        />
      </div>
    </div>
  );
};

export default PalletDetailScreen;

