import { cilViewStream, cilFile } from '@coreui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import { CCol, CCard, CCardBody, CCardTitle, CCardText, CRow, CTooltip, CButton } from '@coreui/react';
import { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel';
import 'simplebar/dist/simplebar.min.css';
import { CUSTOMER_GROUP_ID, formattedPrice, SUPPLIER_GROUP_ID } from '../../constants';
import { Utils } from '../../admin/components/utils';
import { LoggedinUser, Package, Pallet, ProductItem } from '../../types';
import { RootState } from '../../redux/store';
import { addToCart, removeFromCart } from '../../redux/slices/CustomerCartSlice';
import EmptyImage from '../../assets/images/empty-image.jpg';
import '../CustomerStyles.css';
import 'simplebar/dist/simplebar.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useTranslation } from 'react-i18next';

interface PackageDetailProductsProps {
    products: any;
    minPrice: string;
    maxPrice: string;
    minScale: string;
    maxScale: string;
    searchedInput: string;
    currentSubGroupList: number[];
    productCart: any;
    packageItem: Package | undefined;
    combinedProductIds: number[];
    setShowRefreshButton: (value: boolean) => void;
    setDisableConfirmButton: (value: boolean) => void;
}
const PackageDetailProductsComponent = (props: PackageDetailProductsProps) => {
    const state = useLocation();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const authUser: LoggedinUser = useSelector((state: RootState) => state.authSlice.user);
    const [viewedItems, setViewedItems] = useState<number[]>([]);
    const {
        products, minScale, maxScale, minPrice, maxPrice, searchedInput, currentSubGroupList,
        productCart, combinedProductIds, packageItem, setShowRefreshButton, setDisableConfirmButton
    } = props;
    useEffect(() => {
        // Retrieve viewed items from local storage on component mount
        const viewedItemsKey = `${state?.state?.packageItem?.id}`;
        const storedViewedItems = JSON.parse(localStorage.getItem(viewedItemsKey) || '[]');
        setViewedItems(storedViewedItems);
    }, []); // Empty dependency array to run the effect only on mount
    
    const { filterByPriceCriteria, filterByScaleCriteria, filterBySearchedCriteria, filterBySubCategory, getLink } = Utils();
    
    const handleAddToCart = (packageId: number, id: number, selling_price: number, combined_products?: number[]) => {
        const isInCart = productCart.some((item: number) => item === id);
        if (isInCart) {
            dispatch(removeFromCart({ packageId: packageId, id: id, selling_price: selling_price, combined_products: combined_products }));
        } else {
            dispatch(addToCart({ packageId: packageId, id: id, selling_price: selling_price, combined_products: combined_products }));
        }
        setShowRefreshButton(true);
        setDisableConfirmButton(true);
    };

    
    const images = (product: ProductItem) => {
        return product.image_list?.map((item: any) => {
            return `https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=${product.id}&filename=${item}.jpg`
        });
    };

    const handleOpenNewTab = (product: ProductItem | Pallet, url: string) => {

        // Open the link in a new tab using window.open
        if('pallet_products' in product) {
            const newTab: any = window.open(url, '_blank');
            const serializedProduct = JSON.stringify(product);
            const serializedPackageItem = JSON.stringify(packageItem);
            newTab.localStorage.setItem('pallet', serializedProduct);
            newTab.localStorage.setItem('packageItem', serializedPackageItem);
        }
        else {
            const newTab: any = window.open(url, '_blank');
            const serializedProduct = JSON.stringify(product);
            const serializedPackageItem = JSON.stringify(packageItem);
            newTab.localStorage.setItem('product', serializedProduct);
            newTab.localStorage.setItem('packageItem', serializedPackageItem);
        }        

        // Store the viewed item with a dynamic name based on package ID
        const viewedItemsKey = `${state?.state?.packageItem?.id}`;
        const storedViewedItems = JSON.parse(localStorage.getItem(viewedItemsKey) || '[]');
        if (!storedViewedItems.includes(product.id)) {
            const updatedViewedItems = [...storedViewedItems, product.id]; // Add the new product ID
            localStorage.setItem(viewedItemsKey, JSON.stringify(updatedViewedItems));
            setViewedItems(updatedViewedItems); // Update the state with the new array
        } else {
            setViewedItems(storedViewedItems);
        }

    };

    return (
        <CRow>
        {
            products?.filter((item: any) => {
                return (!combinedProductIds.includes(item.id)
                    && filterByPriceCriteria(item, minScale, maxScale)
                    && filterByScaleCriteria(item, minPrice, maxPrice)
                    && filterBySearchedCriteria(item, searchedInput))
                    && filterBySubCategory(item, currentSubGroupList)
                  
            }).map((productItem: any) => {
                // this filteration means the set articles which are paired only one of them would be rendered because it is a whole product for the customer and 
                // if the customer opens a detailed view he can see details of both of the set articles separatly. but on main screen it would be shown as one single
                // product so that is why I have added this filteration.
                return (
                    <CCol xs={12} md={3} lg={3} xl={2} style={{ marginTop: 24, marginBottom: 24 }}>
                        <CCard // Parent card component.
                            className={`pointer ${productCart.includes(productItem.id) ? 'selected-card' : 'unselected-card'}`}
                            onClick={() => {
                                if (authUser.groups?.includes(CUSTOMER_GROUP_ID) && packageItem && packageItem.status === 'A') {
                                    handleAddToCart(packageItem?.id, productItem.id, productItem.selling_price, productItem.combined_products);
                                }
                            }}
                        >
                            {authUser?.groups && authUser?.groups?.includes(CUSTOMER_GROUP_ID) && viewedItems.includes((productItem.id)) && <div className="ribbon ribbon-top-right"><span>{t('adminDashboard.Products.seen')}</span></div>}
                            {authUser?.groups && authUser?.groups.includes(CUSTOMER_GROUP_ID) && productItem.combined_products && productItem.combined_products.length > 0 && <div className="ribbon ribbon-bottom-right"><span>{t('adminDashboard.Products.paired')}</span></div>}
                            <CCardBody className="customer-product-card-body">
                                <div onClick={(e) => { e.stopPropagation() }}>
                                    {
                                    'pallet_products' in productItem ?
                                        <div>
                                            <img src={`https://mmlpy45aff.execute-api.eu-central-1.amazonaws.com/image?lager=340439039052693323&filename=0.jpg`} className={"aspect-ratio-style"} alt="logo" />
                                        </div>
                                    :
                                    images(productItem)?.length === 0 ?
                                        <div style={{cursor: 'pointer'}}>
                                            <img src={EmptyImage} className={"aspect-ratio-style"} alt="logo" />
                                        </div>
                                        :
                                        <Carousel showThumbs={false} showStatus={false}>
                                            {
                                                images(productItem)?.map((imageItem: string) => {
                                                    return (
                                                        <div
                                                            style={{cursor: 'pointer'}}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (authUser?.groups && authUser.groups.includes(CUSTOMER_GROUP_ID)) {
                                                                    productItem?.pallet_products ?
                                                                        handleOpenNewTab(productItem, '/customer/pallet/details')
                                                                    :
                                                                        handleOpenNewTab(productItem, '/customer/product/details');
                                                                }
                                                                else {
                                                                    productItem?.pallet_products ?
                                                                        handleOpenNewTab(productItem, '/admin/dashboard/pallet/details')
                                                                    :
                                                                        handleOpenNewTab(productItem, '/admin/dashboard/product/details');
                                                                }
                                                            }}>
                                                            <img src={imageItem} className={"aspect-ratio-style"} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Carousel>
                                    }
                                </div>
                                <div className='customer-product-card-body-text'>
                                    <h6>
                                        {
                                            'pallet_products' in productItem ?
                                            <>
                                                <CCardTitle> {t('adminDashboard.Products.description')}: {productItem?.description}</CCardTitle>
                                                <CCardText>
                                                    <div className='margin-top-12'><b> ID: {productItem.id}</b></div>
                                                    {!authUser.groups?.includes(CUSTOMER_GROUP_ID) && <div className='margin-top-12'><b> {t('adminDashboard.Products.buying_price')}: {productItem?.buying_price}</b></div>} 
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.usage')}: {productItem?.usage}</b></div>
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Pallets.products_inside')}: {productItem?.pallet_products?.length}</b></div>
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.selling_price')}: {formattedPrice((Number(productItem?.selling_price)))}</b></div>
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.online_price')}: {productItem?.current_online_price} €</b></div>
                                                </CCardText>
                                            </>
                                            :
                                            <>
                                                <CTooltip content={productItem?.sub_category + ' ' + productItem?.model}>
                                                    <CCardTitle> {t('adminDashboard.Products.description')}: {productItem?.sub_category} {productItem?.model}</CCardTitle>
                                                </CTooltip>
                                                <CCardText>
                                                    <div className='margin-top-12'><b> ID: {productItem.id}</b></div>
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.brand')}: {productItem?.brand}</b></div>
                                                    <div className='margin-top-12'><b> {t('adminDashboard.CommonWords.model')}: {productItem?.model}</b></div>
                                                    {
                                                        authUser.groups?.includes(CUSTOMER_GROUP_ID) || authUser.groups?.includes(SUPPLIER_GROUP_ID)  ?
                                                            <div className='margin-top-12'><b> {t('adminDashboard.Packages.article')} #: {productItem?.article}</b></div>
                                                        :
                                                            <div className='margin-top-12 url-decoration'><b 
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    const link = productItem.link ? productItem.link : getLink(productItem.model || '');
                                                                    if (link?.length > 0) {
                                                                        window.open(link.startsWith('http') ? link : `https://${link}`, '_blank', 'noopener,noreferrer');
                                                                    }
                                                                }}
                                                            > {t('adminDashboard.Packages.article')} #: {productItem?.article}</b></div>
                                                    }
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.grade_point')}: {Math.round(Number(productItem?.final_points))}</b></div>
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.selling_price')}: {formattedPrice((Number(productItem?.selling_price)))}</b></div>
                                                    <div className='margin-top-12'><b> {t('adminDashboard.Products.online_price')}: {productItem?.current_online_price} €</b></div>
                                                </CCardText>
                                            </>
                                        }
                                        <CButton
                                            className={productCart.includes(productItem.id) ? 'elv-btn-outline package-detail-button' : 'elv-btn package-detail-button'}
                                            variant='outline'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (authUser?.groups && authUser.groups.includes(CUSTOMER_GROUP_ID)) {
                                                    productItem?.pallet_products ?
                                                        handleOpenNewTab(productItem, '/customer/pallet/details')
                                                    :
                                                        handleOpenNewTab(productItem, '/customer/product/details');
                                                }
                                                else {
                                                    productItem?.pallet_products ?
                                                        handleOpenNewTab(productItem, '/admin/dashboard/pallet/details')
                                                    :
                                                        handleOpenNewTab(productItem, '/admin/dashboard/product/details');
                                                }
                                            }}
                                        >
                                            {t('adminDashboard.CommonWords.more_details')}
                                        </CButton>
                                    </h6>
                                </div>
                            </CCardBody>
                        </CCard>
                    </CCol>
                )
            })
        }
        </CRow>
    )
};
export default PackageDetailProductsComponent;