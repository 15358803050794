import { CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CButton, CTable,
    CTableBody, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow
} from "@coreui/react";
import "../AdminComponentStyles.css";
import { ProductItem, Pallet } from "../../../types";
import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";


interface ModalProps {
    isModalVisible: boolean;
    headerText: string;
    bodyText: string;
    closeModal: () => void;
    cartData: any;
    productsData: Pallet[];
    productType: string;
    onClick: (value: any) => void;
    removeFromCart: (value: any) => void;
}
const PalletCartModal = (props: ModalProps) => {
    const {isModalVisible, closeModal, headerText, bodyText, cartData, productsData, productType, onClick, removeFromCart} = props;

    return (
        <CModal size="lg" visible={isModalVisible} onClose={closeModal} className="zero-margin">
            <CModalHeader className="modal-header">
                <CModalTitle>{headerText && headerText}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div>{bodyText}</div>
                <CTable align="middle" className="mb-0" hover responsive>
                    <CTableHead color="light">
                        <CTableRow>
                            <CTableHeaderCell className="text-center">Id</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Selling price</CTableHeaderCell>
                            <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                        </CTableRow>
                    </CTableHead>
                    <CTableBody>
                    {cartData?.map((cartItem: any, index: number) => {
                        return (
                            <>
                                <CTableRow v-for="item in tableItems" key={index}>
                                    <CTableDataCell className="text-center">
                                        <div>{ cartItem?.set_articles ? cartItem?.set_articles[0] : cartItem}</div>
                                    </CTableDataCell>
                                    
                                  
                                    <CTableDataCell className="text-center">
                                        <div>{Math.round(Number(
                                            cartItem?.set_articles ? productsData.filter((article) => {return article.id === cartItem?.set_articles[0]})[0]?.selling_price
                                            :
                                            productsData.filter((product: Pallet) => {return product.id === cartItem})[0]?.selling_price
                                            ))}</div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                        <CButton
                                            shape="square"
                                            size="sm"

                                            onClick={() =>{
                                                // cartItem?.set_articles ?
                                                //     onClick(cartItem?.set_articles[0])
                                                // : 
                                                //     onClick(cartItem)
                                            }}

                                        >
                                            details
                                        </CButton>
                                        <CIcon
                                            color={'red'}
                                            icon={cilTrash}
                                            customClassName="nav-icon"
                                            onClick={()=>{removeFromCart(cartItem?.set_articles ? cartItem?.set_articles[0] : cartItem)}}
                                            style={{width:25, height: 25, marginLeft: 8}}
                                        /> 
                                    </CTableDataCell>                                   
                                </CTableRow>
                                
                            </>
                        )
                    })}
                    </CTableBody>
                </CTable>
            </CModalBody>
            <CModalFooter>
                <CButton color="primary" onClick={closeModal}>Cancel</CButton>
            </CModalFooter>
        </CModal>
    );
}
export default PalletCartModal;
