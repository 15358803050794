import { CHeader, CNav, CNavItem, CNavLink, CRow, CCol, CFormSwitch, CButton, CSpinner, CLoadingButton} from "@coreui/react-pro";
import { CustomerDetails, ProductItem } from "../../../types";
import ProgressBar from "@ramonak/react-progress-bar";
import { cilTruck, cilReload  } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { useEffect, useState } from "react";
import { getTruckCapacity } from "../../../services/PackageService";
import { t } from "i18next";
import "../AdminComponentStyles.css";
import { isMobile } from "react-device-detect";

interface PackageHeaderProps {
    edit?: boolean;
    headerText?: string;
    productsLength: number;
    palletLength?: number;
    selectedTab: 0 | 1 | 2 | 3;
    setArticlesLength: number;
    cartLength: number;
    automaticPairing: boolean;
    truckCapacity: number;
    selectedSetArticles: number[];
    selectedPallets: number[];
    selectedProducts: number[];
    selectedCustomer?: CustomerDetails;
    showRefreshButton?: boolean;
    setShowRefreshButton?: (value: boolean) => void;
    setSelecteTab: (value: 0 | 1 | 2 | 3) => void;
    setAutomaticPairing: (value: boolean) => void;
    getPairedSetArticlesOptions: (e: any, item?: ProductItem, callBackFunction?: () => void) => void;
    CreatePackage?: (value: any) => void;
    EditPackage?: () => void;
    getAllSnapshots?: () => void;
    setTruckCapacity: (value: number) => void;
    calculatePackagePrice?: () => void;
    truckErrorCallBack?: () => void;
    smartPairLoading: boolean;
    setSmartPairLoading: (value: boolean) => void;
    addToCart: boolean;
    setAddToCart: (value: boolean) => void;
    setPackageStatus: (value: string) => void;
};

const PackageHeaderComponent = (props: PackageHeaderProps) => {
    const [numOfTrucks, setNumOfTrucks] = useState<number>(1);   
    const [disableCreateButton, setDisableCreateButton] = useState<boolean>(true);
    const [showHeader, setShowHeader] = useState(false);
    const [loading, setLoading] = useState<boolean>(false);
    // const [smartPairLoading, setSmartPairLoading] = useState<boolean>(false);
    const [createPackageLoading, setCreatePackageLoading] = useState<boolean>(false);

    const {
        headerText, truckCapacity, productsLength, setSelecteTab, selectedSetArticles, selectedCustomer, selectedPallets, smartPairLoading, 
        selectedTab, setArticlesLength, cartLength, automaticPairing, selectedProducts, addToCart, calculatePackagePrice, palletLength,
        setAutomaticPairing, getPairedSetArticlesOptions, CreatePackage, EditPackage, getAllSnapshots, setTruckCapacity, 
        edit, truckErrorCallBack, setSmartPairLoading, setAddToCart, showRefreshButton, setShowRefreshButton } = props;

    useEffect(() => {
        setDisableCreateButton(true);
    },[selectedSetArticles, selectedProducts])
    useEffect(() => {
        const handleScroll = () => {
          const scrollPosition = window.scrollY || document.documentElement.scrollTop;
          // Define the threshold at which the header should appear (e.g., 100px)
          const threshold = 250;
    
          if (scrollPosition > threshold) {
            setShowHeader(true);
          } else {
            setShowHeader(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); // Run this effect only once on component mount

    const calculateTruckCapacity = () => {
        setLoading(true);
        let products = selectedProducts.map(number => `'${number}'`).join(',');
        let set_articles = selectedSetArticles.map(number => `'${number}'`).join(',');

        let form_data = {
            "lager_numbers": selectedProducts?.length && selectedSetArticles?.length ?  '(' + products + ',' + set_articles + ')' :  selectedProducts?.length ? '(' +  products + ')'  : '(' + set_articles + ')' ,
            fetched_pallet_count: selectedPallets?.length > 0 ? selectedPallets.length : 0,
        }
        getTruckCapacity(form_data).then((response) => {
            const totalCapacityOccupied = Number(response?.data?.truck_details?.occupancy["total_occupied_in_meters"]);
            if(totalCapacityOccupied) {
                const totalTrucks = Math.ceil(totalCapacityOccupied / 13.6);
                const currentTruckCapacity = (totalCapacityOccupied - ((totalTrucks-1) * 13.6));
                setNumOfTrucks(totalTrucks);
                setTruckCapacity(parseFloat(currentTruckCapacity.toFixed(2)));
                setDisableCreateButton(false);
                setLoading(false);
            }
            else {
                // Error popup here.
                truckErrorCallBack && truckErrorCallBack();
                setNumOfTrucks(0);
                setDisableCreateButton(false);
                setLoading(false);
            }
            setShowRefreshButton && setShowRefreshButton(false);
            !selectedCustomer && alert('Please select customer.')
        }).catch((e)=> {
            // console.log('Truck Exception: ', e)
            truckErrorCallBack && truckErrorCallBack();
            setDisableCreateButton(false);
            setLoading(false);
            setShowRefreshButton && setShowRefreshButton(false);
            !selectedCustomer && alert('Please select customer.')
        });
    }
   
    return (
        <>
            <CHeader 
                position="sticky" 
                className={`mb-4 ${showHeader ? 'd-block' : 'd-none'}`}
                style={isMobile ? { top: 20, margin: 0, width: '-webkit-fill-available', padding: 12 } : {}}
            >
                <CNav style={{ justifyContent: isMobile ? 'flex-start' : 'flex-end', alignItems: isMobile ? 'flex-start': 'flex-end' }}>
                    <CRow className={isMobile ? "max-width" : ""}>
                        <CCol style={isMobile ? {width: '-webkit-fill-available', marginRight: 12} : {marginRight: 12}}>
                            <CNavItem>
                                {
                                    loading ?
                                    <CRow>
                                        <CCol>
                                            <CSpinner />
                                        </CCol>
                                    </CRow>
                                    : null
                                }
                            </CNavItem>
                        </CCol>
                    </CRow>
                     <CRow className={isMobile ? "max-width" : ""}>
                        <CCol xs={12} style={isMobile ? {width: '-webkit-fill-available'}: {}}>
                            <CNavItem>
                                <h6 className="white">{numOfTrucks} x {t('adminDashboard.Packages.truck_capacity_text')} ({truckCapacity} m)</h6>
                                <ProgressBar completed={`${truckCapacity}`} maxCompleted={13.5} bgColor="#333333" width="-webkit-fill-available" customLabel=" "/>
                            </CNavItem>
                        </CCol>
                    </CRow>
                    <CRow style={isMobile ? {width: '-webkit-fill-available'} : {}}>
                        <CCol xs={12} style={isMobile ? {width: '-webkit-fill-available'} : {}}>
                            <CNavItem>
                                {
                                    showRefreshButton && cartLength > 0 ?
                                        <CButton
                                            className='elv-btn-header'
                                            variant="outline"
                                            shape="square"
                                            style={isMobile ? {marginLeft: 0, marginTop: 12, marginRight: 12} : {marginLeft: 30}}
                                            onClick={calculateTruckCapacity}
                                            disabled={cartLength == 0}
                                        >
                                            {t('adminDashboard.CommonWords.refresh')}
                                            <CIcon 
                                                icon={cilReload}
                                            />
                                        </CButton>
                                    :
                                    null
                                }
                            </CNavItem>
                        </CCol>
                    </CRow>
                    <CRow style={isMobile ? {width: '-webkit-fill-available'} : {}}>
                        <CCol xs={12} style={isMobile ? {width: '-webkit-fill-available'} : {}}>
                            <CNavItem>
                                <CButton
                                    className='elv-btn-header'
                                    variant="outline"
                                    shape="square"
                                    onClick={(e)=>{
                                        if(edit) {
                                            cartLength > 0 && EditPackage && EditPackage();
                                        }
                                        else {
                                            setCreatePackageLoading(true);
                                            cartLength > 0 && CreatePackage && CreatePackage(setCreatePackageLoading);
                                        }
                                    }}
                                   style={isMobile ? {width: '-webkit-fill-available', marginLeft: 0, marginTop: 12} : {marginLeft: 30}}
                                    disabled={!selectedCustomer || disableCreateButton || createPackageLoading}
                                >
                                    {createPackageLoading ? <CSpinner /> : edit ? t('adminDashboard.CommonWords.edit') : t('adminDashboard.CommonWords.create')}
                                </CButton>
                            </CNavItem>
                        </CCol>
                    </CRow>
                    <CRow style={isMobile ? {width: '-webkit-fill-available'} : {}}>
                        <CCol xs={12} style={isMobile ? {width: '-webkit-fill-available'} : {}}>
                            <CNavItem>
                                {
                                    edit ?
                                        <CButton
                                            className='elv-btn-header'
                                            variant="outline"
                                            shape="square"
                                            onClick={(e)=>{
                                                getAllSnapshots && getAllSnapshots();
                                            }}
                                            style={isMobile ? {marginTop: 12, marginLeft: 12} : {marginLeft: 30}}
                                        >
                                        {t('adminDashboard.Packages.revert')}
                                        </CButton>
                                    :
                                    null
                                }
                            </CNavItem>
                        </CCol>
                    </CRow>
                </CNav>
            </CHeader>
            <CNav style={{justifyContent: isMobile ? "flex-start" : 'flex-end', alignItems: isMobile ? "flex-start" : 'flex-end', padding: 20 }}>
                <CRow className={isMobile ? "max-width" : ""}>
                    <CCol style={isMobile ? {width: '-webkit-fill-available', marginRight: 12} : {marginRight: 12}}>
                        <CNavItem>
                            {
                                loading ?
                                <CRow>
                                    <CCol>
                                        <CSpinner />
                                    </CCol>
                                </CRow>
                                : null
                            }
                        </CNavItem>
                    </CCol>
                </CRow>
                <CRow className={isMobile ? "max-width" : ""}>
                    <CCol style={isMobile ? {width: '-webkit-fill-available'} : {marginRight: 20}}>
                        <CNavItem>
                            <h4>{numOfTrucks} x </h4>
                            <CIcon icon={cilTruck} className="me-2" size="3xl"/>
                            {t('adminDashboard.Packages.truck_capacity_text')} ({truckCapacity} m)
                            <ProgressBar completed={`${truckCapacity}`} maxCompleted={13.5} bgColor="#23B2D9" width= {isMobile ? '-webkit-fill-available' : "100%"} customLabel=" "/>
                        </CNavItem>
                    </CCol>
                </CRow>
                <CRow className={isMobile ? "max-width" : ""}>
                    <CCol style={isMobile ? {width: '-webkit-fill-available', marginTop: 12} : {}}>
                        <CNavItem>
                            {
                                showRefreshButton && cartLength > 0 ?
                                    <CButton
                                        className='elv-btn'
                                        variant="outline"
                                        shape="square"
                                        style={isMobile ? { marginRight: 20} : {marginLeft: 20, marginRight: 20}}
                                        onClick={calculateTruckCapacity}
                                        disabled={cartLength == 0}
                                    >
                                        {t('adminDashboard.CommonWords.refresh')}
                                        <CIcon 
                                            icon={cilReload}
                                        />
                                    </CButton>
                                : null
                            }
                        
                            <CButton
                                className='elv-btn'
                                variant="outline"
                                shape="square"
                                onClick={(e)=>{
                                    if(edit) {
                                        cartLength > 0 && EditPackage && EditPackage();
                                    
                                    }
                                    else {
                                        setCreatePackageLoading(true);
                                        cartLength > 0 && CreatePackage && CreatePackage(setCreatePackageLoading);
                                    }
                                }}
                                // style={{marginLeft: 30}}
                                disabled={!selectedCustomer || disableCreateButton || createPackageLoading}
                            >
                                {createPackageLoading ? <CSpinner /> : edit ? t('adminDashboard.CommonWords.edit') : t('adminDashboard.CommonWords.create')}
                            </CButton>
                            {
                                edit ?
                                    <CButton
                                        className='elv-btn'
                                        variant="outline"
                                        shape="square"
                                        onClick={(e)=>{
                                            getAllSnapshots && getAllSnapshots();
                                        }}
                                        style={{marginLeft: 30}}
                                    >
                                        {t('adminDashboard.Packages.revert')}
                                    </CButton>
                                :
                                null
                            }
                            
                        </CNavItem>
                    </CCol>
                </CRow>
            </CNav>
            <CHeader style={{padding: 20}}>
                <h1><b>{headerText ? headerText : t('adminDashboard.Packages.create_package_text')}</b></h1>
                <CNav variant="tabs" style={{alignItems: "flex-end"}}>
                    <CNavItem className="pointer">
                        <CNavLink onClick={() => setSelecteTab(0)} active={selectedTab === 0 ? true : false}>
                        {t('adminDashboard.Products.products')} ({productsLength})
                        </CNavLink>
                    </CNavItem>
                    <CNavItem className='margin-left-12 pointer'>
                        <CNavLink onClick={() => setSelecteTab(1)} active={selectedTab === 1 ? true : false}>{t('adminDashboard.OrdersWords.set_articles')} ({setArticlesLength})</CNavLink>
                    </CNavItem>
                    <CNavItem className='margin-left-12 pointer'>
                        <CNavLink onClick={() => setSelecteTab(2)} active={selectedTab === 2 ? true : false}>{t('adminDashboard.Pallets.pallets')} ({palletLength})</CNavLink>
                    </CNavItem> 
                    <CNavItem className='margin-left-12 pointer'>
                        <CNavLink onClick={() => {setSelecteTab(3);calculatePackagePrice && calculatePackagePrice();}} active={selectedTab === 3 ? true : false}>{t('adminDashboard.CommonWords.cart')} ({cartLength})</CNavLink>
                    </CNavItem>
                </CNav>
            </CHeader>

            <CRow>
                <CCol style={{justifyContent: 'flex-end', alignItems: 'center', display: 'flex', marginTop: 16}}>
                    {
                        <CFormSwitch className="margin-right-24 pointer" label={t('adminDashboard.OrderRequests.add_to_cart')} checked={addToCart} onClick={(e) => {setAddToCart(!addToCart)}}/>
                    }
                    {
                        selectedTab === 1 && selectedSetArticles?.length > 0 ?
                            <CFormSwitch className="pointer" label={t('adminDashboard.Packages.smart_pair_switch')} checked={automaticPairing} onClick={(e) => {setAutomaticPairing(!automaticPairing)}}/>
                        :   null
                    }                
                    {
                        selectedTab === 1 && selectedSetArticles?.length > 0 && automaticPairing ?
                        <CButton
                            className='elv-btn'
                            variant="outline"
                            shape="square"
                            style={{ marginLeft: 14 }}
                            disabled={!automaticPairing || smartPairLoading}
                            onClick={(e)=>{
                                setSmartPairLoading(true);
                                getPairedSetArticlesOptions(e, undefined, ()=>{setSmartPairLoading(false)});
                            }}
                        >
                            {
                                smartPairLoading ?
                                    <CRow>
                                        <CCol>
                                            <CSpinner />
                                        </CCol>
                                    </CRow>
                                : t('adminDashboard.Packages.pair_all_button')
                            }
                        </CButton>
                        : null
                    }
                </CCol>
            </CRow>
        </>
    )
};
export default PackageHeaderComponent;