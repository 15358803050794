import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import BlackLogo from '../assets/images/black-logo.png'
import './Authstyles.css';
import { Link, useLocation } from "react-router-dom";
import {loginUser, getAuthUser, confirmResetPasswordRequest } from '../services/AuthService';
import { CInputGroup, CSpinner } from "@coreui/react";

function ResetPasswordScreen() {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false);
    const [successText, setSuccessText] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [token, setToken] = useState<any>();
    const [uid, setUID] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
   
    
    const resetPassword = () => {
        setLoading(true);
        let form_data = {
            "new_password1": password,
            "new_password2": confirmPassword,
            "uid": uid,
            "token": token,
        }
          // console.log(form_data)
        confirmResetPasswordRequest(form_data).then((response) => {
            console.log(response.data.detail);
            setSuccessText(response.data.detail);
            setError('');
            setLoading(false);
        }).catch((e) => {
            setLoading(false);
            setSuccessText('');
            if(e?.response?.data?.token) {
                setError ("Token " + e?.response?.data?.token)
            }
            if(e?.response?.data?.uid) {
                setError ("UID " + e?.response?.data?.uid)
            }
        })
    }
    useEffect(()=> {
        // Extracting the search query parameter string using useLocation
        const queryParams = new URLSearchParams(location.search);
        // Get specific query parameter
        setToken(queryParams.get('token') || "")
        if(queryParams.get('token') == null  || queryParams.get('token') == undefined) {
            setError('Token not provided.')
        }

        setUID(queryParams.get('uid') || "")
        if(queryParams.get('uid') == null  || queryParams.get('uid') == undefined) {
            setError('Uid not provided.')
        }
    },[])
  
  return (
    <div className="main-container">
        <div className="bg-image-blurred"></div>
        <div className="login-form-container col-md-4 col-lg-3">
            <div style={{textAlign: "center"}}>
                <img src={BlackLogo} style={{width: 170}}/>
            </div>
            <div style={{textAlign: "center", marginTop: 14, marginBottom: 14}}>
                <h4>Reset password</h4>
            </div>
            
            <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    {/* <Form.Label>Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        value={email}
                        disabled={true}
                        onChange={(e) => {
                            setEmail(e?.target?.value);
                        }}
                        required
                    /> */}
                    <Form.Label  className="margin-top-24">Password</Form.Label>
                    <CInputGroup className='form-input-group'>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            style={{borderWidth: 0}}
                            required
                        />
                        {
                            showPassword ?
                                <img className='margin-left-12 show-hide-password-icon' src={require('../assets/images/eye-slash-icon.png')} onClick={()=>setShowPassword(false)}/>
                            :
                                <img className='margin-left-12 show-hide-password-icon' src={require('../assets/images/eye-icon.png')} onClick={()=>setShowPassword(true)}/>
                        }
                    </CInputGroup>
                    <CInputGroup className='form-input-group margin-top-24'>
                        <Form.Control
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="confirm password"
                            value={confirmPassword}
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            style={{borderWidth: 0}}
                            required
                        />
                        {
                            showConfirmPassword ?
                                <img className='margin-left-12 show-hide-password-icon' src={require('../assets/images/eye-slash-icon.png')} onClick={()=>setShowConfirmPassword(false)}/>
                            :
                                <img className='margin-left-12 show-hide-password-icon' src={require('../assets/images/eye-icon.png')} onClick={()=>setShowConfirmPassword(true)}/>
                        }
                    </CInputGroup>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Link to="/login">Already have an account?</Link>
                </Form.Group>
                <Button size="sm" className="login-button" onClick={() => resetPassword()}>
                    {loading ? <CSpinner /> : "Submit"}
                </Button>
                {
                   successText && successText.length > 0 ?
                        <p style={{color: 'green', marginTop: 8}}>{successText}</p>
                    :    null
                }
                {
                   error && error.length > 0 ?
                        <p style={{color: 'red', marginTop: 8}}>{error}</p>
                    :    null
                }
            </Form>
        </div>
    </div>
  );
}

export default ResetPasswordScreen;
