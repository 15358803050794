import 'simplebar/dist/simplebar.min.css';
import { CCardBody } from '@coreui/react-pro';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../../redux/store';
import { CCard, CCol, CRow } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilBasket, cilTruck } from '@coreui/icons';
import { useNavigate } from 'react-router-dom';
import { setPackageCountStart, setPackageCountSuccess, setPackageCountFailure } from '../../redux/slices/CustomerActivePackagesSlice';
import { setOrdersCountStart, setOrdersCountSuccess, setOrdersCountFailure, setSelectedTab } from '../../redux/slices/CustomerTotalOrdersSlice';
import { getPackages } from '../../services/PackageService';
import { getOrders } from '../../services/OrderService';
import { Package } from '../../types';
import { useTranslation } from 'react-i18next';

const CustomerDashboard = () => {
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { totalPending, totalActive } = useSelector((state: RootState) => state.CustomerActivePackagesSlice);
    const { totalOrders } = useSelector((state: RootState) => state.CustomerTotalOrdersSlice);
    const flexStyle = {
        display: 'flex',
    }

    useEffect(() => {
        dispatch(setSelectedTab(0));
    }, []);

    const fetchPackageCounts = async () => {
        try {
            dispatch(setPackageCountStart());
            const response = await getPackages();
            const { results } = response.data;

            // Filter the results based on status M and A but not F
            var filteredResults = results.filter((pkg: Package) => pkg.status !== 'F');

            // Calculate the total counts for pending and active packages
            const totalPending = filteredResults.filter((pkg: Package) => pkg.status === 'M').length;
            const totalActive = filteredResults.filter((pkg: Package) => pkg.status === 'A').length;

            dispatch(setPackageCountSuccess({ totalPending, totalActive }));
        } catch (error: any) {
            dispatch(setPackageCountFailure(error.message));
        }
    };

    const fetchOrderCounts = async () => {
        try {
            dispatch(setOrdersCountStart());
            const response = await getOrders();

            // Calcualte total orders
            const totalOrders = response.data.count;

            dispatch(setOrdersCountSuccess({ totalOrders }));
        } catch (error: any) {
            dispatch(setOrdersCountFailure(error.message));
        }
    };

    useEffect(() => {
        fetchPackageCounts();
        fetchOrderCounts();
    }, []); // Fetch package counts when the component mounts

    return (
        <div>
            <div className='website-settings-main-container margin-bottom-24'>
                <h1><b>{t('adminDashboard.Dashboard.my_dashboard')}</b></h1>
                <CRow>
                    <CCol>
                        <CCard className='pointer' style={{ marginTop: 14 }} onClick={() => { dispatch(setSelectedTab(1)); navigation('/customer/packages/summary') }}>
                            <CCardBody>
                                <div style={flexStyle}><CIcon icon={cilBasket} className='margin-right-12' size="xxl" style={{ color: '#23B2D9' }} />  <h5><b>{t('adminDashboard.Packages.active_packages')}: </b>{totalActive + totalPending}</h5> </div>
                            </CCardBody>
                        </CCard>
                    </CCol>
                    <CCol>
                        <CCard className='pointer' style={{ marginTop: 14 }} onClick={() => { dispatch(setSelectedTab(2)); navigation('/customer/orders/summary')}}>
                            <CCardBody>
                                <div style={flexStyle}><CIcon icon={cilTruck} className='margin-right-12' size="xxl" style={{ color: '#23B2D9' }} />  <h5><b>{t('adminDashboard.OrderRequests.total_orders')}: </b>{totalOrders}</h5> </div>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </div>
        </div >
    );
};
export default CustomerDashboard;